var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-box"},[_vm._l((_vm.PuTonglist),function(item,index){return _c('div',{key:index,staticClass:"list"},[_c('div',{staticClass:"list-left"},[_c('div',{staticClass:"list-doc"},[_c('div',{staticClass:"doc-t"},[_c('img',{attrs:{"src":_vm.$getimgsrc(item.gzperson.avatar),"alt":""}}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.gzperson.realname))]),_c('div',{staticClass:"pro"},[_vm._v(_vm._s(item.gzperson.manypersonrole_id))])]),_c('div',{staticClass:"doc-b"},[_c('i',{staticClass:"el-icon-time"}),_vm._v(_vm._s(item.gzperson.ban)+" ")])]),_c('div',{staticClass:"list-info"},[_c('div',{staticClass:"info-t"},[_c('el-button',{attrs:{"size":"mini"}},[_vm._v("客户信息")])],1),_c('div',{staticClass:"info-b"},[_vm._v(" "+_vm._s(item.username)+" "+_vm._s(item.usertel)+" ")])]),_c('div',{staticClass:"list-info"},[_c('div',{staticClass:"info-t"},[_c('el-button',{attrs:{"size":"mini"}},[_vm._v("预约服务")])],1),_c('div',{staticClass:"info-b"},[_vm._v(" "+_vm._s(item.server)+" ")])]),_c('div',{staticClass:"list-pro"},[_c('div',{staticClass:"pro-t"},[_c('el-button',{attrs:{"size":"mini"}},[_vm._v("预约时间")])],1),_c('div',{staticClass:"pro-b"},[_vm._v(_vm._s(item.yue_time))])]),_c('div',{staticClass:"list-pro status"},[_c('div',{staticClass:"pro-t"},[_c('el-button',{attrs:{"size":"mini"}},[_vm._v("当前状态")]),_c('div',{staticClass:"look",on:{"click":function($event){return _vm.lookOrderDetail(
                item.status == 1
                  ? '已预约'
                  : item.status == 2
                  ? '服务中'
                  : '已关闭',
                item.orderserid
              )}}},[_vm._v(" 查看详情"),_c('i',{staticClass:"el-icon-arrow-right",attrs:{"color":"#666"}})])],1),_c('div',{staticClass:"status_t"},[_c('div',{staticClass:"status_b"},[_c('el-button',{attrs:{"size":"mini"}},[_vm._v(_vm._s(item.statuss))])],1)])])])])}),_c('el-dialog',{attrs:{"title":"查看回访","visible":_vm.lookhuifangShow,"width":"50%"},on:{"update:visible":function($event){_vm.lookhuifangShow=$event}}},[_c('p',[_vm._v("回访人："+_vm._s(_vm.lookitem.follow))]),(_vm.lookitem.folltime)?_c('p',[_vm._v("回访时间："+_vm._s(_vm._f("timeform")(_vm.lookitem.folltime,true)))]):_vm._e(),_c('el-input',{attrs:{"type":"textarea","rows":20,"placeholder":"回访记录","disabled":""},model:{value:(_vm.lookitem.content),callback:function ($$v) {_vm.$set(_vm.lookitem, "content", $$v)},expression:"lookitem.content"}}),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.lookhuifangShow = false}}},[_vm._v("确 定")])],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }