<template>
    <el-container style="height:calc(100vh);">
      <el-main style="width:75%; height:calc(100vh);padding:0 !important;">
        <el-header>
          <el-row>
            <el-col :span="14" class="btn">
              <div :class="tabsChange== 1?'btn1 left':'left'" @click="handleTabsChange(1)">卡券列表</div>
              <div :class="tabsChange== 2?'btn1 right':'right'" @click="handleTabsChange(2)">权益卡列表</div>
            </el-col>
            <el-col :span="10" class="search">
              <el-input class="searchInput" placeholder="请输入名称" prefix-icon="el-icon-search" v-model="searchVal"
                @keyup.enter.native="searchChange">
              </el-input>
            </el-col>
          </el-row>
        </el-header>
        <el-container style="height:calc(91vh);">
          <el-row class="content" :gutter="20" v-if="tabsChange == 1">
            <el-col :span="rowCol" v-for="(item,i) in callList" :key="i">
              <div :class="['card cursor', activeItemList.indexOf(item)!==-1 ? 'activeCard' : '']" @click="activeList(item)">
                <div class="card-top">
                  <div class="card-title">
                    <div class="name">{{  item.name}}</div>
                    <div class="price"><b>¥{{ item.original_price}}</b></div>
                  </div>
                  <div class="card-info">
                    项目：{{ item.server_ids }}
                  </div>
                  <div class="card-time">
                    <div class="time">
                      有效期限：{{item.valid}}个月
                    </div>
                    <div class="num">
                      {{item.num}}次
                    </div>
                  </div>
                </div>
                <div class="card-btm">
                  <div class="card-type">
                    限时卡
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row class="content equity_cont" :gutter="10" v-else>
            <el-col :span="rowCol" v-for="(item,i) in equityList" :key="i" >
              <div :class="['equity', activeItemList.indexOf(item)!==-1 ? 'activeEquity' : '']" @click="activeList(item)">
                <div class="equity-day">
                  会员日：{{ item.vipday }}
                </div>
                <div class="detail">
                  <div class="left">
                    <div class="txt">
                      <img src="@/assets/images/vip-info/equity.png" alt="">
                      <div class="title">{{ item.storename }}</div>
                    </div>
                    <div class="instr">{{ item.instr }}</div>
                  </div>
                  <div class="look" @click.stop="LookInfo(item)">
                    查看权益
                  </div>
                </div>
                <div class="price_cont">
                  <div class="zhe">会员折扣：{{ item.zhe }}折</div>
                  <div class="btn">
                    <!-- ￥{{ item.price }} -->
                      <div class="price">￥{{ item.price }}</div>
                      <div class="buy">立即购买</div>
                  </div>
                </div>
                <div class="bg">
                  <img src="@/assets/images/vip-info/equity_bg.png" alt="">
                </div>
              </div>
            </el-col>
          </el-row>
        </el-container>
      </el-main>
      <el-main style="width:25%;height:calc(100vh);min-width:260px;padding:0 !important;">
        <el-container style="height:calc(100vh);border-left: 1px solid #e6e6e6; background-color:#fff;">
          <el-header class="order-header">
            <div class="order-box">
              <div class="order-title" style="height: 20px;">
              </div>
              <div class="order-vip cursor" @click="orderChangeTeacherShow=true">
                <div>
                  <img :src="$getimgsrc(orderTeacherName.avatar)" alt="" v-if="orderTeacherName.avatar" style="border-radius: 50%;">
                  <img src="@/assets/images/avatar.png" alt="" v-else>
                  <span class="name">{{ orderTeacherName.realname || '点击选择会员'}}</span>
                </div>
                <b class="iconfont icon-jia"></b>
              </div>
            </div>
          </el-header>
          <el-main class="order-main">
            <div class="order" ref="orderWidth">
              <div class="order-body">
                <div class="goods-card cursor" v-for="(item ,i) in activeItemList" :key="i">
                  <div class="order_item" v-if="item.t == 1">
                    <template>
                      <div  class="card-t">
                        <div class="t-name">{{  item.name}}</div>
                        <div class="stepper">
                            <el-input-number v-model="item.num" :precision="0" :min="1">
                            </el-input-number>
                          </div>
                      </div>
                    </template>
                    <div class="card-c">
                      <template>
                        <div  class="c-time">有效期限：{{item.valid}}个月</div>
                      </template>
                      <div class="c-price">¥{{ item.original_price }}</div>
                    </div>
                    <div class="card-c">
                      <template>
                        <div  class="c-time">到期时间：</div>
                      </template>
                      <div class="c-date">
                        <!-- <el-date-picker
                          v-model="item.date"
                          type="date"
                          placeholder="到期时间"
                          format="yyyy-MM-dd"
                          value-format="yyyy-MM-dd"
                          :picker-options="item.pickerOptions">
                        </el-date-picker> -->
                        <el-date-picker
                          v-model="item.date"
                          type="date"
                          placeholder="到期时间"
                          format="yyyy-MM-dd"
                          value-format="yyyy-MM-dd">
                        </el-date-picker>
                      </div>
                    </div>
                    <template>
                      <div  class="card-b">
                        <div class="b-name">
                          <div class="stepper">
                            <el-input-number v-model="item.addnum" :precision="0" :min="1"  @change="numchang">
                            </el-input-number>
                          </div>
                        </div>
                        <el-input placeholder="填写姓名" v-model="item.childname">
                          <i slot="prefix" class="el-input__icon iconfont icon-bi"></i>
                        </el-input>
                      </div>
                    </template>
                  </div>
                  <div class="order_item" v-else-if="item.t == 2">
                    <template>
                      <div  class="card-t">
                        <div class="t-name">{{  item.storename}}</div>
                      </div>
                    </template>
                    <div class="card-c">
                      <template>
                        <div  class="c-time">{{  item.zhe}}</div>
                      </template>
                      <div class="c-price">¥{{ item.price }}</div>
                    </div>
                    <el-input placeholder="填写姓名" v-model="item.childname">
                      <i slot="prefix" class="el-input__icon iconfont icon-bi"></i>
                    </el-input>
                  </div>
                </div>
              </div>
            </div>
          </el-main>
          <el-footer>
            <div class="order-bottom">
              <div class="btm-t">
                <div class="t-left">
                  共<span>{{ activeItemList.length }}</span>项 合计：<b>¥{{ allmoney.toFixed(2) }}</b>
                </div>
              </div>
              <div class="btm-c">
                <el-input placeholder="填写备注" v-model="remark">
                  <i slot="prefix" class="el-input__icon iconfont icon-bi"></i>
                </el-input>
              </div>
              <div class="btm-b">
                <!-- <el-button type="primary" round @click="openOrder">开单</el-button> -->
                <!-- <el-button type="danger" round @click="ordpay">收款</el-button> -->
                <el-button type="danger" round @click="ordPay">收款</el-button>
              </div>
            </div>
          </el-footer>
        </el-container>
      </el-main>
       <!-- 选择用户 -->
      <el-dialog class="dialog" center :visible.sync="orderChangeTeacherShow" width="50%" :before-close="handleClose"
        append-to-body>
        <template slot="title">
          <div class="dia-title">选择用户</div>
        </template>
        <template v-if="true">
          <!-- <div class="alert el-icon-warning">
            合理的排班会让工作更便捷、高效哦~
          </div> -->
          <div class="header">
          <div class="search">
            <el-input placeholder="请输入会员昵称、姓名或手机" prefix-icon="el-icon-search" v-model="searchVip"
              @keyup.enter.native="vipSearchChange">
            </el-input>
            <el-button type="primary" @click="vipSearchChange">搜索</el-button>
          </div>
        </div>
          <el-container style="height:370px;">
            <el-main class="cell-list">
              <el-radio-group v-model="orderCellRadio">
                <el-radio  :label="i" v-for="(item,i) in userList" :key="i" @change="orderChangeTeacher(item)">
                  <!-- <div class="disable" v-if="!i">
                    占用
                  </div> -->
                  <div class="title">
                    <img :src="$getimgsrc(item.avatar)" alt="" v-if="item.avatar" style="border-radius: 50%;">
                    <img src="@/assets/images/avatar.png" alt="" v-else>
                    {{ item.realname }}
                  </div>
                </el-radio>
              </el-radio-group>
            </el-main>
          </el-container>
        </template>
        <template v-else>
          <div class="empty-box">
            <div>
              <img src="@/assets/images/empty.png" alt="">
            </div>
            <div class="text">
              您还没有排班哦~
            </div>
            <div>
              <el-button type="primary" @click="jumpScheduling">立即排班</el-button>
            </div>
          </div>
        </template>
      </el-dialog>
       <!-- 次卡详情 -->
      <el-dialog :visible.sync="cardDetailShow" width="40%" :before-close="handleClose">
        <template slot="title">
          <div class="dia-title">
            权益卡详情
          </div>
        </template>
        <el-container style="height:500px;background-color:#fff;" v-if="lookitem.id">
          <div class="item">
            <div class="title">
              <img src="@/assets/images/equity/icon_1.png" alt="">
              <span>店铺名：</span>
            </div>
            <div class="server">
              <p>{{lookitem.storename}}</p>
            </div>
          </div>
          <div class="item">
            <div class="title">
              <img src="@/assets/images/equity/icon_1.png" alt="">
              <span>介绍：</span>
            </div>
            <div class="server">
              <p>{{lookitem.instr}}</p>
            </div>
          </div>
          <div class="item">
            <div class="title">
              <img src="@/assets/images/equity/icon_1.png" alt="">
              <span>服务：</span>
            </div>
            <div class="server">
              <span v-for="(it, ind) in lookitem.server_ids" :key="ind">{{it}}</span>
            </div>
          </div>
          <div class="item">
            <div class="title">
              <img src="@/assets/images/equity/icon_2.png" alt="">
              <span>抵扣券：</span>
            </div>
            <div class="server">
              <span v-for="(it, ind) in lookitem.coupon_ids" :key="ind">{{it}}</span>
            </div>
          </div>
          <div class="item">
            <div class="title">
              <img src="@/assets/images/equity/icon_3.png" alt="">
              <span>折扣：</span>
            </div>
            <div class="server">
              <p>会员日、节气日当天全场{{lookitem.zhe}}折</p>
            </div>
          </div>
          <div class="item">
            <div class="title">
              <img src="@/assets/images/equity/icon_4.png" alt="">
              <span>抽奖次数：</span>
            </div>
            <div class="server">
              <p>当月免费抽奖{{lookitem.num}}次（绝无空奖）</p>
            </div>
          </div>
          <div class="item">
            <div class="title">
              <img src="@/assets/images/equity/icon_5.png" alt="">
              <span>体验包：</span>
            </div>
            <div class="server">
              <span v-for="(it, ind) in lookitem.ty_server" :key="ind">{{it}}</span>
            </div>
            <div class="server">
              <span v-for="(it, ind) in lookitem.ty_shops" :key="ind">{{it}}</span>
            </div>
          </div>
        </el-container>
      </el-dialog>
    </el-container>
</template>

<script>
  export default {
    data() {
      return {
        tabsChange: 1,
        searchVal: '',
        innerWidth: document.documentElement.clientWidth,
        rowCol: 6,
        remark: '',
        num:20,
        callList:[],   
        equityList: [],
        allmoney: 0,
        allcount: 0,
        userList: [],
        searchVip: '',
        page: 0,
        // 选择会员
        changeVipShow: false,        
        activeItem: 0,
        activeItemList: [],
        orderTeacherName: '顾小敏',
        orderCellRadio: null,
        orderChangeTeacherShow: false,
        lookitem: {
          id: ''
        },
        cardDetailShow: false,
        date: ''
      }
    },
    watch: {
      innerWidth(val) {
        if (val >= 1440) {
          console.log(this.rowCol);
          this.rowCol = 6
        } else if (val >= 1150) {
          this.rowCol = 8
        } else if (val >= 830) {
          this.rowCol = 12
        } else if (val <= 830) {
          this.rowCol = 12
        }
      },
      tabsChange(val) {
        if (val == 1) {
          this.getCallList()
        } else if (val == 2) {
          this.getEquityList()
        }
      }
    },
    mounted() {
      var that = this;
      if (that.innerWidth >= 1440) {
        this.rowCol = 6
      } else if (that.innerWidth >= 1150) {
        this.rowCol = 8
      } else if (that.innerWidth >= 830) {
        this.rowCol = 12
      } else if (that.innerWidth <= 830) {
        this.rowCol = 12
      }
      window.onresize = () => {
        return (() => {
          window.fullWidth = document.documentElement.clientWidth;
          that.innerWidth = window.fullWidth; // 宽
        })()
      };
      this.getCallList()
      this.getuserList()
    },
    methods: {
      LookInfo(item){
        this.lookitem = item
        this.cardDetailShow = true
      },

      handleChangeName (i) {
        console.log(i, 'ii')
      },
      //获取卡卷列表
      getCallList(){
        this.$http.post('api/store/storecard/cardlist', {
          keyword: this.searchVal
        }).then(res =>{
          res.data.data.forEach(item => {
            item.addnum = 1
            item.t = 1
            item.count = item.num
            item.date = item.endtime
            // item.pickerOptions = {
            //   disabledDate(time) {
            //     return time.getTime() <= (new Date() - 3600 * 1000 * 24) || time.getTime() > new Date(item.endtime).getTime();
            //   },
            // }
          })
          this.callList = res.data.data
        })
      },

      // 获取权益卡列表
      getEquityList () {
        this.$http.post('api/store/storecard/quanyikalist', {
          keyword: this.searchVal
        }).then(res =>{
          res.data.data.forEach(item => {
            item.addnum = 1
            item.t = 2
          })
          this.equityList = res.data.data
        })
      },

      //用户列表
      getuserList(){
        this.$http.post('api/store/storeuser/quickuserlist',{
          page:this.page,
          keyword: this.searchVip
        }).then(res =>{
          this.userList = res.data.data
        })
      },

      vipSearchChange () {
        this.getuserList()
      },

      handleTabsChange (num) {
        this.tabsChange = num
        this.activeItemList = []
        this.allmoney = 0
      },

      orderChangeTeacher(item) {
        this.orderTeacherName = item
        this.orderChangeTeacherShow = false
      },

      // 搜索
      searchChange() {
        if (this.tabsChange == 1) {
            this.getCallList()
          } else {
            this.getEquityList()
          }
          console.log(this.activeItemList, '[]')
      },

      activeList(item) {
        if (this.tabsChange == 2) {
          this.activeItemList = []
        }
        if (this.activeItemList.indexOf(item) === -1) {
          this.activeItemList.push(item)
        } else {
          this.activeItemList.splice(this.activeItemList.indexOf(item),1)
        }
        // console.log(this.activeItemList)
        this.computmoney()
      },

      // 选择会员
      changeVip() {
        if (this.validatorPhone(this.vipTel)) {
          this.$message.success('选择会员成功')
          this.changeVipShow = false
        }
      },

      stepperChange() {
        this.getEquityList()
      },
      //计算总价格
      computmoney(){
        let money = 0
        for(let i in this.activeItemList){
          money += Number(this.activeItemList[i].original_price || this.activeItemList[i].price)*Number(this.activeItemList[i].addnum)
        }
        console.log(money, 'money')
        this.allmoney = money
      },

      // 设置次数
      handleNumChange () {
        this.$forceUpdate()
      },

      //设置数量
      numchang(){
        this.computmoney()
        this.$forceUpdate()
      },

      // 提交订单 权益卡多选单选 权益卡订单信息 编辑个人信息 权益卡订单支付 
      ordPay () {
        if(this.activeItemList.length != 0){
          if(this.orderTeacherName.id){
            if (this.tabsChange == 1) {
              var arr =[]
              this.activeItemList.forEach(item => {
                arr.push(item.childname)
              }) 
              if (arr.indexOf(undefined) != -1) {
                this.$message.warning('姓名不能为空')
                return
              }
              this.$http.post('api/store/order/createorder',{
                user_id: this.orderTeacherName.id,
                card: this.activeItemList,
                money: this.allmoney,
                remark: this.remark,
                type: 1,
              }).then(res =>{
                this.confirmChangeGoods(res.data.data) 
              })
            } else {
              if (!this.activeItemList[0].childname) {
                this.$message.warning('请填写名字')
                return
              }
              this.$http.post('api/store/storecard/buyquanyicard',{
                user_id: this.orderTeacherName.id,
                id: this.activeItemList[0].id,
                childname: this.activeItemList[0].childname
              }).then(res =>{
                this.confirmChangeGoods(res.data.data) 
              })
            }
          }else{
            this.$message.warning('请选择会员')
          }
        }else{
          this.$message.warning('请选择卡券')
        }
      },

      confirmChangeGoods(id) {
        var type = ''
        if (this.tabsChange == 1) {
          type = 'sellCardList'
        } else {
          type = 'equity'
        }
        this.$router.push({
          path: '/pay-settle',
          query: {
            type: type,
            id: id
          }
        })
      },

      handleClose() {
        this.changeVipShow = false
        this.orderChangeTeacherShow = false
        this.cardDetailShow = false
      },
    }
  }

</script>

<style lang="less" scoped>
  // 头部
  .el-header {
    background-color: #fff;
    color: #333;
    line-height: 60px;
    border-bottom: 1px solid #e9e9e9;

    .btn {

      .left,
      .right {
        display: inline-block;
        width: 100px;
        height: 30px;
        border: 1px solid #409eff;
        text-align: center;
        line-height: 30px;
        color: #409eff;
        cursor: pointer;
      }

      .left {
        border-right: 0;
        border-radius: 5px 0 0 5px;
      }

      .right {
        border-radius: 0 5px 5px 0;
      }

      .btn1 {
        border: 1px solid #409eff;
        text-align: center;
        line-height: 30px;
        color: #fff;
        background-color: #409eff;
      }

    }

    .search {
      /deep/ .el-input__inner {
        height: 35px;
        border-radius: 35px;

        &:focus {
          border-color: #dcdfe6;
        }
      }
    }
  }

  .header {
    line-height: 50px;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    background-color: #fff;
    border-bottom: 1px solid #e9e9e9;
  }

  .content {
      width: 100%;
      padding: 10px;
      box-sizing: border-box;

      .card {
        border: 3px solid #dcdfe6;
        margin: 10px 0;
        border-bottom: none;

        .card-top {
          padding: 10px;


          .card-title,
          .card-time {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .card-title {
            .name {
              font-size: 14px;
              font-weight: bold;
            }

            .price {
              font-size: 12px;
              color: #f56c6c;
            }
          }

          .card-info {
            font-size: 12px;
            color: #999;
            margin: 10px 0 15px;
          }

          .card-time {
            font-size: 12px;
            color: #999;
            margin-bottom: 15px;
          }
        }

        .card-btm {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #dcdfe6;
          font-weight: bold;
          color: #fff;
          padding: 10px;
          border-bottom: 3px dashed #fff;


          .stepper {

            /deep/ .el-input-number {
              width: 100px;
              height: 24px;
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .el-input-number__decrease,
              .el-input-number__increase {
                width: 24px;
                height: 24px;
                box-sizing: border-box;
                z-index: 1;
                line-height: 20px;
                border-radius: 100%;
                border: 2px solid #fff;
                background-color: #73a0fa;
                color: #fff;
                font-weight: bold;
              }

              .el-input {
                width: 100px;
                height: 34px;

                .el-input__inner {
                  height: 24px;
                  line-height: 24px;
                  padding: 0 15px;
                  border-color: #73a0fa;
                  background-color: #73a0fa;
                  color: #fff;
                  font-weight: bold;
                  border: none;
                }
              }
            }
          }
        }
      }

      .activeCard {
        border-color: #73a0fa;

        .card-btm {
          background-color: #73a0fa;
        }
      }
    }

   // 右侧订单
   /deep/ .order-main.el-main {
    padding: 0 0 10px !important;
    width: 100%;
    position: relative;
    background-color: #fff;
  }

  /deep/ .order-header.el-header {
    height: auto !important;
    width: 100% !important;
    padding: 0 !important;
    border-bottom: none;
  }

  .order-box {
    padding: 0 10px 10px;
    box-sizing: border-box;
    background-color: #fff;

    .order-title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left-btn {
        width: 70%;
        line-height: 60px;

        span {
          width: 20%;
          font-size: 12px;
          padding: 5px 5px;
          color: #409eff;
          border: 1px solid #409eff;
          border-radius: 5px;
          margin-left: 10px;
        }
      }

      .right {
        position: relative;
        right: 30px;
        color: #409eff;

        span {
          position: absolute;
          top: 5px;
          right: -15px;
          width: 22px;
          height: 22px;
          font-size: 12px;
          text-align: center;
          line-height: 22px;
          color: #fff;
          background-color: #f56c6c;
          border-radius: 100%;
        }
      }
    }

    .order-vip {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #f5f5f5;
      border: 1px solid #e9e9e9;
      border-radius: 5px;
      overflow: hidden;
      padding-right: 10px;

      img {
        float: left;
        width: 40px;
        height: 40px;
        margin: 10px 15px;
      }

      .name {
        float: left;
        color: #999;
      }

      b {
        float: right;
        color: #ccc;
        font-size: 20px;
        text-align: center;
        font-weight: normal;
      }
    }
  }

  

  .equity_cont {

    .equity {
      padding: 31px 8px 14px 8px;
      border-radius: 12px;
      position: relative;
      margin: 10px 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      background: #dcdfe6;
      border-radius: 10px 10px 10px 10px;

      .bg {
        position: absolute;
        bottom: -21px;
        right: 0;
        width: 107px;
        height: 86px;

        img {
          width: 100%;
          height: 100%;
        }
      }
      
      .equity-day {
        position: absolute;
        top: 0;
        left: 0;
        width: fit-content;
        border-radius: 12px 0 12px 0;
        padding: 6px 9px;
        color: #fff;
        background-color: #027269;
        box-sizing: border-box;
        font-size: 10px;
        line-height: 14px;
      }

      .detail {
        display: flex;
        flex: 1;
        margin-bottom: 5px;

        .left {
          margin-right: 14px;
          .txt {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            width: 90%;

            img {
              width: 27px;
              height:22px;
              margin-right: 6px;
            }

            .title {
              display: block;
              flex: 1;
              color: #027269;
              font-size: 18px;
              font-weight: bold;
              overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
              word-break: break-all;
            }
          }

          .instr {
            width: 80%;
            height: 35px;
            font-size: 12px;
            color: #027269;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            word-break: break-all;
          }
        }

        .look {
          width: 74px;
          height: 26px;
          line-height: 26px;
          text-align: center;
          color: #027269;
          font-size: 12px;
          box-sizing: border-box;
          background: linear-gradient( 268deg, #5FEADF 0%, #7CE9DF 100%);
          box-shadow: inset 0px 4px 10px 0px #49E8C6, inset 0px -4px 10px 0px #49E8C6;
          border-radius: 249px 249px 249px 249px;
        }
      }

      .price_cont {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .zhe {
          font-size: 12px;
          color: #027269;
        }

        .btn {
          min-width: 138px;
          width: fit-content;
          font-size: 12px;
          color: #fff;
          font-weight: bold;
          height: 29px;
          line-height: 29px;
          background-color: #257A92;
          position: relative;
          padding: 0 0 0 12px;
          border-radius: 267px 267px 267px 267px;
          // box-sizing: border-box;
          
          .price {
            z-index: 1;
            position: relative;
          }

          .buy {
            width: 80px;
            height: 29px;
            line-height: 29px;
            text-align: center;
            box-sizing: border-box;
            background-color: #3EA29A;
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 0 14px 14px 0;
          }
        }
      }
    }

    .activeEquity {
      background: linear-gradient( 146deg, #54EADE 0%, #7DEBE2 38%, #79DFD7 68%, #57BCB4 100%);
    }
  }


  .order {
    background-color: #fff;
    padding: 0 10px;


    .order-body {
      .goods-card {
        background-color: #fff;
        box-shadow: 0 0 4px #ccc;
        border-radius: 10px;
        margin-top: 10px;
        padding: 10px;
        color: #666;

        .card-t {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;

          .t-name {
            width: 70%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          span {
            display: inline-block;
            margin-left: 10px;
            color: #ccc;
          }
        }

        .card-c {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;

          .c-price {
            color: #666;
            font-size: 14px;
          }

          .c-date {
            width: 60%;

            /deep/ .el-date-editor.el-input {
              width: 100%;
            }
          }
        }

        .card-b {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .b-name {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-right: 20px;

            img {
              width: 30px;
              margin-right: 10px;
            }


          }

          .b-change {
            color: #999;
            font-size: 12px;

            .icon-arrow-right-bold {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  .stepper {

    /deep/ .el-input-number {
      width: 90px;
      height: 24px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .el-input-number__decrease,
      .el-input-number__increase {
        width: 24px;
        height: 24px;
        box-sizing: border-box;
        z-index: 1;
        line-height: 22px;
        border-radius: 100%;
        border: 1px solid #666;
        color: #666;
        font-weight: bold;
      }

      .el-input {
        width: 100px;
        height: 34px;
        background-color: transparent;

        .el-input__inner {
          height: 24px;
          line-height: 24px;
          padding: 0 15px;
          border-color: #73a0fa;
          background-color: transparent;
          color: #666;
          font-weight: bold;
          border: none;
        }
      }
    }
  }

  /deep/ .el-footer {
    padding: 0 !important;
    height: auto !important;

    .order-bottom {
      padding: 10px 10px 20px;
      box-sizing: border-box;
      background-color: #fff;
      color: #666;
      border-left: none;

      .btm-t {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        border-bottom: 1px solid #f2f2f2;
        padding: 10px 0;

        .t-left {
          b {
            color: #f56c6c;
          }
        }
      }

      .btm-c {
        border-bottom: 1px solid #f2f2f2;
        margin-bottom: 10px;

        .el-input__inner {
          border: none;
        }
      }

      .btm-b {
        display: flex;
        justify-content: space-around;

        .el-button {
          width: 40%;
        }
      }
    }
  }

  /deep/ .el-dialog {
    border-radius: 10px;

    .el-dialog__header {
      padding: 0;

      .el-dialog__headerbtn {
        top: 14px;
      }

    }

    .dia-title {
      font-size: 16px;
      font-weight: bold;
      border-bottom: 1px solid #f2f2f2;
      padding: 10px 0;
    }

    .alert {
      color: #ff9900;
      padding-right: 20px;
      font-size: 12px;
    }
  }

  .dialog {
    /deep/ .el-dialog__body {
      height: 400px;
    }

    .alert {
      color: #ff9900;
      margin-bottom: 20px;
    }

    .cell-list {
      width: 100%;

      /deep/ .el-radio-group {
        width: 100%;
        position: relative;

        .el-radio {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid #f2f2f2;
          padding: 10px;
          margin-bottom: 10px;
          margin-right: 0;
          position: relative;
          overflow: hidden;

          .disable {
            position: absolute;
            top: 0;
            right: -37px;
            font-size: 12px;
            transform: rotate(45deg);
            background-color: #f95e5a;
            color: #fff;
            width: 100px;
            line-height: 24px;
            text-align: center;
          }

          .title {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 12px;

            img {
              width: 40px;
              height: 40px;
              margin-right: 10px;
            }
          }

          .el-radio__input {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

    }

    .header {
      position: relative;
      background-color: #fff;
      color: #333;
      line-height: 50px;
      height: 50px;
      border-bottom: 0;
      // border-bottom: 1px solid #e9e9e9;

      .search {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .el-button--primary {
          height: 35px;
          margin-left: -6px;
          z-index: 1;
          font-size: 12px;
          border-radius: 0 5px 5px 0;

        }

        .el-button--primary:focus,
        .el-button--primary:hover,
        .el-button:active,
        .el-button:focus,
        .el-button:hover {
          color: #fff;
          border-color: #409eff;
          background-color: #409eff;
        }

        /deep/ .el-input__inner {
          height: 35px;
          border: 1px solid #dcdfe6;
          border-right: 0;
          border-radius: 5px 0 0 5px;

          &:focus,
          &:hover {
            border-color: #dcdfe6;
          }
        }
      }
    }
  }


   /deep/ .el-dialog {
      border-radius: 10px;
      min-width: 400px;
      padding: 10px;
      box-sizing: border-box;
  
      .el-dialog__header {
        padding: 0;
  
        .el-dialog__headerbtn {
          top: 14px;
        }
  
      }
  
      .dia-title {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
      }
  
      .el-dialog__body {
        height: 600px;
        padding: 10px;
      }
  
      .el-header {
        padding: 0;
        line-height: 30px;
        height: fit-content;
  
        .equity {
          border-bottom: none;
  
          .card-top {
  
            .card-title,
            .card-time {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
  
            .card-title {
              .name {
                font-size: 14px;
                font-weight: bold;
              }
  
              .price {
                font-size: 12px;
  
                b {
                  span {
                    font-size: 14px;
                    color: #ff9d0b;
                  }
                }
              }
            }
  
            .card-info,
            .time {
              font-size: 12px;
              color: #999;
            }
  
            .time {
              margin-bottom: 0;
              display: flex;
              justify-content: space-between;
              align-items: center;
  
              .btn {
                display: inline-block;
                width: 70px;
                line-height: 26px;
                text-align: center;
                color: #fff;
                background-color: #f56c6c;
                border-radius: 30px;
              }
            }
  
  
          }
  
          .card-btm {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: bold;
            color: #fff;
            padding: 10px;
            border-bottom: 3px dashed #fff;
  
  
            .stepper {
  
              /deep/ .el-input-number {
                width: 100px;
                height: 24px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
  
                .el-input-number__decrease,
                .el-input-number__increase {
                  width: 24px;
                  height: 24px;
                  box-sizing: border-box;
                  z-index: 1;
                  line-height: 20px;
                  border-radius: 100%;
                  border: 2px solid #fff;
                  background-color: #73a0fa;
                  color: #fff;
                  font-weight: bold;
                }
  
                .el-input {
                  width: 100px;
                  height: 34px;
  
                  .el-input__inner {
                    height: 24px;
                    line-height: 24px;
                    padding: 0 15px;
                    border-color: #73a0fa;
                    background-color: #73a0fa;
                    color: #fff;
                    font-weight: bold;
                    border: none;
                  }
                }
              }
            }
          }
        }
      }
  
      .el-main {
        padding: 0 !important;
      }

      .el-container {
        display: flex;
        flex-direction: column;
      }

      .item {
        margin-bottom: 10px;

        .title {
          display: flex;
          align-items: center;
          margin-bottom: 6px;

          img {
            width: 13px;
            height: 13px;
            margin-right: 6px;
          }

          span {
            color: #000;
            font-weight: bold;
            font-size: 14px;
          }
        }

        .server {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 10px;

          span {
            display: block;
            border: 1px solid #5DC2BA;
            color: #5DC2BA;
            font-size: 12px;
            line-height: 16px;
            padding: 6px 10px;
            margin-right: 10px;
            box-sizing: border-box;
            border-radius: 5px;
          }

          p {
            line-height: 18px;
            margin: 0;
          }
        }
      }
  
    }

</style>
