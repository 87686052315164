<template>
  <el-container class="content">
    <el-header class="header">
      <span class="left el-icon-arrow-left" @click="returnOn"></span>
      添加会员
    </el-header>
    <el-main class="main">
      <div class="info">
        <div class="title">
          会员资料
        </div>
        <el-form label-position="left" label-width="120px" :model="vipform">
          <el-form-item label="手机">
            <el-input v-model="vipform.mobile" placeholder="输入手机号码"></el-input>
          </el-form-item>
          <el-form-item label="姓名">
            <el-input v-model="vipform.realname" placeholder="输入会员姓名"></el-input>
          </el-form-item>
          <el-form-item label="性别">
            <el-radio-group v-model="vipform.gender">
              <el-radio :label="0">女</el-radio>
              <el-radio :label="1">男</el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- <el-form-item label="微信">
            <el-input v-model="form.wxChat" placeholder="输入微信账号"></el-input>
          </el-form-item> -->
          <el-form-item label="生日">
            <el-date-picker 
            v-model="vipform.birthday" 
            type="date" 
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            :editable="false" 
            :clearable="false" 
            placeholder="选择生日">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="顾问">
            <div @click="consultantClick">
              <el-input v-model="form.consultant" readonly suffix-icon="el-icon-arrow-right" placeholder="请选择顾问">
              </el-input>
            </div>
            <el-dialog class="dialog" title="选择顾问" center :visible.sync="consultantShow" width="40%"
              :before-close="handleClose">
              <el-container style="height:490px;">
                <el-main class="cell-list">
                  <el-radio-group v-model="vipform.brief">
                    <el-radio :label="item.id" v-for="(item,i) in userList" :key="i" @change="changeRadio(item)">
                      <div class="dialog-title">
                        <img src="@/assets/images/avatar.png" alt="">
                        {{ item.realname }}
                      </div>
                    </el-radio>
                  </el-radio-group>
                </el-main>
              </el-container>
            </el-dialog>
          </el-form-item>
          <el-form-item label="喜欢店里的谁">
            <div @click="likeClick">
              <el-input v-model="form.like" readonly suffix-icon="el-icon-arrow-right" placeholder="请选择">
              </el-input>
            </div>
            <el-dialog class="dialog" title="喜欢店里的谁" center :visible.sync="likeShow" width="40%"
              :before-close="handleClose">
              <el-container style="height:490px;">
                <el-main class="cell-list">
                  <el-radio-group v-model="vipform.like">
                    <el-radio :label="item.id" v-for="(item,i) in userList" :key="i" @change="changeRadio(item)">
                      <div class="dialog-title">
                        <img src="@/assets/images/avatar.png" alt="">
                        {{ item.realname }}
                      </div>
                    </el-radio>
                  </el-radio-group>
                </el-main>
              </el-container>
            </el-dialog>
          </el-form-item>
          <!-- <el-form-item label="客户来源">
            <div @click="sourceClick">
              <el-input v-model="form.source" readonly suffix-icon="el-icon-arrow-right" placeholder="选择"></el-input>
            </div>
            <el-dialog class="dialog" title="选择客户来源" center :visible.sync="sourceShow" width="40%"
              :before-close="handleClose">
              <el-container style="height:490px;">
                <el-main class="cell-list">
                  <el-radio-group v-model="addCellRadio">
                    <el-radio :label="i" v-for="(item,i) in 20" :key="i" @change="changeRadio(item)">
                      <div class="dialog-title">
                        <img src="@/assets/images/avatar.png" alt="">
                        顾小敏(美容师)
                      </div>
                    </el-radio>
                  </el-radio-group>
                </el-main>
              </el-container>
            </el-dialog>
          </el-form-item> -->
          <!-- <el-form-item label="推荐人">
            <div @click="refereesClick">
              <el-input v-model="form.referees" readonly suffix-icon="el-icon-arrow-right" placeholder="选择"></el-input>
            </div>
            <el-dialog class="dialog1" title="选择推荐人" :visible.sync="refereesShow" width="40%"
              :before-close="handleClose">
              <el-input v-model="refereesTel" prefix-icon="el-icon-mobile-phone" placeholder="请输入会员手机号"></el-input>
              <el-alert :title="alertTitle" :type="alertErrShow?'warning':'warning1'" show-icon :closable="false">
              </el-alert>
              <span slot="footer" class="dialog-footer">
                <el-button @click="refereesShow = false">取 消</el-button>
                <el-button type="primary" @click="telQuery">确 定</el-button>
              </span>
            </el-dialog>
          </el-form-item> -->
        </el-form>
      </div>
      <div class="info mark">
        <div class="title">
          过敏史
        </div>
        <div class="mark-ipt">
          <el-input type="textarea" :rows="3" resize="none" placeholder="请输入过敏史" v-model="vipform.allergy">
          </el-input>
        </div>
      </div>
      <div class="info mark">
        <div class="title">
          遗传史
        </div>
        <div class="mark-ipt">
          <el-input type="textarea" :rows="3" resize="none" placeholder="请输入遗传史" v-model="vipform.inherit">
          </el-input>
        </div>
      </div>
      <div class="info mark">
        <div class="title">
          备注信息
        </div>
        <div class="mark-ipt">
          <el-input type="textarea" :rows="3" resize="none" placeholder="输入备注信息" v-model="vipform.bio">
          </el-input>
        </div>
      </div>
      <div class="info vip">
        <div class="title">
          会员等级
        </div>
        <div class="vip-list">
          <div :class="form.vipLevel===i?'box box1':'box'" v-for="(item ,i) in shopInfo.userdengji" :key="i" @click="huiyuanDengji(item,i)">
            会员{{item.name}}
          </div>
        </div>
      </div>
      <div class="info label">
        <div class="title">
          会员标签
        </div>
        <div class="label-list">
          <div :class="form.vipLabel== i?'label1 label2':'label1'" v-for="(item ,i) in vipLabels" :key="i" @click="huiyuanLabel(item, i)">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div style="width:100%;height:100px;"></div>
      <div class="footer">
        <el-button type="primary" round @click="addVip">保存</el-button>
      </div>
    </el-main>

  </el-container>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    data() {
      return {
        form: {
          tel: '',
          uname: '',
          sex: '美女',
          wxChat: '',
          birthday: '',
          consultant: '',
          like: '',
          source: '',
          referees: '',
          textarea: '',
          vipLevel: 0,
          vipLabel: 0,
        },
        consultantShow: false,
        likeShow: false,
        sourceShow: false,
        refereesShow: false,
        addCellRadio: null,
        refereesTel: '',
        alertErrShow: false,
        alertTitle: '若手机号相匹配，点击确认则选中该会员',
        vipform:{
          mobile:'',//手机号
          realname:'',//姓名
          gender:0,//0女1男
          birthday:'',//生日
          bio:'',//备注(可为空)
          brief:'',//介绍人(可为空)
          grade:'',//等级id
          label: '', //标签id
          allergy:'',//过敏史(可为空)
          inherit:'',//遗传史(可为空)
          like: '', //喜欢(可为空)
        },
        userList:[],//推荐人列表
        shopInfo:{}, //店铺信息
      }
    },
    computed:{
      ...mapState(['user', 'vipLabels'])
    },
    watch:{
      user(userinfo){
        this.vipform.brief = userinfo.id
        this.form.consultant = userinfo.realname
        this.vipform.like = userinfo.id 
        this.form.like = userinfo.realname
      }
    },
    created(){
      this.getUserList()
      this.getshopList()
    },
    mounted(){
      this.vipform.brief = this.user.id
      this.form.consultant = this.user.realname
      this.vipform.like = this.user.id 
      this.form.like = this.user.realname
      this.vipform.label = this.vipLabels[0].id
    },
    methods: {
      //选中vip
      huiyuanDengji(item,i){
        this.form.vipLevel = i
        this.vipform.grade = item.id
      },

      // 选中标签
      huiyuanLabel (item, i) {
        this.form.vipLabel = i
        this.vipform.label = item.id
      },

      //获取店铺信息
      getshopList(){
        this.$http.post('api/store/storeset/storeinfor').then(res =>{
          this.shopInfo = res.data.data
          this.vipform.grade = this.shopInfo.userdengji[0].id
        })
      },
      //获取人员列表
      getUserList(){
        this.$http.post('api/store/user/manystoreperson',{
          type:2
        }).then(res =>{
          this.userList = res.data.data
        })
      },

      // 返回上级
      returnOn() {
        this.$router.go(-1)
      },
      // 显示弹窗
      consultantClick() {
        this.addCellRadio = null
        this.consultantShow = true
      },
      likeClick() {
        this.addCellRadio = null
        this.likeShow = true
      },
      sourceClick() {
        this.addCellRadio = null
        this.sourceShow = true
      },
      refereesClick() {
        this.refereesTel = ''
        this.alertErrShow = false
        this.addCellRadio = null
        this.refereesShow = true
      },
      // 弹窗人员选择
      changeRadio(item) {
        if (this.consultantShow) {
          this.consultantShow = false;
          this.form.consultant = item.realname;
        } else if (this.likeShow) {
          this.likeShow = false;
          this.form.like = item.realname;
        } else if (this.sourceShow) {
          this.sourceShow = false;
          this.form.source = item;
        } else if (this.refereesShow) {
          this.refereesShow = false;
          this.form.referees = item;
        }

      },
      // 手机号查询
      telQuery() {
        if (this.refereesTel) {
          this.form.referees = this.refereesTel
          this.refereesShow = false
        } else {
          this.alertErrShow = true
          this.alertTitle = ' 未查询到匹配的会员信息'
        }
      },
      // 会员标签
      vipLabelCheck(i) {
        if (this.form.vipLabel.indexOf(i) !== -1) {
          this.form.vipLabel.splice(this.form.vipLabel.indexOf(i), 1)
        } else {
          this.form.vipLabel.push(i)
        }
      },
      // 关闭dialog
      handleClose() {
        this.consultantShow = false
        this.likeShow = false;
        this.sourceShow = false
        this.refereesShow = false

      },
      // 添加会员
      addVip() {
        this.$http.post('api/store/storeuser/addstoreuser',this.vipform).then(res =>{
          this.$message.success(res.data.msg)
          this.returnOn()
          
        })
      }

    }
  };

</script>

<style lang="less" scoped>
  .content {
    height: calc(100vh);
  }

  // 头部
  .el-header {
    position: relative;
    z-index: 1;
    background-color: #fff;
    font-weight: bold;
    font-size: 16px;
    color: #333;
    height: 50px !important;
    line-height: 50px;
    text-align: center;
    border-bottom: 1px solid #e9e9e9;

    .left {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      color: #46a6ff;
      font-size: 24px;
    }
  }

  /deep/ .main.el-main {
    background-color: #f9f9f9;
    padding: 15px !important;
    min-height: calc(100vh);

    .info {
      background-color: #fff;

      .title {
        border-bottom: 1px solid #f2f2f2;
        padding: 12px;
        font-weight: bold;
        font-size: 14px;
        color: #666;
        margin-bottom: 10px;
      }

      .el-form {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-bottom: 15px;

        .el-form-item {
          width: 48%;
          margin: 0 1%;
          border-bottom: 1px solid #f2f2f2;

          &:nth-last-child(1),
          &:nth-last-child(2) {
            border-bottom: none;
          }


          .el-input {
            .el-input__inner {
              border: none;
              text-align: right;
            }
          }

          .el-form-item__content {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 100%;
          }

          .el-radio-group {
            padding-right: 15px;

          }

          .el-date-editor {
            .el-input__inner {
              padding: 0 15px 0 0;

            }

            .el-input__prefix {
              display: none;
            }
          }

        }
      }
    }

    .mark {
      margin-top: 30px;

      .mark-ipt {
        padding: 10px;

      }
    }

    .vip {
      margin-top: 30px;

      .vip-list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        .box {
          width: fit-content;
          padding: 0 10px;
          line-height: 30px;
          border: 1px solid #f1f1f1;
          border-radius: 30px;
          text-align: center;
          margin: 10px;
        }

        .box1 {
          border-color: #409eff;
          color: #409eff;
        }

        .box2 {
          background-color: #409eff;
          color: #fff;
        }

        
      }
    }

    .label {
      margin-top: 30px;

      .label-list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
      }
      .label1 {
          width: fit-content;
          padding: 6px 10px;
          border: 1px solid #f1f1f1;
          border-radius: 30px;
          text-align: center;
          margin: 10px;
          color: #333;
        }

        .label2 {
          border-color: #409eff;
          color: #409eff;
        }
    }
  }

  .dialog {
    /deep/.el-dialog__body {
      height: 500px;

    }

    .cell-list {
      width: 100%;

      /deep/ .el-radio-group {
        width: 100%;
        position: relative;

        .el-radio {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid #f2f2f2;
          padding: 10px;
          margin-bottom: 10px;
          margin-right: 0;

          .dialog-title {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 12px;

            img {
              width: 40px;
              height: 40px;
              margin-right: 10px;
            }
          }

          .el-radio__input {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }

    }
  }

  .dialog1 {
    /deep/.el-dialog__body {
      .el-input {
        .el-input__inner {
          text-align: left !important;
          border: 1px solid #DCDFE6 !important;
        }
      }

      .el-alert {
        padding: 0 10px;
        margin-top: 10px;
      }

      .el-alert--warning1 {
        background-color: #ebf5ff;
        color: #409eff;
        border: 1px solid #e5f2ff;
      }
    }
  }

  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    background-color: #fff;
    padding: 10px 0;

    .el-button {
      width: 30%;
      min-width: 240px;
    }
  }

</style>
