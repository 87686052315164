<template>
  <el-row>
    <el-container style="height:calc(100vh);">
      <el-header class="header">
        <div class="header-box">
          <div class="top">
            <span class="header-left el-icon-arrow-left" @click="returnOn"></span>
            <div :class="tabsChange==='1'?'btn1 left':'left'" @click="tabsChange='1'">营业日报</div>
            <div :class="tabsChange==='2'?'btn1 center':'center'" @click="tabsChange='2'">销量报表</div>
            <div :class="tabsChange==='3'?'btn1 right':'right'" @click="tabsChange='3'">异常情况</div>
          </div>
          <template>
            <div v-if="tabsChange!=='2' && tabsChange !== '3'" class="time">
              <span class="el-icon-arrow-left"></span>
              <el-date-picker v-model="timePicker" :editable="false" type="date" placeholder="选择日期" align="center"
                prefix-icon="none" clear-icon="none" format="yyyy-MM-dd" value-format="yyyy-MM-dd">
              </el-date-picker>
              <span class="el-icon-arrow-right"></span>

            </div>
            <div v-else class="header-btm">
              <div class="tabs cursor">
                <div :class="tabsActive===1?'tab tab1':'tab'" @click="tabsActive=1">商品销量</div>
                <div :class="tabsActive===2?'tab tab1':'tab'" @click="tabsActive=2">服务销量</div>
                <div :class="tabsActive===3?'tab tab1':'tab'" @click="tabsActive=3">次卡售卖</div>
                <!-- <div :class="tabsActive===4?'tab tab1':'tab'" @click="tabsActive=4">积分换购</div> -->
              </div>
              <div class="data">
                <span class="el-icon-arrow-left"></span>
                <el-date-picker class="cursor" v-model="timePicker1" :editable="false" type="date" placeholder="选择日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                  align="center" prefix-icon="none" clear-icon="none">
                </el-date-picker>
                <span class="el-icon-arrow-right"></span>

              </div>
            </div>
          </template>
        </div>
      </el-header>
      <el-main class="main">
        <div v-if="tabsChange==='1'" class="day">
          <div class="f-one">
            <div class="one-left">
              <div class="top">营业额</div>
              <div class="btm"><b>¥{{ Info.realprice }}</b></div>
            </div>
            <div class="one-center">
              <div class="top">客单数</div>
              <div class="btm"><b>{{ Info.ordernum }}</b></div>
            </div>
            <div class="one-right">
              <div class="top">单均价</div>
              <div class="btm"><b>¥{{ Info.realprice/Info.ordernum?Info.realprice/Info.ordernum.toFixed(2):'0' }}</b></div>
            </div>
          </div>
          <div class="f-two">
            <div class="cell">
              <div class="left">应收金额</div>
              <div class="right"><b class="red">¥{{ Info.totalprice }}</b></div>
            </div>
            <div class="cell cell1">
              <div class="left">-会员折扣</div>
              <div class="right"><b>¥{{ Info.grade }}</b></div>
            </div>
            <!-- <div class="cell cell1">
              <div class="left">-折扣活动</div>
              <div class="right"><b>¥100.00</b></div>
            </div> -->
            <div class="cell cell1">
              <div class="left">-优惠券</div>
              <div class="right"><b>¥{{ Info.copou }}</b></div>
            </div>
            <div class="cell cell1">
              <div class="left">-抹零</div>
              <div class="right"><b>¥{{ Info.rubprice }}</b></div>
            </div>
            <div class="cell cell1">
              <div class="left">-积分抵扣</div>
              <div class="right"><b>¥{{ Info.score }}</b></div>
            </div>
            <div class="cell cell1">
              <div class="left">-余额抵扣</div>
              <div class="right"><b>¥{{ Info.yue }}</b></div>
            </div>
            <div class="cell cell1">
              <div class="left">-卡券</div>
              <div class="right"><b>¥{{ Info.kaquan }}</b></div>
            </div>
            <div class="cell">
              <div class="left">=实收金额</div>
              <div class="right"><b class="red">¥{{ Info.realprice }}</b></div>
            </div>
            <!-- <div class="cell cell1">
              <div class="left">-退换货</div>
              <div class="right"><b>¥200.00</b></div>
            </div>
            <div class="cell">
              <div class="left">=总计金额</div>
              <div class="right"><b class="red">¥4900.00</b></div>
            </div> -->
          </div>
          <!-- <div class="f-three" v-if="false">
            <div class="f-title">
              支付方式汇总
            </div>
            <div class="table">
              <div class="thead">
                <div class="th">支付方式</div>
                <div class="th">结账次数</div>
                <div class="th">支付金额</div>
                <div class="th">退货次数</div>
                <div class="th">退货金额</div>
                <div class="th">合计</div>
              </div>
              <div class="tbody">
                <div class="tr" v-for="(item,i) in 8" :key="i">
                  <div class="td">
                    <span v-if="i===0">现金</span>
                    <span v-if="i===1">银行卡</span>
                    <span v-if="i===2">支付宝</span>
                    <span v-if="i===3">微信</span>
                    <span v-if="i===4">会员卡余额</span>
                    <span v-if="i===5">次卡</span>
                    <span v-if="i===6">积分抵现</span>
                    <span v-if="i===7">合计</span>
                  </div>
                  <div class="td">200</div>
                  <div class="td">¥200.00</div>
                  <div class="td">200</div>
                  <div class="td">¥200.00</div>
                  <div class="td">¥200.00</div>
                </div>
              </div>
            </div>
          </div> -->
          <!-- <div class="f-four" v-if="false">
            <div class="f-title">
              储值、次卡汇总
            </div>
            <div class="table">
              <div class="thead">
                <div class="th">支付方式</div>
                <div class="th">支付次数</div>
                <div class="th">支付金额</div>
                <div class="th">合计</div>
              </div>
              <div class="tbody">
                <div class="tr" v-for="(item,i) in 5" :key="i">
                  <div class="td">
                    <span v-if="i===0">现金</span>
                    <span v-if="i===1">银行卡</span>
                    <span v-if="i===2">支付宝</span>
                    <span v-if="i===3">微信</span>
                    <span v-if="i===4">合计</span>
                  </div>
                  <div class="td">200</div>
                  <div class="td">¥200.00</div>
                  <div class="td">¥200.00</div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <div v-if="tabsChange==='2'" class="report">
          <div class="r-one">
            <div class="f-title">
              销量
            </div>
            <div class="table">
              <div class="thead">
                <div class="th">名称</div>
                <div class="th">销售数量</div>
                <div class="th">销售金额</div>
              </div>
              <div class="tbody">
                <div class="tr" v-for="(item,i) in xiaoliang" :key="i">
                  <div class="td">
                    <span >{{ item.name }}</span>
                  </div>
                  <div class="td">{{ item.sale }}</div>
                  <div class="td">¥{{ item.sum }}</div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="r-two">
            <div class="f-title">
              分类销量
            </div>
            <div class="table">
              <div class="thead">
                <div class="th">商品名称</div>
                <div class="th">销售数量</div>
                <div class="th">销售金额</div>
              </div>
              <div class="tbody">
                <div class="tr" v-for="(item,i) in 7" :key="i">
                  <div class="td">
                    菲灵魔法定型发胶
                  </div>
                  <div class="td">200</div>
                  <div class="td">¥20000.00</div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <div v-if="tabsChange==='3'" class="abnormal">
          <div class="a-one">
            <div class="f-title">
              异常统计
            </div>
            <div class="table">
              <div class="thead">
                <div class="th">类别</div>
                <div class="th">次数</div>
                <div class="th">金额</div>
              </div>
              <div class="tbody" v-if="false">
                <div class="tr" v-for="(item,i) in 5" :key="i">
                  <div class="td">
                    <span v-if="i===0">商品退货</span>
                    <span v-if="i===1">计次卡退卡</span>
                    <span v-if="i===2">抹零金额</span>
                    <span v-if="i===3">商品议价</span>
                    <span v-if="i===4">商品赠送</span>
                  </div>
                  <div class="td">200</div>
                  <div class="td">¥200.00</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </el-row>

</template>

<script>
  export default {
    data() {
      return {
        tabsChange: '1',
        tabsActive: 1,
        timePicker: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate(),
        timePicker1: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate(),
        Info:{},
        xiaoliang:{}
      }
    },
    created() {
      this.getList()
      this.getXiaoliang()
    },
    watch: {
      tabsActive(){
        this.getXiaoliang()
      },
      timePicker1(){
        this.getXiaoliang()
      },
      timePicker() {
        this.getList()
      }
    },
    methods: {
      //获取销量
      getXiaoliang(){
        this.$http.post('api/store/census/shopsalesum',{
          type:this.tabsActive,
          time:this.timePicker1
        }).then(res =>{
          this.xiaoliang = res.data.data
        })
      },
      //获取报表
      getList(){
        this.$http.post('api/store/census/yingyemoney', {
          time:this.timePicker
        }).then(res =>{
          this.Info = res.data.data
        })
      },
      // 返回上级
      returnOn() {
        this.$router.go(-1)
      },
    }
  };

</script>
<style lang="less" scoped>
  // 头部
  .header {
    background-color: #fff;
    color: #333;
    line-height: 50px;
    border-bottom: 1px solid #e9e9e9;

    .header-box {
      text-align: center;
      line-height: 30px;
      padding-top: 10px;

      .top {
        position: relative;

        .header-left {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          color: #46a6ff;
          font-size: 24px;
        }

        .left,
        .center,
        .right {
          display: inline-block;
          width: 100px;
          height: 30px;
          border: 1px solid #409eff;
          text-align: center;
          line-height: 30px;
          color: #409eff;
          cursor: pointer;
        }



        .left {
          border-right: 0;
          border-radius: 5px 0 0 5px;
        }

        .right {
          border-left: 0;

          border-radius: 0 5px 5px 0;
        }

        .btn1 {
          text-align: center;
          line-height: 30px;
          color: #fff;
          background-color: #409eff;
        }
      }

      .time {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15px;

        .el-icon-arrow-left,
        .el-icon-arrow-right {
          font-weight: bold;
          color: #666;
        }


      }

      .header-btm {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 12px;
        height: 60px;


        .tabs {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          min-width: 360px;
          font-size: 12px;

          .tab {
            box-sizing: border-box;
            padding: 0 20px 3px;
            border-bottom: 2px solid transparent;
            margin-bottom: 5px;

          }

          .tab1 {
            box-sizing: border-box;
            color: #409eff;
            border-bottom: 2px solid #409eff;
          }
        }

        .data {

          .el-icon-arrow-left,
          .el-icon-arrow-right {
            font-weight: bold;
            color: #666;
          }
        }
      }



    }
  }

  .main {
    background-color: #f9f9f9;
    color: #666;

    .f-title {
      font-weight: bold;
      margin-bottom: 20px;
    }

    .day {
      .f-one {
        display: flex;
        justify-content: space-around;
        align-items: center;
        text-align: center;
        line-height: 30px;
        background-color: #fff;
        margin-bottom: 20px;
        border: 1px solid #f2f2f2;
        padding: 10px;

        b {
          font-size: 16px;
        }

      }

      .f-two {
        background-color: #fff;
        margin-bottom: 20px;
        border: 1px solid #f2f2f2;
        padding: 20px;

        .cell {
          display: flex;
          justify-content: space-between;
          padding: 10px 0;

          .right {
            .red {
              color: #f56c6c;
            }
          }
        }

        .cell1 {
          padding-left: 10px;
        }
      }

      .f-three {
        background-color: #fff;
        margin-bottom: 20px;
        border: 1px solid #f2f2f2;
        padding: 20px;

        .table {

          .thead,
          .tbody .tr {
            display: flex;
            justify-content: space-around;

            .th,
            .td {
              width: 16.66%;
              text-align: left;
              padding: 10px 0;
            }
          }

          .thead,
          .tr {
            border-bottom: 1px solid #f2f2f2;
          }
        }
      }

      .f-four {
        background-color: #fff;
        margin-bottom: 20px;
        border: 1px solid #f2f2f2;
        padding: 20px;

        .table {

          .thead,
          .tbody .tr {
            display: flex;
            justify-content: space-around;

            .th,
            .td {
              width: 25%;
              text-align: left;
              padding: 10px 0;
            }
          }

          .thead,
          .tr {
            border-bottom: 1px solid #f2f2f2;
          }
        }
      }
    }

    .report {
      .r-one {
        background-color: #fff;
        margin-bottom: 20px;
        border: 1px solid #f2f2f2;
        padding: 20px;

        .table {

          .thead,
          .tbody .tr {
            display: flex;
            justify-content: space-around;

            .th,
            .td {
              width: 33%;
              text-align: left;
              padding: 10px 0;
            }
          }

          .thead,
          .tr {
            border-bottom: 1px solid #f2f2f2;
          }

          .thead {
            color: #999;
          }

          .tr {
            &:last-child {
              font-weight: bold;
            }
          }
        }
      }

      .r-two {
        background-color: #fff;
        margin-bottom: 20px;
        border: 1px solid #f2f2f2;
        padding: 20px;

        .table {

          .thead,
          .tbody .tr {
            display: flex;
            justify-content: space-around;

            .th,
            .td {
              width: 33%;
              text-align: left;
              padding: 10px 0;
            }
          }

          .thead,
          .tr {
            border-bottom: 1px solid #f2f2f2;
          }

          .thead {
            color: #999;
          }
        }
      }
    }

    .abnormal {
      .a-one {
        background-color: #fff;
        margin-bottom: 20px;
        border: 1px solid #f2f2f2;
        padding: 20px;

        .table {

          .thead,
          .tbody .tr {
            display: flex;
            justify-content: space-around;

            .th,
            .td {
              width: 33%;
              text-align: left;
              padding: 10px 0;
            }
          }

          .thead,
          .tr {
            border-bottom: 1px solid #f2f2f2;
          }

          .thead {
            color: #999;
          }
        }
      }
    }
  }

  /deep/ .el-date-editor.el-input {
    width: 120px;

    .el-input__inner {
      border: 0;
      background-color: transparent;
      text-align: center;
      color: #409eff;
      padding: 0;
    }
  }

  /deep/.header.el-header {
    height: 100px !important;
  }

  .main.el-main {
    padding: 20px !important;
  }

</style>
