import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 预约时间
    aboutTime: {},
    token:localStorage.getItem("token") || '',//用户token
    user: JSON.parse(localStorage.getItem("user")) || {},//用户信息
    staff:JSON.parse(sessionStorage.getItem("staff")) || {},//当前员工信息
    vipuser:JSON.parse(sessionStorage.getItem("vipuser")) || {},//当前员工信息
    vipLabels:[],//当前员工信息
  },
  mutations: {
    // 预约时间 及医生信息
    updataeAboutTime(state, time) {
      state.aboutTime = time
    },
    //设置用户token
    setToken(state,usertoken){
      state.token = usertoken
      window.localStorage.setItem("token", usertoken)
    },
    //用户信息
    setUser(state,user){
      state.user = user
      window.localStorage.setItem("user", JSON.stringify(user))
    },
    //会员标签
    setLabels(state,label){
      state.vipLabels = label
    },
    //当前员工
    setStaff(state,staff){
      state.staff = staff
      window.sessionStorage.setItem("staff", JSON.stringify(staff))
    },
    //当前vip用户
    setVip(state,vip){
      state.vipuser = vip
      window.sessionStorage.setItem("vipuser", JSON.stringify(vip))
    }
  },
  actions: {},
  modules: {}
})
