<template>
    <el-container style="height:calc(100vh); background-color:#f9f9f9;">
      <el-header class="header">
        <span class="header-left el-icon-arrow-left" @click="returnOn"></span>
        权益列表
      </el-header>
      <el-container>
        <el-main style="height:calc(100vh);width:75%;border-right:1px solid #e9e9e9;">
          <el-row class="content" :gutter="10">
  
            <el-col :span="rowCol" v-for="(item,i) in equityList" :key="i" >
              <div :class="['equity', activeItemList.indexOf(item)!==-1 ? 'activeEquity' : '']" @click="activeList(item)">
                <div class="equity_day">
                  会员日：{{ item.vipday }}
                </div>
                <div class="detail">
                  <div class="left">
                    <div class="txt">
                      <img src="@/assets/images/vip-info/equity.png" alt="">
                      <div class="title">{{ item.storename }}</div>
                    </div>
                    <div class="instr">{{ item.instr }}</div>
                  </div>
                  <div class="look" @click.stop="LookInfo(item)">
                    查看权益
                  </div>
                </div>
                <div class="price_cont">
                  <div class="zhe">会员折扣：{{ item.zhe }}</div>
                  <div class="btn">
                      <div class="price">￥{{ item.price }}</div>
                      <div class="buy">立即购买</div>
                  </div>
                </div>
                <div class="bg">
                  <img src="@/assets/images/vip-info/equity_bg.png" alt="">
                </div>
              </div>
            </el-col>
            
          </el-row>
        </el-main>
        <el-main style="height:calc(100vh);width:25%;min-width:350px; background-color:#fff;padding:0 !important;">
          <el-container style="height:calc(94vh);">
            <el-header class="header-right">
              <div class="about-info">
                <div class="info-title">
                  预约信息
                </div>
                <div class="user-info">
                  <div class="tel">
                    <div class="left">
                      手机
                    </div>
                    <div class="right">
                      <el-input v-model="vipuser.mobile"  placeholder="请输入手机号码"></el-input>
                    </div>
                  </div>
                  <div class="name">
                    <div class="left">
                      姓名
                    </div>
                    <div class="right">
                      <el-input v-model="vipuser.realname"  placeholder="请输入姓名"></el-input>
                    </div>
                  </div>
                </div>
                <div class="info-title">
                  已选项目
                </div>
              </div>
            </el-header>
            <el-main>
              <div class="order">
                <div class="order-body">
                  <div class="goods-card cursor" v-for="(item ,i) in activeItemList" :key="i">
                    <div class="card-t">
                      <div class="t-name">{{  item.storename}}</div>
                    </div>
                    <div class="card-b">
                      <div class="c-time">会员折扣：{{ item.zhe }}折</div>
                      <div class="b-change">
                        ¥{{ item.price }}</div>
                    </div>
                    <el-input placeholder="填写姓名" v-model="item.childname">
                      <i slot="prefix" class="el-input__icon iconfont icon-bi"></i>
                    </el-input>
                  </div>
                </div>
              </div>
            </el-main>
            <el-footer>
              <div class="order-bottom">
                <div class="btm-t">
                  <div class="t-left">
                    共<span>{{ activeItemList.length }}</span>项 合计：<b>¥{{  allMoney}}</b>
                  </div>
                </div>
                <div class="btm-c">
                  <el-input placeholder="填写备注" v-model="remark">
                    <i slot="prefix" class="el-input__icon iconfont icon-bi"></i>
                  </el-input>
                </div>
                <div class="btm-b">
                  <el-button type="danger" round @click="ordpay">收款</el-button>
                </div>
              </div>
            </el-footer>
          </el-container>
        </el-main>
      </el-container>
       <!-- 次卡详情 -->
      <el-dialog :visible.sync="cardDetailShow" width="40%" :before-close="handleClose">
        <template slot="title">
          <div class="dia-title">
            权益卡详情
          </div>
        </template>
        <el-container style="height:500px;background-color:#fff;" v-if="lookitem.id">
          <div class="item">
            <div class="title">
              <img src="@/assets/images/equity/icon_1.png" alt="">
              <span>店铺名：</span>
            </div>
            <div class="server">
              <p>{{lookitem.storename}}</p>
            </div>
          </div>
          <div class="item">
            <div class="title">
              <img src="@/assets/images/equity/icon_1.png" alt="">
              <span>介绍：</span>
            </div>
            <div class="server">
              <p>{{lookitem.instr}}</p>
            </div>
          </div>
          <div class="item">
            <div class="title">
              <img src="@/assets/images/equity/icon_1.png" alt="">
              <span>服务：</span>
            </div>
            <div class="server">
              <span v-for="(it, ind) in lookitem.server_ids" :key="ind">{{it}}</span>
            </div>
          </div>
          <div class="item">
            <div class="title">
              <img src="@/assets/images/equity/icon_2.png" alt="">
              <span>抵扣券：</span>
            </div>
            <div class="server">
              <span v-for="(it, ind) in lookitem.coupon_ids" :key="ind">{{it}}</span>
            </div>
          </div>
          <div class="item">
            <div class="title">
              <img src="@/assets/images/equity/icon_3.png" alt="">
              <span>折扣：</span>
            </div>
            <div class="server">
              <p>会员日、节气日当天全场{{lookitem.zhe}}折</p>
            </div>
          </div>
          <div class="item">
            <div class="title">
              <img src="@/assets/images/equity/icon_4.png" alt="">
              <span>抽奖次数：</span>
            </div>
            <div class="server">
              <p>当月免费抽奖{{lookitem.num}}次（绝无空奖）</p>
            </div>
          </div>
          <div class="item">
            <div class="title">
              <img src="@/assets/images/equity/icon_5.png" alt="">
              <span>体验包：</span>
            </div>
            <div class="server">
              <span v-for="(it, ind) in lookitem.ty_server" :key="ind">{{it}}</span>
            </div>
            <div class="server">
              <span v-for="(it, ind) in lookitem.ty_shops" :key="ind">{{it}}</span>
            </div>
          </div>
        </el-container>
      </el-dialog>
    </el-container>
</template>
  
  <script>
  import { mapState } from 'vuex'
    export default {
      data() {
        return {
          innerWidth: document.documentElement.clientWidth,
          rowCol: 6,
          stepperNum: 1,
          telInput: '',
          nameInput: '',
          remark: '',
          equityList:[],
          activeItemList:[],//选中列表
          allMoney:0,//价格
          lookitem: {
            id:''
          },
          cardDetailShow: false
        }
      },
      computed: {
        ...mapState(['vipuser'])
      },
      watch: {
        innerWidth(val) {
          if (val >= 1440) {
            this.rowCol = 6
          } else if (val >= 1180) {
            this.rowCol = 8
          } else if (val >= 900) {
            this.rowCol = 12
          } else if (val <= 900) {
            this.rowCol = 24
          }
        }
      },
      mounted() {
        var that = this;
        if (that.innerWidth >= 1440) {
          that.rowCol = 6
        } else if (that.innerWidth >= 1180) {
          that.rowCol = 8
        } else if (that.innerWidth >= 900) {
          that.rowCol = 12
        } else if (that.innerWidth <= 900) {
          that.rowCol = 24
        }
        window.onresize = () => {
          return (() => {
            window.fullWidth = document.documentElement.clientWidth;
            that.innerWidth = window.fullWidth; // 宽
          })()
        };
        this.getEquityList()
      },
      methods: {
        LookInfo(item){
          this.lookitem = item
          this.cardDetailShow = true
        },

        handleClose() {
          this.cardDetailShow = false
        },

        //提交订单
        ordpay(){
          if (!this.activeItemList[0].childname) {
            this.$message.warning('名字不能为空')
            return
          }
          this.$http.post('api/store/storecard/buyquanyicard',{
            user_id:this.vipuser.id,
            id: this.activeItemList[0].id,
            childname: this.activeItemList[0].childname
          }).then(res =>{
            this.confirmChangeGoods(res.data.data)
          })
        },

        activeList(item) {
          this.activeItemList = []
          this.activeItemList.push(item)
          this.allMoney = item.price
          this.$forceUpdate()
          // this.computmoney()
      },
        //获取卡卷列表
        getEquityList(){
          this.$http.post('api/store/storecard/quanyikalist').then(res =>{
            res.data.data.forEach(item => {
              item.addnum = 1
            })
            this.equityList = res.data.data
          })
        },
        //重新渲染计数器并计算价格
        handleChange(){
          this.allMoney = 0
          if(this.activeItemList.length != 0){
            for(let k in this.activeItemList){
              this.allMoney += Number(this.activeItemList[k].addnum)*Number(this.activeItemList[k].price)
            }
          }else{
            this.allMoney = 0
          }
          this.$forceUpdate()
        },
        // 返回上级
        returnOn() {
          this.$router.push({
            name: 'VipInfo'
          })
        },
        // 删除商品
        delOrderGoods() {
          this.$confirm('确认要删除该次卡吗？', '删除确认', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            center: true
          }).then(() => {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }).catch(() => {
  
          });
        },
        // 收款
        confirmChangeGoods(id) {
          this.$router.push({
            path: '/pay-settle',
            query: {
              type: 'equity',
              id:id
            }
          })
        },
      }
    };
  
  </script>
  <style lang="less" scoped>
    // 头部
    .el-header {
      position: relative;
      z-index: 1;
      background-color: #fff;
      font-weight: bold;
      font-size: 16px;
      color: #333;
      height: 50px !important;
      line-height: 50px;
      text-align: center;
      border-bottom: 1px solid #e9e9e9;
  
      .header-left {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        color: #46a6ff;
        font-size: 24px;
      }
    }
  
    // 左侧商品卡片
    .header-search {
      height: 60px !important;
      background-color: #f9f9f9;
      border-bottom: none;
    }
  
    .content {
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
  
      .equity {
        padding: 31px 8px 14px 8px;
        border-radius: 12px;
        position: relative;
        background-color: #dcdfe6;
        margin: 10px 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        border-radius: 10px 10px 10px 10px;

        .bg {
          position: absolute;
          bottom: -21px;
          right: 0;
          width: 107px;
          height: 86px;

          img {
            width: 100%;
            height: 100%;
          }
        }
        
        .equity_day {
          position: absolute;
          top: 0;
          left: 0;
          width: fit-content;
          border-radius: 12px 0 12px 0;
          padding: 6px 9px;
          color: #fff;
          background-color: #027269;
          box-sizing: border-box;
          font-size: 10px;
          line-height: 14px;
        }

        .detail {
          display: flex;
          flex: 1;
          margin-bottom: 5px;

          .left {
            margin-right: 14px;
            .txt {
              display: flex;
              align-items: center;
              margin-bottom: 10px;
              width: 90%;

              img {
                width: 27px;
                height:22px;
                margin-right: 6px;
              }

              .title {
                display: block;
                flex: 1;
                color: #027269;
                font-size: 18px;
                font-weight: bold;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                word-break: break-all;
              }
            }

            .instr {
              width: 80%;
              height: 30px;
              font-size: 12px;
              color: #045d4c;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              word-break: break-all;
            }
          }

          .look {
            width: 74px;
            height: 26px;
            line-height: 26px;
            text-align: center;
            color: #027269;
            font-size: 12px;
            border-radius: 13px;
            box-sizing: border-box;
            background: linear-gradient( 268deg, #5FEADF 0%, #7CE9DF 100%);
            box-shadow: inset 0px 4px 10px 0px #49E8C6, inset 0px -4px 10px 0px #49E8C6;
            border-radius: 249px 249px 249px 249px;
          }
        }

        .price_cont {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .zhe {
            font-size: 12px;
            color: #027269;
          }

          .btn {
            min-width: 138px;
            width: fit-content;
            font-size: 12px;
            color: #fff;
            font-weight: bold;
            height: 29px;
            line-height: 29px;
            background-color: #257A92;
            position: relative;
            padding: 0 0 0 12px;
            border-radius: 267px 267px 267px 267px;
            
            .price {
              z-index: 1;
              position: relative;
            }

            .buy {
              width: 80px;
              height: 29px;
              line-height: 29px;
              text-align: center;
              box-sizing: border-box;
              background-color: #3EA29A;
              position: absolute;
              top: 0;
              right: 0;
              border-radius: 0 14px 14px 0;
            }
          }
        }
      }

      .activeEquity {
        background: linear-gradient( 146deg, #54EADE 0%, #7DEBE2 38%, #79DFD7 68%, #57BCB4 100%);
      }
    }
  
    .header-right {
      height: auto !important;
      color: #666;
      padding: 0 10px 10px;
      background-color: #fff;
      border-bottom: none;
  
      .about-info {
        .info-title {
          line-height: 40px;
          font-weight: bold;
          text-align: left;
          font-size: 14px;
        }
  
        .user-info {
          padding: 0 10px;
          background-color: #f4f4f4;
          border-radius: 8px;
          margin-bottom: 10px;
  
          .tel,
          .name {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
  
          .tel {
            border-bottom: 1px solid #e9e9e9;
          }
  
          .tel,
          .name {
            box-sizing: border-box;
  
            /deep/ .el-input {
  
              .el-input__inner {
                border: none;
                text-align: right;
                font-size: 12px;
                background-color: #f4f4f4;
                padding-right: 0;
  
              }
            }
          }
  
          .left {
            color: #666;
            font-size: 12px;
          }
  
          .right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            line-height: 40px;
            color: #666;
            font-size: 12px;
          }
        }
      }
    }
  
    .order {
      padding-bottom: 20px;
  
      .order-body {
        .goods-card {
          background-color: #fff;
          box-shadow: 0 0 4px #ccc;
          border-radius: 10px;
          margin-top: 10px;
          padding: 10px;
          color: #666;
  
          .card-t {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
  
            .t-name {
              width: 70%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
  
            span {
              display: inline-block;
              margin-left: 10px;
              color: #ccc;
            }
          }
  
          .card-c {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
  
            .c-price {
              font-size: 12px;
              color: #999;
  
              span {
                color: #666;
                font-size: 14px;
              }
            }
          }
  
          .card-b {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 12px;
  
            .c-time {
              font-size: 12px;
              color: #999;
            }
  
            .b-change {
              color: #666;
              font-size: 14px;
            }
          }
        }
      }
    }
  
    /deep/ .el-footer {
      padding: 0 !important;
      height: auto !important;
  
      .order-bottom {
        padding: 10px 10px 20px;
        box-sizing: border-box;
        background-color: #fff;
        color: #666;
        border-left: none;
  
        .btm-t {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #f2f2f2;
          padding: 10px 0;
  
          .t-left {
            b {
              color: #f56c6c;
            }
          }
        }
  
        .btm-c {
          border-bottom: 1px solid #f2f2f2;
          margin-bottom: 10px;
  
          .el-input__inner {
            border: none;
          }
        }
  
        .btm-b {
          .el-button {
            width: 100%;
          }
        }
      }
    }
  
    /deep/ .el-main {
      padding: 0 10px !important;
  
      .el-row {
        padding: 0 10px !important;
  
  
      }
  
    }

     /deep/ .el-dialog {
      border-radius: 10px;
      min-width: 400px;
      padding: 10px;
      box-sizing: border-box;
  
      .el-dialog__header {
        padding: 0;
  
        .el-dialog__headerbtn {
          top: 14px;
        }
  
      }
  
      .dia-title {
        font-size: 16px;
        font-weight: bold;
        text-align: center;
      }
  
      .el-dialog__body {
        height: 600px;
        padding: 10px;
      }
  
      .el-header {
        padding: 0;
        line-height: 30px;
        height: fit-content;
  
        .equity {
          border-bottom: none;
  
          .card-top {
  
            .card-title,
            .card-time {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
  
            .card-title {
              .name {
                font-size: 14px;
                font-weight: bold;
              }
  
              .price {
                font-size: 12px;
  
                b {
                  span {
                    font-size: 14px;
                    color: #ff9d0b;
                  }
                }
              }
            }
  
            .card-info,
            .time {
              font-size: 12px;
              color: #999;
            }
  
            .time {
              margin-bottom: 0;
              display: flex;
              justify-content: space-between;
              align-items: center;
  
              .btn {
                display: inline-block;
                width: 70px;
                line-height: 26px;
                text-align: center;
                color: #fff;
                background-color: #f56c6c;
                border-radius: 30px;
              }
            }
  
  
          }
  
          .card-btm {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: bold;
            color: #fff;
            padding: 10px;
            border-bottom: 3px dashed #fff;
  
  
            .stepper {
  
              /deep/ .el-input-number {
                width: 100px;
                height: 24px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
  
                .el-input-number__decrease,
                .el-input-number__increase {
                  width: 24px;
                  height: 24px;
                  box-sizing: border-box;
                  z-index: 1;
                  line-height: 20px;
                  border-radius: 100%;
                  border: 2px solid #fff;
                  background-color: #73a0fa;
                  color: #fff;
                  font-weight: bold;
                }
  
                .el-input {
                  width: 100px;
                  height: 34px;
  
                  .el-input__inner {
                    height: 24px;
                    line-height: 24px;
                    padding: 0 15px;
                    border-color: #73a0fa;
                    background-color: #73a0fa;
                    color: #fff;
                    font-weight: bold;
                    border: none;
                  }
                }
              }
            }
          }
        }
      }
  
      .el-main {
        padding: 0 !important;
      }

      .el-container {
        display: flex;
        flex-direction: column;
      }

      .item {
        margin-bottom: 10px;

        .title {
          display: flex;
          align-items: center;
          margin-bottom: 6px;

          img {
            width: 13px;
            height: 13px;
            margin-right: 6px;
          }

          span {
            color: #000;
            font-weight: bold;
            font-size: 14px;
          }
        }

        .server {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 10px;

          span {
            display: block;
            border: 1px solid #5DC2BA;
            color: #5DC2BA;
            font-size: 12px;
            line-height: 16px;
            padding: 6px 10px;
            margin-right: 10px;
            box-sizing: border-box;
            border-radius: 5px;
          }

          p {
            line-height: 18px;
            margin: 0;
          }
        }
      }
  
    }
  
  </style>
  