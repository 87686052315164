<template>
  <div class="container">
    <div class="usable-card">
      <div class="title">
        <div class="left">充值金额</div>
        <div class="right">账户余额：<b>¥{{ vipuser.money }}</b></div>
      </div>
      <div class="card">
        <div :class="activeCardItem===item?'card-item card-item1':'card-item'" v-for="(item,i) in chongzhiList" :key="i"
          @click="activeCardItem=item">
          <div class="top">充值金额</div>
          <div class="bottom"><b>¥{{ item.original_price}}</b></div>
        </div>
        <div class="card-item card-item-custom" @click="activeCardItem={original_price:0}">
          <div class="top">自定义充值金额</div>
          <div class="bottom">
            <el-input v-model="topUpPriceInput" placeholder="请输入"></el-input>
          </div>
        </div>
      </div>

    </div>
    <div class="failure-card usable-card">
      <div class="title">
        <div class="left">支付方式</div>
      </div>
      <div class="pay-methods">
        <div class="grid">
          <div class="grid-item" @click="payMethod(1)">
            <div class="iconfont icon-xianjin"></div>
            <div>现金</div>
          </div>
          <div class="grid-item" @click="payMethod(2)">
            <div class="iconfont icon-weixin"></div>
            <div>微信</div>
          </div>
          <div class="grid-item" @click="payMethod(3)">
            <div class="iconfont icon-zhifubao"></div>
            <div>支付宝</div>
          </div>
          <!-- <div class="grid-item" @click="payMethod(4)">
            <div class="iconfont icon-yinxingqia"></div>
            <div>银行卡</div>
          </div> -->
        </div>
      </div>
    </div>
    <!-- 支付方式 -->
    <el-drawer class="drawer" :visible.sync="payMethodShow" direction="rtl" :show-close="false"
      :before-close="handleClose">
      <template slot="title">
        <div class="nav">
          <div :class="payActiveItem===1?'nav-item nav-item1':'nav-item'" @click="handlePayActiveItem(1)">现金收款</div>
          <div :class="payActiveItem===2?'nav-item nav-item1':'nav-item'" @click="handlePayActiveItem(2)">微信</div>
          <div :class="payActiveItem===3?'nav-item nav-item1':'nav-item'" @click="handlePayActiveItem(3)">支付宝</div>
          <!-- <div :class="payActiveItem===4?'nav-item nav-item1':'nav-item'" @click="payActiveItem=4">银行卡支付</div> -->
        </div>
      </template>
      <div class="body">
        <div class="title">
          应收金额：<b>¥{{  activeCardItem.original_price || topUpPriceInput}}</b>
        </div>
        <div v-if="payActiveItem===1" class="cash">

          <!-- <el-input v-model="cashInput" clearable placeholder="输入收款金额"></el-input> -->

          <!-- <div class="info">
            找零：<span v-if="cashInput">¥{{(cashInput*1-519).toFixed(2)}}</span>
            <span v-else>¥0.00</span>
          </div> -->
        </div>
        <div v-if="payActiveItem===2" class="weChat">
          <img :src="payCode" alt="">
          <div v-if="codeShow===0">
            使用扫码枪扫描客户付款码
          </div>
          <el-input ref="input" v-if="codeShow===0" placeholder="流水号" v-model="numNo">
          </el-input>
        </div>
        <div v-if="payActiveItem===3" class="weChat">
          <img :src="payCode" alt="">
          <div v-if="codeShow===0">
            使用扫码枪扫描客户付款码
          </div>
          <el-input ref="input" v-if="codeShow===0" placeholder="流水号" v-model="numNo">
          </el-input>
        </div>
        <div v-if="payActiveItem===4" class="card">

          <div class="cell">
            <img src="@/assets/images/pay-settle/card-title.png" alt="">
            <span>银行卡支付</span>
          </div>
          <el-steps :active="stepsActive" align-center>
            <el-step title="发送账单至POS"></el-step>
            <el-step title="使用POS刷卡"></el-step>
            <el-step title="结账成功"></el-step>
          </el-steps>
          <div class="info">
            <div class="i-title">
              <span v-if="stepsActive===1">第一步,发送账单至POS</span>
              <span v-if="stepsActive===2">第二步,使用POS刷卡支付({{payTime}})s</span>
            </div>
            <div class="text">
              <span v-if="stepsActive===1">请保证POS网络连接顺畅</span>
              <span v-if="stepsActive===2">未收到账单?请保证POS网络顺畅</span>
            </div>
            <div class="img">
              <img src="@/assets/images/pay-settle/prompt.png" alt="">
            </div>
          </div>

        </div>
      </div>
      <div class="drawer-footer">
        <div class="cancel" @click="payMethodShow=false">取消收款</div>
        <template>
          <div v-if="payActiveItem===1" class="confirm" @click="upPay">确认收款</div>
          <!-- <div v-if="payActiveItem===2 || payActiveItem===3" class="confirm iconfont icon-erweima" @click="changeCode">
            {{codeShow===0?'客户扫我的收款码':'扫描客户付款码'}}</div> -->
            <div v-if="payActiveItem===2 || payActiveItem===3" class="confirm iconfont" @click="handlePay">确认收款</div>
          <div v-if="payActiveItem===4" class="confirm">

            <div v-if="stepsActive===1" @click="stepsActive=2">发送账单</div>
            <div v-if="stepsActive===2" @click="stepsActive=1">重新发送账单</div>
          </div>
        </template>
      </div>
    </el-drawer>
    <!-- 确认收款 -->
    <el-drawer class="drawer confirm-drawer" :visible.sync="confirmCollectionShow" direction="rtl" :show-close="false"
      :before-close="handleClose">
      <div class="top">
        <div class="el-icon-circle-check"></div>
        <div class="text1">结算成功</div>
        <div class="text2">通过账单可查看详情</div>
      </div>
      <div class="center">
        <div class="cell">
          <div class="left">收款金额</div>
          <div class="right"><b>¥{{ totaleprice.total_price }}</b></div>
        </div>
        <div class="cell">
          <div class="left">支付方式</div>
          <div class="right">{{ totaleprice.pay_type }}</div>
        </div>
        <div class="cell">
          <div class="left">订单号</div>
          <div class="right">{{ totaleprice.ordernum }}</div>
        </div>
      </div>
      <div class="bottom">
        <div class="btn btn1" @click="jumpServiceBills">查看账单</div>
        <div class="btn btn2" @click="handleClose">返回</div>
      </div>

    </el-drawer>
  </div>
</template>

<script>
  import payCode from '../../assets/images/pay-settle/scanCode.png';
  import code from '../../assets/images/pay-settle/code.jpg'
  import { mapState } from 'vuex'
  export default {
    data() {
      return {
        // 自定义充值金额
        topUpPriceInput: 0,
        activeCardItem: {
          original_price:0
        },

        // 支付方式
        payMethodShow: false,
        payActiveItem: 1,
        cashInput: '',
        balanceInput: '',
        cardStep: 0,
        payCode: payCode,
        codeShow: 0,
        stepsActive: 1,
        payTime: 60,
        timer: null,
        // 确认收款
        confirmCollectionShow: false,
        //充值列表
        chongzhiList:[],
        //收款信息
        totaleprice:{},
        numNo: ''
      }
    },
    computed: {
      ...mapState(['vipuser'])
    },
    watch: {
      stepsActive() {
        if (this.stepsActive === 2) {
          this.timer = setInterval(() => {
            this.payTime -= 1
            if (this.payTime < 0) {
              this.payTime = 60
              this.stepsActive = 1
            }
          }, 1000)
        } else {
          clearInterval(this.timer)
          this.payTime = 60
        }
      },

      
    payMethodShow (val) {
        if (val && (this.payActiveItem == 2 || this.payActiveItem == 3)) {
          this.$nextTick(() => {
            this.$refs.input.focus()
          })
        }
      }
    },
    mounted() {
      this.getList()
    },
    methods: {

      handlePayActiveItem (num) {
        this.payActiveItem = Number(num)
        this.numNo = ''
        if (num == 3 || num == 4) {
          this.$nextTick(() => {
            this.$refs.input.focus()
          })
        }
      },
      //充值
      upPay(){
        if(this.activeCardItem.original_price || this.topUpPriceInput){
          this.$http.post('api/store/storeuser/rechargepay',{
            user_id:this.vipuser.id,
            money:this.activeCardItem.original_price == 0?this.topUpPriceInput:this.activeCardItem.original_price,
            type:this.payActiveItem
          }).then(res =>{
            this.totaleprice = res.data.data
            this.confirmCollectionShow = true
            this.$message.success(res.data.msg)
          })
        }else{
          this.$message.warning('请选择充值金额')
        }
        
      },
      //充值
      handlePay(){
        if(this.activeCardItem.original_price || this.topUpPriceInput){
          this.$http.post('api/store/storeuser/rechargepay',{
            user_id:this.vipuser.id,
            money:this.activeCardItem.original_price == 0?this.topUpPriceInput:this.activeCardItem.original_price,
            type:this.payActiveItem,
            authcode: this.numNo
          }).then(res =>{
            this.totaleprice = res.data.data
            this.confirmCollectionShow = true
            this.$message.success(res.data.msg)
          })
        }else{
          this.$message.warning('请选择充值金额')
        }
        
      },
      //获取充值列表
      getList(){
        this.$http.post('api/store/storeset/rechargelist').then(res =>{
          this.chongzhiList = res.data.data
        })
      },
      // 选择支付方式
      payMethod(activeItem) {
        this.payActiveItem = activeItem
        this.payMethodShow = true

      },
      changeCode() {
        if (this.codeShow === 1) {
          this.payCode = payCode
          this.codeShow = 0
        } else {
          this.payCode = code
          this.codeShow = 1
        }
      },
      // 确认收款
      confirmCollection() {
        this.confirmCollectionShow = true
      },
      jumpServiceBills() {
        let params = {
              sout: 3,
              id: this.totaleprice.id,
              user: this.totaleprice.user,
              ordernum: this.totaleprice.ordernum,
              paytime: this.totaleprice.paytime,
              manystoreperson_id: this.totaleprice.manystoreperson_id,
              sum: this.totaleprice.sum
            }
        this.$router.push({
          name: 'TopUpBills',
          path: '/top-up-bills',
          query:{
            item:JSON.stringify(params)
          }
        })
        window.sessionStorage.setItem('reservationPath', '/vip-info')

      },
      handleClose() {
        this.payMethodShow = false
        this.confirmCollectionShow = false
      }
    },
  }

</script>

<style lang="less" scoped>
  .usable-card {
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      height: 50px;
      color: #666;

      .left {
        font-weight: bold;
      }

      .right {
        font-size: 12px;
      }


    }

    .card {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      text-align: center;

      .card-item {
        width: 40%;
        margin-bottom: 15px;
        border: 1px solid #e9e9e9;
        padding: 15px 0;

        &:nth-child(2n) {
          margin-left: 15px;
        }

        .top {
          font-size: 12px;
          color: #999;
        }

        .bottom {
          color: #f56c6c;
          font-size: 16px;
          line-height: 40px;

          /deep/ .el-input {
            .el-input__inner {
              border: none;
              text-align: center;
              background-color: #f9f9f9;
              color: #f56c6c;
              font-size: 16px;
              font-weight: bold;

              &::placeholder {
                font-weight: normal;
                font-size: 14px;
              }
            }
          }
        }
      }

      .card-item1 {
        border: 1px solid #409eff;
        background-color: #e6eff9;
      }
    }


  }

  .failure-card {
    .pay-methods {
      padding: 0 15px;
      color: #999;

      .grid {
        display: flex;
        justify-content: space-around;
        align-items: center;

        .grid-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          width: 60px;
          height: 60px;
          background-color: #f2f2f2;
          border-radius: 100%;
          font-size: 12px;
          border: 1px solid #e4e4e4;
        }
      }
    }


  }

  /deep/ .drawer.el-drawer__wrapper {
    .el-drawer {
      min-width: 400px;
    }

    .el-drawer__header {
      padding: 0;
    }

    .nav {
      display: flex;
      justify-content: center;
      border-bottom: 1px solid #f2f2f2;
      padding: 15px 0;

      .nav-item {
        border: 1px solid #6cb5ff;
        color: #6cb5ff;
        width: 20%;
        line-height: 30px;
        text-align: center;
        border-left: none;
        font-size: 12px;

        &:first-child {
          border-left: 1px solid #6cb5ff;
          border-radius: 5px 0 0 5px;
        }

        &:last-child {
          border-radius: 0 5px 5px 0;
        }
      }

      .nav-item1 {
        background-color: #6cb5ff;
        color: #fff;
      }

    }

    .body {
      padding: 30px 50px;

      .title {
        margin-bottom: 50px;
        text-align: center;
        font-size: 18px;
        font-weight: bold;

        b {
          color: #f56c6c;
        }
      }

      .cash {
        .info {
          text-align: right;
          margin: 15px 0;

          span {
            font-weight: bold;
            color: #f56c6c;
          }
        }
      }

      .balance {
        .balance-box {
          display: flex;
          justify-content: space-between;
          margin: 15px 0;
          color: #666;

          .left {
            font-size: 12px;
          }

          .right {
            font-weight: bold;
          }
        }

        .info {
          text-align: right;
          margin: 15px 0;

          span {
            font-weight: bold;
            color: #f56c6c;
          }
        }
      }

      .weChat {
        text-align: center;

        img {
          width: 70%;
          height: auto;
        }
      }

      .card {
        .cell {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          img {
            width: 80px;
          }

          span {
            margin-left: 30px;
          }
        }

        .el-steps {
          margin: 15px 0;

          .el-step__main {
            .el-step__title {
              font-size: 12px;
            }
          }
        }

        .info {
          text-align: center;
          color: #666;

          .i-title {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 5px;
          }

          .img {
            margin-top: 20px;

            img {
              width: 50%;
            }
          }
        }
      }
    }

    .drawer-footer {
      position: fixed;
      bottom: 10px;
      width: 30%;
      min-width: 400px;
      background-color: #fff;
      display: flex;
      justify-content: space-around;

      .cancel,
      .confirm {
        width: 40%;
        line-height: 40px;
        border-radius: 60px;
        text-align: center;
        font-size: 14px;
      }

      .cancel {
        border: 1px solid #46a6ff;
        color: #46a6ff;
      }

      .confirm {
        background-color: #46a6ff;
        color: #fff;
      }
    }
  }

  .confirm-drawer {
    color: #666;

    .top {
      text-align: center;
      margin-top: 20px;

      .el-icon-circle-check {
        font-size: 60px;
        color: #19be6b;
      }

      .text1 {
        margin-top: 15px;
        font-weight: bold;
      }

      .text2 {
        font-size: 12px;
        margin-top: 10px;
        color: #999;
      }
    }

    .center {
      margin-top: 60px;

      .cell {
        display: flex;
        justify-content: space-between;
        line-height: 40px;
        padding: 0 30px;
      }
    }

    .bottom {
      display: flex;
      justify-content: space-around;
      margin-top: 20px;

      .btn {
        width: 40%;
        border: 1px solid #409eff;
        text-align: center;
        line-height: 40px;
        border-radius: 5px;
      }

      .btn1 {
        color: #fff;
        background-color: #409eff;
      }

      .btn2 {
        color: #409eff;
      }
    }
  }

</style>
