<template>
  <el-container style="height:calc(100vh);">
    <el-container style="height:calc(100vh);">
      <el-aside class="aside" width="25%">

        <el-header class="header">
          <span class="left el-icon-arrow-left" @click="returnOn"></span>
          交班记录
          <!-- <span class="right cursor" @click="leftDrawerShow=true">筛选</span> -->
        </el-header>

        <el-container>
          <div class="menu">
            <div class="search">
              <el-input placeholder="输入员工姓名" prefix-icon="el-icon-search" v-model="searchVal"
                @keyup.enter.native="searchChange">
              </el-input>
            </div>
            <ul v-if="succList.length">
              <li class="menu-item" v-for=" (item,i) in succList" :key="i">
                <div :class="activeItem===item.id?'nav nav1':'nav'" @click="routerJump(item)">
                  <div class="avatar">
                    <img :src="$getimgsrc(item.person.avatar)" alt="">
                  </div>
                  <div class="name">
                    <div class="name-box">
                      <div class="text-box">
                        <div class="text">
                          <div class="uname">{{  item.person.realname}}</div>
                          <div class="position">{{  item.person.manypersonrole_id}}</div>
                        </div>
                        <div class="btn" v-if="item.type">{{item.type}}</div>
                      </div>
                      <div class="time el-icon-time">{{  item.day_time | timeform(false)}}</div>
                    </div>

                    <div class="long-time">
                      在班时长：<span>{{  item.shihcang}}</span>
                    </div>
                    <div class="price">
                      收款金额：<span>¥{{ item.sum }}</span>
                    </div>
                  </div>

                </div>
              </li>
            </ul>
          </div>
        </el-container>

      </el-aside>
      <!-- 筛选 -->
      <el-drawer class="drawer" :visible.sync="leftDrawerShow" direction="ltr" :before-close="handleClose">
        <template slot="title">
          <div class="drawer-title">
            交班记录
          </div>
        </template>
        <div class="drawer-body">
          <div class="b-title">
            时间范围
          </div>
          <div class="list-box">
            <div :class="checkTime===i+1?'time-box time-box1 cursor':'time-box cursor'" v-for="(item ,i) in 7" :key='i'
              @click="confirmTime(i+1)">
              {{item}} 天内
            </div>
          </div>
        </div>
      </el-drawer>
      <el-container>
        <!-- <el-header>
          <el-date-picker
          @change="changetime"
            v-model="value2"
            type="datetimerange"
            :picker-options="pickerOptions"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right">
          </el-date-picker>
        </el-header> -->
        <el-main class="main">
          <el-container>
            <div class="content" v-if="succInfo.person && succInfo.person.realname">
              <div class="info">
                <div class="top">
                  <div class="avatar">
                    <img :src="$getimgsrc(succInfo.person.avatar)" alt="">
                  </div>
                  <div class="text">
                    <div class="uname">{{ succInfo.person.realname }}</div>
                    <div class="position">{{  succInfo.person.manypersonrole_id}}</div>
                  </div>
                  <div class="btn" v-if="succInfo.type">{{  succInfo.type}} </div>
                </div>
                <div class="body">
                  <div class="list">
                    <div class="l-left">上班时间</div>
                    <div class="l-right">{{  succInfo.up_time | timeform(true)}}</div>
                  </div>
                  <div class="list">
                    <div class="l-left">交班时间</div>
                    <div class="l-right" v-if="succInfo.after_time">{{  succInfo.after_time | timeform(true)}}</div>
                  </div>
                  <div class="list">
                    <div class="l-left">在班时长</div>
                    <div class="l-right">{{  succInfo.shihcang}}</div>
                  </div>
                  <!-- <div class="list">
                    <div class="l-left">实收金额</div>
                    <div class="l-right">¥{{  succInfo.shouyin}}</div>
                  </div> -->
                </div>
              </div>
              <div class="revenue-price">
                <div class="title">
                  营收金额
                </div>
                <div class="revenue-body">
                  <div class="box">
                    <div class="name">
                      收款金额（元）
                    </div>
                    <div class="price">
                      ￥{{  succInfo.sum}}
                    </div>
                    <div class="num">
                     <span @click="clickDetail('收款明细')">明细</span>
                    </div>
                  </div>
                  <!-- <div class="box">
                    <div class="name">
                      退款金额（元）
                    </div>
                    <div class="price">
                      500.00
                    </div>
                    <div class="num">
                      20笔 <span @click="clickDetail('退款明细')">明细</span>
                    </div>
                  </div>
                  <div class="box">
                    <div class="name">
                      实收金额（元）
                    </div>
                    <div class="price">
                      500.00
                    </div>
                    <div class="num">
                      20笔 <span @click="clickDetail('退款明细')">明细</span>
                    </div>
                  </div> -->
                  <!-- <div class="box">
                    <div class="name">
                      储值金额（元）
                    </div>
                    <div class="price">
                      500.00
                    </div>
                    <div class="num">
                      20笔 <span @click="clickDetail('储值金额')">明细</span>
                    </div>
                  </div> -->
                </div>
                <el-dialog class="dialog" :title="dialogTitle" center :visible.sync="detailShow" width="50%"
                  :before-close="handleClose">
                  <el-container style="height:410px;">
                    <el-main class="cell-list">
                      <div class="list" v-if="dialogTitle==='收款明细'">
                        <div class="card">
                          <div class="card-title iconfont icon-xianjin">
                            现金
                          </div>
                          <div class="card-box">
                            <div class="name">
                              金额（元）
                            </div>
                            <div class="price">
                              {{ succInfo.cash_pay }}
                            </div>
                            <!-- <div class="num">
                              20笔
                            </div> -->
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-title iconfont icon-zhifubao">
                            支付宝
                          </div>
                          <div class="card-box">
                            <div class="name">
                              金额（元）
                            </div>
                            <div class="price">
                              {{ succInfo.aplay_pay }}
                            </div>
                            <!-- <div class="num">
                              20笔
                            </div> -->
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-title iconfont icon-weixin">
                            微信
                          </div>
                          <div class="card-box">
                            <div class="name">
                              金额（元）
                            </div>
                            <div class="price">
                              {{ succInfo.wepay_pay }}
                            </div>
                            <!-- <div class="num">
                              20笔
                            </div> -->
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-title iconfont icon-yue">
                            余额
                          </div>
                          <div class="card-box">
                            <div class="name">
                              金额（元）
                            </div>
                            <div class="price">
                             {{ succInfo.balan_pay }}
                            </div>
                            <!-- <div class="num">
                              20笔
                            </div> -->
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-title iconfont icon-yinxingqia">
                            银行卡
                          </div>
                          <div class="card-box">
                            <div class="name">
                              金额（元）
                            </div>
                            <div class="price">
                              {{ succInfo.card_pay }}
                            </div>
                            <!-- <div class="num">
                              20笔
                            </div> -->
                          </div>
                        </div>
                        <!-- <div class="card">
                          <div class="card-title iconfont icon-jifen">
                            积分抵现
                          </div>
                          <div class="card-box">
                            <div class="name">
                              金额（元）
                            </div>
                            <div class="price">
                              5000.00
                            </div>
                            <div class="num">
                              20笔
                            </div>
                          </div>
                        </div> -->
                      </div>
                      <div class="list" v-if="dialogTitle==='退款明细'">
                        <div class="card">
                          <div class="card-title iconfont icon-xianjin">
                            现金
                          </div>
                          <div class="card-box">
                            <div class="name">
                              金额（元）
                            </div>
                            <div class="price">
                              5000.00
                            </div>
                            <div class="num">
                              20笔
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-title iconfont icon-zhifubao">
                            支付宝
                          </div>
                          <div class="card-box">
                            <div class="name">
                              金额（元）
                            </div>
                            <div class="price">
                              5000.00
                            </div>
                            <div class="num">
                              20笔
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-title iconfont icon-weixin">
                            微信
                          </div>
                          <div class="card-box">
                            <div class="name">
                              金额（元）
                            </div>
                            <div class="price">
                              5000.00
                            </div>
                            <div class="num">
                              20笔
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-title iconfont icon-yue">
                            余额
                          </div>
                          <div class="card-box">
                            <div class="name">
                              金额（元）
                            </div>
                            <div class="price">
                              5000.00
                            </div>
                            <div class="num">
                              20笔
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-title iconfont icon-yinxingqia">
                            银行卡
                          </div>
                          <div class="card-box">
                            <div class="name">
                              金额（元）
                            </div>
                            <div class="price">
                              5000.00
                            </div>
                            <div class="num">
                              20笔
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-title iconfont icon-jifen">
                            积分抵现
                          </div>
                          <div class="card-box">
                            <div class="name">
                              金额（元）
                            </div>
                            <div class="price">
                              5000.00
                            </div>
                            <div class="num">
                              20笔
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="list" v-if="dialogTitle==='储值金额'">
                        <div class="card">
                          <div class="card-title iconfont icon-xianjin">
                            现金
                          </div>
                          <div class="card-box">
                            <div class="name">
                              金额（元）
                            </div>
                            <div class="price">
                              5000.00
                            </div>
                            <div class="num">
                              20笔
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-title iconfont icon-zhifubao">
                            支付宝
                          </div>
                          <div class="card-box">
                            <div class="name">
                              金额（元）
                            </div>
                            <div class="price">
                              5000.00
                            </div>
                            <div class="num">
                              20笔
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-title iconfont icon-weixin">
                            微信
                          </div>
                          <div class="card-box">
                            <div class="name">
                              金额（元）
                            </div>
                            <div class="price">
                              5000.00
                            </div>
                            <div class="num">
                              20笔
                            </div>
                          </div>
                        </div>
                        <div class="card">
                          <div class="card-title iconfont icon-yinxingqia">
                            银行卡
                          </div>
                          <div class="card-box">
                            <div class="name">
                              金额（元）
                            </div>
                            <div class="price">
                              5000.00
                            </div>
                            <div class="num">
                              20笔
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-main>
                  </el-container>
                </el-dialog>
              </div>
              <!-- <div class="revenue-price">
                <div class="title">
                  会员数据
                </div>
                <div class="revenue-body">
                  <div class="box">
                    <div class="name">
                      注册会员（元）
                    </div>
                    <div class="price">
                      500.00
                    </div>
                  </div>
                  <div class="box">
                    <div class="name">
                      订单占比
                    </div>
                    <div class="price">
                      500.00
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
            <!-- <div class="footer">
              <div class="btn">
                重打交班小票
              </div>
            </div> -->
          </el-container>
        </el-main>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
  export default {
    data() {
      return {
        searchVal: '',
        leftDrawerShow: false,
        checkTime: 1,
        item: ['化', '妆', '品', '连', '锁'],
        activeItem: '化',
        detailShow: false,
        dialogTitle: '',
        //交班记录列表
        succList:[],
        //交班详情
        succInfo:{},
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        value1: [new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)],
        value2: [],
        successionId:null,//员工id
      }
    },
    mounted(){
      this.successionId = this.$route.query.id || null;
      this.getSuccessList()
    },
    methods: {
      changetime(){
        this.getSuccessList()
      },
      //获取交班记录
      getSuccessList(){
        this.$http.post('api/store/storeset/workover',{
          id:this.successionId,
          keyword:this.searchVal,
          startime:this.value2[0]?this.value2[0]:'',
          endtime:this.value2[1]?this.value2[1]:''
        }).then(res =>{
          this.succList = res.data.data
          if(this.succList.length != 0){
            this.succInfo = this.succList[0]
            this.activeItem = this.succList[0].id
          }else{
            this.succInfo = {}
            this.activeItem = ''
          }
         
        })
      },
      // 返回上级
      returnOn() {
        this.$router.go(-1)
      },
      routerJump(item) {
        this.activeItem = item.id
        for(let i in this.succList){
          if(this.succList[i].id == item.id){
            this.succInfo = this.succList[i]
          }
        }
      },
      // 筛选
      confirmTime(i) {
        this.checkTime = i
        this.getSuccessList()
        this.leftDrawerShow = false
      },
      // 搜索
      searchChange() {
        this.getSuccessList()
      },
      // 明细
      clickDetail(name) {
        this.dialogTitle = name
        this.detailShow = true

      },
      handleClose() {
        this.leftDrawerShow = false
        this.detailShow = false
      },
    }
  };

</script>
<style lang="less" scoped>
  .el-header {
    position: relative;
    z-index: 1;
    background-color: #fff;
    font-weight: bold;
    font-size: 16px;
    color: #333;
    height: 50px !important;
    line-height: 50px;
    text-align: center;
    border-bottom: 1px solid #e9e9e9;

    .left {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      color: #46a6ff;
      font-size: 24px;
    }

    .right {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 14px;
      font-weight: normal;
      color: #409eff;
    }
  }

  .header {
    position: fixed;
    width: 25%;
    min-width: 300px;
    border-right: 1px solid #e9e9e9;
  }

  // 左侧导航
  .aside {
    position: relative;
    z-index: 99;
    min-height: calc(100vh);
    background-color: #f9f9f9;
    border-right: 1px solid #e9e9e9;
    min-width: 300px;


    .menu {
      width: 100%;
      padding-top: 120px;

      .search {
        position: fixed;
        top: 50px;
        width: 24.9%;
        min-width: 299px;
        padding: 15px;
        box-sizing: border-box;
        background-color: #f9f9f9;
      }

      .menu-item {
        background-color: #fff;
        margin-bottom: 15px;
        border-top: 1px solid #f2f2f2;
        border-bottom: 1px solid #f2f2f2;
        line-height: 30px;

        .nav {
          color: #333;
          padding: 0 20px;
          color: #666;
          box-sizing: border-box;


          display: flex;
          justify-content: flex-start;
          width: 100%;

          .avatar {
            margin-top: 4px;
            width: 40px;
            min-width: 35px;
            height: 40px;
            min-height: 35px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }

          .name {
            margin-left: 15px;
            width: 90%;

            .name-box {
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .text-box {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
              }

              .text {
                margin-right: 15px;
                line-height: 24px;

                .uname {
                  font-size: 14px;
                }

                .position {
                  font-size: 12px;
                  color: #999;
                }
              }

              .btn {
                font-size: 12px;
                color: #fff;
                background-color: #82d588;
                line-height: 14px;
                padding: 2px 10px;
                border-radius: 3px;
                white-space: nowrap;
              }
            }

            .long-time,
            .price {
              font-size: 12px;

              span {
                font-weight: bold;
              }
            }
          }

          .time {
            font-size: 12px;
            color: #999;
            margin-top: 5px;
            min-width: 90px;
            text-align: right;

            &::before {
              margin-right: 5px;
            }
          }
        }

        .nav1 {
          background-color: #ecf5ff;
        }
      }


    }

  }

  .drawer {

    /deep/ .el-drawer {
      min-width: 370px;

      .el-drawer__header {
        margin-bottom: 30px;
        padding: 10px;
        border-bottom: 1px solid #efefef;

        .drawer-title {
          text-align: center;
          font-weight: bold;
          color: #333;
        }
      }
    }

    .drawer-body {
      padding: 10px;

      .b-title {
        padding: 0 0 15px 5px;
      }

      .list-box {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 10px;

        .time-box {
          width: 30%;
          text-align: center;
          border: 1px solid #f0f0f0;
          line-height: 30px;
          margin: 5px 1.3%;
        }

        .time-box1 {
          background-color: #f6faff;
          border: 1px solid #bddeff;
          color: #409eff;
        }
      }
    }
  }

  .main {
    background-color: #f9f9f9;

    .content {
      padding: 20px 20px 75px;
      width: 100%;


      .info {
        padding: 15px;
        background-color: #fff;

        .top {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 15px;

          .avatar {
            width: 40px;
            height: 40px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 100%;
            }
          }

          .text {
            margin: 0 15px;

            .position {
              font-size: 12px;
              color: #999;
            }
          }

          .btn {
            font-size: 12px;
            line-height: 14px;
            padding: 2px 10px;
            color: #fff;
            background-color: #82d588;
            border-radius: 3px;
          }
        }

        .body {
          color: #666;

          .list {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;

            .l-left {
              font-size: 12px;
            }

            .l-right {
              font-weight: bold;
            }
          }
        }
      }

      .revenue-price {
        margin-top: 20px;
        background-color: #fff;
        padding: 15px;

        .title {
          font-weight: bold;
          margin-bottom: 15px;
        }

        .revenue-body {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          color: #666;
          font-size: 12px;

          .box {
            width: 50%;
            margin-bottom: 20px;
            line-height: 30px;

            .price {
              font-weight: bold;
              font-size: 14px;
            }

            .num {
              span {
                color: #4fa5ff;
              }
            }
          }
        }
      }
    }

    .dialog {
      /deep/.el-dialog__body {
        height: 420px;

        .cell-list {
          .list {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .card {
              width: 50%;
              padding: 10px 10px;
              box-sizing: border-box;

              .card-title {
                margin-bottom: 10px;
                font-size: 12px;
              }

              .card-box {
                background-color: #f1f1f1;
                border-radius: 10px;
                padding: 10px;
                line-height: 24px;

                .name {
                  font-size: 12px;
                }

                .price {
                  font-size: 14px;
                  font-weight: bold;
                }
              }
            }
          }
        }
      }

    }

    .footer {
      position: fixed;
      bottom: 0;
      right: 0;
      background-color: #fff;
      padding: 10px;
      width: 75%;
      box-sizing: border-box;
      min-width: 450px;
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #e9e9e9;

      .btn {
        width: 120px;
        font-size: 12px;
        line-height: 40px;
        text-align: center;
        border-radius: 60px;
        color: #409eff;
        border: 1px solid #409eff;
      }
    }
  }



  /deep/ .menu.el-menu {
    border-right: none !important;
    background-color: #f9f9f9;

  }

  .el-menu-item:focus,
  .el-menu-item:hover {
    background-color: #ecf5ff;

  }

  .main.el-main {
    padding: 0 !important;
  }

</style>
