import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import { Message } from 'element-ui'
Vue.use(VueRouter)

const routes = [{
    path: '/',
    component: () => import('@/components/login.vue')
  },
  {
    path: '/login',
    component: () => import('@/components/login.vue')
  },
  {
    path: '/index',
    component: () => import('@/components/index.vue'),
    redirect: '/work',
    children: [
      // 工作台
      {
        path: '/work',
        component: () => import('@/components/work/work.vue')
      },
      // 收银
      {
        name: 'CashReg',
        path: '/cash-reg',
        component: () => import('@/components/cash-reg/cash-reg.vue')
      },
      // 预约
      {
        name: 'Reservation',
        path: '/reservation',
        component: () => import('@/components/reservation/reservation.vue')
      },
      // 面诊
      {
        name: 'MianZheng',
        path: '/mianzheng',
        component: () => import('@/components/work-children/mianzheng.vue'),
      },
      // 会员
      {
        path: '/vip',
        component: () => import('@/components/vip/vip.vue')
      },
      // 售卡
      {
        path: '/sellCard',
        component: () => import('@/components/sellCard/sellCard.vue')
      },
      // 账单
      {
        path: '/bill',
        component: () => import('@/components/bill/bill.vue'),
        meta:{
          keepAlive:true
        }
      },
      // 数据
      {
        path: '/echars-data',
        component: () => import('@/components/echars-data/echars-data.vue')
      },
      //合伙人
      {
        path: '/partner',
        component: () => import('@/components/partner/partner.vue')
      },
      {
        path: '/Schedule',
        component: () => import('@/components/Schedule/Schedule.vue')
      },
      // 更多
      {
        name: 'More',
        path: '/more',
        component: () => import('@/components/more/more.vue')
      },
    ]
  },
  // 设置
  {
    path: '/set',
    component: () => import('@/components/set/set.vue'),
    redirect: '/shop-info',
    children: [
      // 店铺信息
      {
        name: 'ShopInfo',
        path: '/shop-info',
        component: () => import('@/components/set-children/shop-info.vue'),
      },
      // 员工信息
      {
        name: 'StaffInfo',
        path: '/staff-info',
        component: () => import('@/components/set-children/staff-info.vue'),
      },
      // 员工详情
      {
        name: 'StaffDetail',
        path: '/staff-detail',
        component: () => import('@/components/set-children/staff-detail.vue'),
      },
      // 门店设置
      {
        name: 'DoorShopSet',
        path: '/door-shop-set',
        component: () => import('@/components/set-children/door-shop-set.vue'),
      },
      // 优惠设置
      {
        name: 'PreferentialSet',
        path: '/preferential-set',
        component: () => import('@/components/set-children/preferential-set.vue'),
      },
      // 抹零设置
      {
        name: 'MalingSet',
        path: '/maling-set',
        component: () => import('@/components/set-children/maling-set.vue'),
      },
      // 折扣设置
      {
        name: 'DiscountSet',
        path: '/discount-set',
        component: () => import('@/components/set-children/discount-set.vue'),
      },
      // 改价设置
      {
        name: 'GoodsToPriceSet',
        path: '/goods-to-price-set',
        component: () => import('@/components/set-children/goods-to-price-set.vue'),
      },
       // 积分设置
      {
        name: 'Points',
        path: '/Points',
        component: () => import('@/components/set-children/points.vue'),
      },
      // 用户协议
      {
        name: 'UserAgreement',
        path: '/user-agreement',
        component: () => import('@/components/set-children/user-agreement.vue'),
      },
      // 版本信息
      {
        name: 'VersionInfo',
        path: '/version-info',
        component: () => import('@/components/set-children/version-info.vue'),
      },
    ]
  },
  // 消息列表
  {
    path: '/msg-list',
    component: () => import('@/components/work-children/msg-list.vue'),
  },
  // 预约
  // {
  //   path: '/reservation',
  //   component: () => import('@/components/reservation/reservation.vue'),
  // },
  // 预约搜索结果列表
  {
    name: 'ReservationSearchList',
    path: 'reservation-search-list',
    component: () => import('@/components/reservation-children/reservation-search-list.vue'),
  },
  // 添加预约
  {
    name: 'AddReservation',
    path: '/add-reservation',
    component: () => import('@/components/reservation-children/add-reservation.vue'),
  },

  // 添加预约_预约时间
  {
    path: '/about-time-res',
    component: () => import('@/components/reservation-children/about-time-res.vue'),
  },

  // 预约时间
  {
    path: '/about-time',
    component: () => import('@/components/work-children/about-time.vue'),
  },
  // 排班管理
  {
    path: '/scheduling',
    component: () => import('@/components/work-children/scheduling.vue'),
  },
  // 添加会员
  {
    name: 'AddVip',
    path: '/add-vip',
    component: () => import('@/components/vip-children/add-vip.vue'),
  },
  // 编辑会员
  {
    name: 'EditVip',
    path: '/edit-vip',
    component: () => import('@/components/vip-children/edit-vip.vue'),
  },
  // 交班管理
  {
    name: 'Succession',
    path: '/succession',
    component: () => import('@/components/work-children/succession.vue'),
  },
  // 商品管理
  {
    path: '/goods-management',
    component: () => import('@/components/work-children/goods-management.vue'),
  },
  // 添加&修改商品
  {
    name: 'AddGoods',
    path: '/add-goods',
    component: () => import('@/components/work-children/add-goods.vue'),
  },
  // 添加&修改商品
  {
    name: 'EdGoods',
    path: '/ed-goods',
    component: () => import('@/components/work-children/ed-goods.vue'),
  },
  // 商品分类&品牌&规格&单位管理
  {
    name: 'ManageAttr',
    path: '/manage-attr',
    component: () => import('@/components/work-children/manage-attr.vue'),
  },
  // 单据详情
  {
    name: 'OrderDetail',
    path: '/order-detail',
    component: () => import('@/components/work-children/order-detail.vue'),
  },
  // 单据详情
  {
    name: 'FaceorderDetail',
    path: '/faceorder-detail',
    component: () => import('@/components/work-children/faceorder-detail.vue'),
  },
  // 收款结算
  {
    name: 'PayFuwu',
    path: '/pay-fuwu',
    component: () => import('@/components/work-children/pay-fuwu.vue'),
  },
  // 收款结算
  {
    name: 'MianBan',
    path: '/mian-ban',
    component: () => import('@/components/reservation-children/mianban.vue'),
  },
  // 收款结算
  {
    name: 'PaySettle',
    path: '/pay-settle',
    component: () => import('@/components/work-children/pay-settle.vue'),
  },
  // 面诊收款结算
  {
    name: 'FacePaySettle',
    path: '/facepay-settle',
    component: () => import('@/components/work-children/facepay-settle.vue'),
  },
  // 结算完成
  {
    name: 'PayEnd',
    path: '/pay-end',
    component: () => import('@/components/work-children/pay-end.vue'),
  },
  // 积分换购
  {
    name: 'IntegralFor',
    path: '/integral-for',
    component: () => import('@/components/work-children/integral-for.vue'),
  },
  // 服务账单
  {
    name: 'ServiceBills',
    path: '/service-bills',
    component: () => import('@/components/reservation-children/service-bills.vue'),
  },
  // 预约账单
  {
    name: 'ReservationBills',
    path: '/reservation-bills',
    component: () => import('@/components/reservation-children/reservation-bills.vue'),
  },
  // 商品账单
  {
    name: 'GoodsBills',
    path: '/goods-bills',
    component: () => import('@/components/reservation-children/goods-bills.vue'),
  },
  // 售卡账单
  {
    name: 'CardBills',
    path: '/card-bills',
    component: () => import('@/components/reservation-children/card-bills.vue'),
  },
  // 积分账单
  {
    name: 'IntegralBills',
    path: '/integral-bills',
    component: () => import('@/components/reservation-children/integral-bills.vue'),
  },
  // 充值账单
  {
    name: 'TopUpBills',
    path: '/top-up-bills',
    component: () => import('@/components/reservation-children/top-up-bills.vue'),
  },
  // 权益卡账单
  {
    name: 'EquityBills',
    path: '/equity-bills',
    component: () => import('@/components/reservation-children/equity-bills.vue'),
  },
  //添加面诊
  {
    name:'MianZhengAdd',
    path:'/mianzhengadd',
    component: ()=> import('@/components/work-children/mianzheng-add.vue')
  },
  //编辑面诊
  {
    name:'MianZhengEdit',
    path:'/mianzhengedit',
    component: ()=> import('@/components/work-children/mianzheng-edit.vue')
  },
  // 会员信息
  {
    name: 'VipInfo',
    path: '/vip-info',
    component: () => import('@/components/vip-children/vip-info.vue'),
    redirect: '/member-info',
    children: [
      // 会员信息
      {
        name: 'MemberInfo',
        path: '/member-info',
        component: () => import('@/components/vip-children/member-info.vue'),
      },
      //套餐次卡
      {
        name: 'MealCard',
        path: '/meal-card',
        component: () => import('@/components/vip-children/meal-card.vue'),
      },
      //优惠卡券
      {
        name: 'CouponCard',
        path: '/coupon-card',
        component: () => import('@/components/vip-children/coupon-card.vue'),
      },
      // 核销卡券
      {
        name: 'EquityCoupons',
        path: '/equity-coupons',
        component: () => import('@/components/vip-children/equity-coupons.vue'),
      },
      //寄存商品
      {
        name: 'CheckGoods',
        path: '/check-goods',
        component: () => import('@/components/vip-children/check-goods.vue'),
      },
      //预约记录
      {
        name: 'AboutHistory',
        path: '/about-history',
        component: () => import('@/components/vip-children/about-history.vue'),
      },
      //充值记录
      {
        name: 'TopUpHistory',
        path: '/top-up-history',
        component: () => import('@/components/vip-children/top-up-history.vue'),
      },
      //消费记录
      {
        name: 'CostHistory',
        path: '/cost-history',
        component: () => import('@/components/vip-children/cost-history.vue'),
      },
      //积分记录
      {
        name: 'IntegralHistory',
        path: '/integral-history',
        component: () => import('@/components/vip-children/integral-history.vue'),
      },
      //现金充值
      {
        name: 'CashTopUp',
        path: '/cash-top-up',
        component: () => import('@/components/vip-children/cash-top-up.vue'),
      }
    ]
  },
  // 购买卡次
  {
    name: 'SellCardList',
    path: '/sell-card-list',
    component: () => import('@/components/vip-children/sell-card-list.vue'),
  },
  // 购买卡次
  {
    name: 'SellEquityList',
    path: '/sell-equity-list',
    component: () => import('@/components/vip-children/sell-equity-list.vue'),
  },
  // 业务报表
  {
    name: 'BusinessReport',
    path: '/business-report',
    component: () => import('@/components/more-children/business-report.vue'),
  },
  // 服务管理
  {
    name: 'ServiceManagement',
    path: '/service-management',
    component: () => import('@/components/more-children/service-management.vue'),
  },
  // 新增服务
  {
    name: 'AddService',
    path: '/add-service',
    component: () => import('@/components/more-children/add-service.vue'),
  },
  // 编辑服务
  {
    name: 'EditService',
    path: '/edit-service',
    component: () => import('@/components/more-children/edit-service.vue'),
  },
  // 服务分类管理
  {
    name: 'ServiceManageAttr',
    path: '/service-manage-attr',
    component: () => import('@/components/more-children/service-manage-attr.vue'),
  },
  // 库存查询
  {
    name: 'InventoryQuery',
    path: '/inventory-query',
    component: () => import('@/components/more-children/inventory-query.vue'),
  },
  // 低库存预警
  {
    name: 'InventoryWarning',
    path: '/inventory-warning',
    component: () => import('@/components/more-children/inventory-warning.vue'),
  },
  // 入库管理
  {
    name: 'StockManage',
    path: '/stock-manage',
    component: () => import('@/components/more-children/stock-manage.vue'),
  },
  // 入库查询
  {
    name: 'StockQuery',
    path: '/stock-query',
    component: () => import('@/components/more-children/stock-query.vue'),
  },
  // 入库单详情
  {
    name: 'StockOrderDetail',
    path: '/stock-order-detail',
    component: () => import('@/components/more-children/stock-order-detail.vue'),
  },
  // 出库管理
  {
    name: 'OutboundManage',
    path: '/outbound-manage',
    component: () => import('@/components/more-children/outbound-manage.vue'),
  },
  // 出库查询
  {
    name: 'OutboundQuery',
    path: '/outbound-query',
    component: () => import('@/components/more-children/outbound-query.vue'),
  },
  // 出库单详情
  {
    name: 'OutboundOrderDetail',
    path: '/outbound-order-detail',
    component: () => import('@/components/more-children/outbound-order-detail.vue'),
  },
  // 库存盘点
  {
    name: 'StockTaking',
    path: '/stock-taking',
    component: () => import('@/components/more-children/stock-taking.vue'),
  },
  // 盘点查询
  {
    name: 'TakingQuery',
    path: '/taking-query',
    component: () => import('@/components/more-children/taking-query.vue'),
  },
  // 盘点单详情
  {
    name: 'TakingOrderDetail',
    path: '/taking-order-detail',
    component: () => import('@/components/more-children/taking-order-detail.vue'),
  },
  // 商品盘点
  {
    name: 'GoodsTaking',
    path: '/goods-taking',
    component: () => import('@/components/more-children/goods-taking.vue'),
  },
  // 账单流水
  {
    name: 'BillsWater',
    path: '/bills-water',
    component: () => import('@/components/set-children/bills-water.vue'),
  },

]

const router = new VueRouter({
  routes
})
// 挂载路由导航守卫  ?
router.beforeEach((to, from, next) => {
  // to     将要访问的路径
  // from   代表从哪个路径跳转而来
  // next   是一个函数表示放行
  // next() 放行  next("/login") 强制跳转
  //允许前往登录页
  if (to.path === '/login') {
    next()
  }else{
    //检查用户是否登录
    if(store.state.token){
      next()
    }else{
      Message.error('请先登录')
      next("/login")
    }
  }
  
  // const tokenStr = window.sessionStorage.getItem('token')
  // if (!tokenStr) return next('/login')
})
export default router
