<template>
  <div class="list-box">
    <div class="list" v-for="(item,index) in PuTonglist" :key="index">
      <div class="list-left">
        <div class="list-doc">
          <div class="doc-t">
            <img :src="$getimgsrc(item.gzperson.avatar)" alt="">
            <div class="name">{{  item.gzperson.realname}}</div>
            <div class="pro">{{  item.gzperson.manypersonrole_id}}</div>
          </div>
          <div class="doc-b">
            <i class="el-icon-time"></i>{{ item.gzperson.ban }}
          </div>
        </div>
        <div class="list-info">
          <div class="info-t">
            <el-button size="mini">客户信息</el-button>
          </div>
          <div class="info-b">
            {{ item.username }} {{ item.usertel }}
          </div>
        </div>
        <div class="list-info">
          <div class="info-t">
            <el-button size="mini">预约服务</el-button>
          </div>
          <div class="info-b">
            {{ item.server }}
          </div>
        </div>
        <!-- <div class="list-pro">
          <div class="pro-t">
            <el-button size="mini">回访</el-button>
          </div>
          <div class="pro-b">{{  item.types}}</div>
        </div> -->
        <div class="list-pro">
          <div class="pro-t">
            <el-button size="mini">预约时间</el-button>
          </div>
          <div class="pro-b">{{  item.yue_time}}</div>
        </div>
        <div class="list-pro status">
          <div class="pro-t">
            <el-button size="mini">当前状态</el-button>
            <div class="look"
              @click="
                lookOrderDetail(
                  item.status == 1
                    ? '已预约'
                    : item.status == 2
                    ? '服务中'
                    : '已关闭',
                  item.orderserid,
                )
              "
            >
              查看详情<i class="el-icon-arrow-right" color="#666"></i>
            </div>
          </div>
          <div class="status_t">
            <!-- <div class="pro-b">{{ item.statuss }}</div> -->
            <div class="status_b">
              <el-button size="mini">{{item.statuss}}</el-button>
            </div>
          </div>
        </div>
      </div>
        <!-- @click="lookOrderDetail('已关闭')" -->
      <!-- <div class="list-btn">
        <el-button type="primary" size="mini" @click="lookHuiFnag(item)">查看回访记录<i class="el-icon-arrow-right"></i> </el-button>
      </div> -->
    </div>
    <el-dialog title="查看回访" :visible.sync="lookhuifangShow" width="50%">
        <p>回访人：{{ lookitem.follow }}</p>
        <p v-if="lookitem.folltime">回访时间：{{ lookitem.folltime  | timeform(true)}}</p>
        <el-input type="textarea"  :rows="20" v-model="lookitem.content"  placeholder="回访记录" disabled></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="lookhuifangShow = false">确 定</el-button>
        </span>
      </el-dialog>
    <!-- <div class="list list1">
      <div class="list-left">
        <div class="list-doc">
          <div class="doc-t">
            <img src="@/assets/images/avatar.png" alt="">
            <div class="name">顾小敏</div>
            <div class="pro">美容师</div>
          </div>
          <div class="doc-b">
            <i class="el-icon-time"></i>2020-01-03 09:00-10:15
          </div>
        </div>
        <div class="list-pro">
          <div class="pro-t">
            <el-button size="mini">预约项目</el-button>
          </div>
          <div class="pro-b">头皮平衡补水精华、美白祛斑体验、资深美容专家面诊</div>
        </div>
      </div>
      <div class="list-btn">
        <el-button type="primary" size="mini" @click="lookOrderDetail('服务中')">查看详情<i class="el-icon-arrow-right"></i>
        </el-button>
      </div>
    </div>
    <div class="list list2">
      <div class="list-left">
        <div class="list-doc">
          <div class="doc-t">
            <img src="@/assets/images/avatar.png" alt="">
            <div class="name">顾小敏</div>
            <div class="pro">美容师</div>
          </div>
          <div class="doc-b">
            <i class="el-icon-time"></i>2020-01-03 09:00-10:15
          </div>
        </div>
        <div class="list-pro">
          <div class="pro-t">
            <el-button size="mini">预约项目</el-button>
          </div>
          <div class="pro-b">头皮平衡补水精华、美白祛斑体验、资深美容专家面诊</div>
        </div>
      </div>
      <div class="list-btn">
        <el-button type="primary" size="mini" @click="lookOrderDetail('已预约')">查看详情<i class="el-icon-arrow-right"></i>
        </el-button>
      </div>
    </div> -->


  </div>
</template>

<script>
import { mapState } from 'vuex'
  export default {
    data() {
      return {
        lookhuifangShow:false,
        lookitem:{},//查看回访
        PuTonglist:[],//列表
      }
    },
    computed: {
      ...mapState(['vipuser'])
    },
    created() {
      this.getList()
    },
    methods: {
      //查看回访
      lookHuiFnag(item){
        this.lookitem = item
       this.lookhuifangShow = true
      },
      //获取列表
      getList(){
        this.$http.post('api/store/storeuser/pailiebiao',{
          user_id:this.vipuser.id
        }).then(res =>{
          this.PuTonglist = res.data.data
        })
      },
      lookOrderDetail(pageName, id) {
        if (pageName == "已预约") {
          this.$router.push({
            name: "OrderDetail",
            params: {
              pageName,
              id,
            },
          });
        } else {
          this.$router.push({
            path: "/mian-ban", 
            query: {
              item:id
            },
          });

          // this.$router.push({
          //   name: "ServiceBills",
          // });
          window.sessionStorage.setItem("reservationPath", "/reservation");
        }
      },
      // lookOrderDetail(pageName) {
      //   if (pageName !== '已关闭') {
      //     this.$router.push({
      //       name: 'OrderDetail',
      //       params: {
      //         pageName
      //       }
      //     })
      //   } else {
      //     this.$router.push({
      //       name: 'ServiceBills',
      //     })
      //     window.sessionStorage.setItem('reservationPath', '/about-history')
      //   }
      // }
    }
  };

</script>
<style lang="less" scoped>
  .list-box {
    box-sizing: border-box;
    background-color: #f9f9f9;

    .list {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 10px;
      border-left: 4px solid #24d2d3;
      color: #666;
      font-size: 12px;
      background-color: #fff;
      margin-bottom: 15px;
      box-shadow: 0 0 2px #ccc;

      .list-left {
        display: flex;
        justify-content: flex-start;
        width: 100%;

        .list-doc {
          width: 20%;
          min-width: 180px;

          .doc-t {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 10px;

            img {
              width: 35px;
              height: 35px;
              border-radius: 50%;
            }

            .name {
              margin: 0 8px;
            }

            .pro {
              color: #999;
            }
          }
        }

        .list-info {
          width: 20%;
        }

        .list-pro {
          width: 20%;
          // margin-left: 20px;
          .pro-b {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .info-t,
        .pro-t {
          margin-bottom: 18px;

          /deep/.el-button:focus,
          .el-button:hover {
            color: #666 !important;
            border-color: #dcdfe6 !important;
            background-color: #ffffff !important;
          }
        }
      }

      .list-btn {
        width: 20%;
        text-align: center;

        /deep/.el-button:focus,
        .el-button:hover {
          color: #fff !important;
          border-color: #409eff !important;
          background-color: #409eff !important;
        }
      }
    }

    .list1 {
      border-left: 4px solid #ffa94c
    }

    .list2 {
      border-left: 4px solid #51d351;
    }
  }

  .status {

  .pro-t {
    display: flex;
    align-items: center;

    .look {
      margin-left: 20px;
      color: #666;
      font-size: 12px;
    }
  }
  .status_b {
    .el-button {
      color: #409eff !important;
      font-size: 12px;
    }

    /deep/.el-button:focus,
        .el-button:hover {
          color: #409eff !important;
          border-color: #dcdfe6 !important;
          background-color: #ffffff !important;
        }
  }
}
.status_t {
  display: flex;
  align-items: center;

  .list-btn {
    margin-left: 10px;
  }

  .el-button {
      font-size: 12px;
    }
}

</style>
