<template>
  <el-container>
    <el-header>
      版本信息
    </el-header>
    <el-main class="main">
      <div class="body">
        <div class="item" v-for="(item, index) in Info" :key="index">
          <div class="title">
            <div class="name">{{item.name}}</div>
            <div class="time">
              <i class="el-icon-alarm-clock"></i>
              <span>{{item.createtime | timeform(false)}}</span>
            </div>
          </div>
          <div v-html="item.content"></div>
        </div>
      </div>
      <!-- <div class="box" v-for="(item,i) in 10" :key="i">
          <div class="box-title">
            <div class="b-l"><b>智慧门店 v3.1.1</b></div>
            <div class="b-r el-icon-time">2021-01-20</div>
          </div>
          <div class="box-list">
            <div class="list">1、收银中心新增库存展示设置；</div>
            <div class="list">2、库查查询新增显示建议零售价；</div>
            <div class="list">3、支持收银员编辑会员信息、打标签；</div>
            <div class="list">4、会员收银自动定位余额收款，提升收银效率；</div>
            <div class="list">5、会员详情界面优化，会员新增流程优化；</div>
          </div>
        </div> -->
    </el-main>
  </el-container>
</template>

<script>
  export default {
    data () {
      return {
        Info: ''
      }
    },
    mounted () {
      this.getInfo()
    },
    methods: {
      getInfo () {
          this.$http.post('api/store/storeset/configpeizhi').then(res =>{
              this.Info = res.data.data.version
          })
      }
    }
  }

</script>

<style lang="less" scoped>
  .el-header {
    background-color: #fff;
    font-weight: bold;
    font-size: 16px;
    color: #333;
    height: 50px !important;
    line-height: 50px;
    text-align: center;
    border-bottom: 1px solid #e9e9e9;
  }

  .main {
    padding: 20px !important;
    background-color: #f9f9f9;

    .body {
      padding: 15px;
      background-color: #fff;

      .item {
        margin-bottom: 30px;
      }

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #f3f3f3;

        .name {
          font-size: 18px;
          font-weight: bold;
          color: #000;
        }

        .time {
          display: flex;
          align-items: center;

          span {
            color: #666;
            font-size: 14px;
            margin-left: 8px;
          }
        }
      }

      .box {
        margin-bottom: 30px;
        color: #666;

        .box-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #f2f2f2;
          padding: 5px 0 20px;

          .b-r {
            font-size: 12px;
            color: #999;

            &::before {
              margin-right: 5px;
            }
          }
        }

        .box-list {
          padding-top: 20px;

          .list {
            line-height: 30px;
          }
        }
      }
    }
  }

</style>
