<template>
  <el-container style="height: calc(93.5vh)">
    <el-header class="header">
      <span class="left el-icon-arrow-left" @click="returnOn"></span>
      添加预约
    </el-header>
    <!-- <el-header class="header">
      <div class="time">
        <span class="el-icon-arrow-left"></span>
        <el-date-picker 
          v-model="timePicker" 
          :editable="false" 
          type="date" 
          placeholder="选择日期" 
          align="center"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
          prefix-icon="none" clear-icon="none">
        </el-date-picker>
        <span class="el-icon-arrow-right"></span>
      </div>
    </el-header> -->
    <el-container>
      <!-- <el-container style="width:75%; height:calc(87vh);">
        <el-aside class="aside" width="150px">
          <el-menu class="menu">
            <el-menu-item v-for=" (item,index) in item" :key="index">
              <template slot="title">
                <div :class="activeItem===index?'nav1':'nav'" @click="ThisYuanGong(item,index)">
                  <img :src="$getimgsrc(item.avatar)" alt="" style="height: 35px;width: 35px;border-radius: 50%;margin-right: 5px;">
                  <span>{{item.realname}}</span>
                </div>
              </template>
            </el-menu-item>
          </el-menu>
        </el-aside>
        <el-container>
          <el-main class="main">
            <el-row :gutter="15" style="padding:10px 10px 0 0;">
              <el-radio-group v-model="thistime">
                <el-col class="cursor" :span="rowCol" v-for="(item1 ,i) in item[activeItem].time" :key="i">
                  activeItemList.indexOf(item1)!==-1?'order-card order-card1':'order-card'
                  <div class="order-card"
                    @click="activeList(item1)">
                    <div class="card-name">{{ item1.time }}</div>
                    <div class="btm">
                      <div class="btm-time"><el-radio :label="item1" :disabled="item1.per.length != 0"><br></el-radio></div>
                      <div class="btm-price">
                        <del>¥199.00</del>
                        <strong v-if="item1.per.length != 0">已预约</strong>
                        <strong v-if="item1.per.length == 0" style="color: #409eff;">未预约</strong>
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-radio-group>
            </el-row>
          </el-main>
        </el-container>
      </el-container> -->

      <el-main style="width: 75%; height: calc(100vh); padding: 0 !important">
        <el-header>
          <el-row>
            <el-col :span="14" class="btn">
              <!-- <div :class="tabsChange==='1'?'btn1 left':'left'" @click="tabsChange='1'">服务列表</div> -->
              <div
                :class="tabsChange === '2' ? 'btn1 right' : 'right'"
                @click="tabsChange = '2'"
              >
                选择服务
              </div>
            </el-col>
            <el-col :span="10" class="search">
              <!-- <el-input class="searchInput" placeholder="请输入服务或商品名称" prefix-icon="el-icon-search" v-model="searchVal"
              @keyup.enter.native="searchChange">
            </el-input> -->
            </el-col>
          </el-row>
        </el-header>
        <el-container style="height: calc(91vh)">
          <el-aside class="aside" width="150px">
            <el-menu class="menu">
              <el-menu-item v-for="(item, index) in goodList" :key="index">
                <template slot="title">
                  <div
                    :class="activeItem === index ? 'nav1' : 'nav'"
                    @click="activeItem = index"
                  >
                    <span>{{ item.name }}</span>
                  </div>
                </template>
              </el-menu-item>
            </el-menu>
          </el-aside>
          <el-container>
            <el-main class="left-main">
              <el-row :gutter="15" v-if="goodList[activeItem]">
                <el-col
                  class="cursor"
                  :span="rowCol"
                  v-for="(item, i) in goodList[activeItem].server"
                  :key="i"
                >
                  <div
                    :class="
                      activeItemList.indexOf(item) !== -1
                        ? 'order-card order-card1'
                        : 'order-card'
                    "
                    @click="activeList(item)"
                  >
                    <div class="card-name">{{ item.name }}</div>
                    <div class="btm">
                      <div class="btm-time">{{ item.hour }}</div>
                      <div class="btm-price">
                        <del>¥{{ item.original_price }}</del>
                        <strong>¥{{ item.vip_price }}</strong>
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </el-main>
          </el-container>
        </el-container>
      </el-main>
      <el-container
        style="width: 25%; min-width: 300px; border-left: 1px solid #f2f2f2"
      >
        <el-main class="right-main">
          <el-container>
            <el-main style="padding: 0 !important">
              <div class="content">
                <div class="about-info">
                  <div class="info-title">预约信息</div>
                  <div class="user-info">
                    <div class="teacher">
                      <div class="left">预约用户</div>
                      <div class="right" @click="tehDiaLogShow = true">
                        <div class="img">
                          <img
                            :src="$getimgsrc(teacherName.avatar)"
                            alt=""
                            v-if="teacherName.avatar"
                          />
                          <img src="@/assets/images/avatar.png" alt="" v-else />
                        </div>
                        <div class="name">
                          {{ teacherName.realname }}
                          <span class="el-icon-arrow-right"></span>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="about-time">
                      <div class="left">预约时间</div>
                      <router-link to="/about-time-res" class="right">
                        {{ aboutTime ? aboutTime : "14:00" }}
                        <span class="el-icon-arrow-right"></span>
                      </router-link>
                    </div> -->
                  </div>
                  <div class="user-info">
                    <div class="tel">
                      <div class="left">手机</div>
                      <div class="right">
                        <el-input
                          v-model="teacherName.mobile"
                          disabled
                          clearable
                          placeholder="手机号码"
                        ></el-input>
                      </div>
                    </div>
                    <div class="name">
                      <div class="left">姓名</div>
                      <div class="right">
                        <el-input
                          v-model="teacherName.nickname"
                          disabled
                          clearable
                          placeholder="姓名"
                        ></el-input>
                      </div>
                    </div>
                  </div>

                  <div class="user-info">
                    <div class="teacher">
                      <div class="left">{{aboutTime.manypersonrole_id}}</div>
                      <router-link to="/about-time-res">
                        <div class="right">
                          <div class="img">
                            <img
                              :src="$getimgsrc(aboutTime.avatar)"
                              alt=""
                              v-if="aboutTime.avatar"
                            />
                            <img
                              src="@/assets/images/avatar.png"
                              alt=""
                              v-else
                            />
                          </div>
                          <div class="name">
                            {{ aboutTime.realname }}
                            <span class="el-icon-arrow-right"></span>
                          </div>
                        </div>
                      </router-link>
                    </div>
                    <div class="about-time">
                      <div class="left">预约时间</div>
                      <router-link to="/about-time-res" class="right">
                        {{ aboutTime.time ? aboutTime.time : "14:00" }}
                        <span class="el-icon-arrow-right"></span>
                      </router-link>
                    </div>
                  </div>
                </div>
                <div class="check-service">
                  <div class="service-title">已选信息</div>
                  <!-- <div class="service-list">
                    <div class="card">
                      <div class="title">
                        <div class="left">
                          {{ thisyuangong.realname }}
                        </div>
                      </div>
                      <div class="title">
                        <div class="left">
                          职位：{{ thisyuangong.manypersonrole_id }}
                        </div>
                      </div>
                      <div class="time">
                        <div class="left" v-if="aboutTime">
                          预约时间：{{ aboutTime }}
                        </div>
                       
                      </div>
                    </div>
                  </div> -->
                </div>

                <el-main class="order-main">
                  <div class="order" ref="orderWidth">
                    <div class="order-body">
                      <div
                        class="goods-card cursor"
                        v-for="(item, i) in activeItemList"
                        :key="i"
                      >
                        <template>
                          <!-- <div v-if="i" class="card-t">
                    <div class="t-name">{{  item.name}}</div>
                    <div>
                      <span class="t-change iconfont icon-bianji" @click="serviceDialogShow=true"></span>
                      <span class="t-del iconfont icon-cha" @click="delOrderGoods"></span>
                    </div>
                  </div> -->
                          <div class="card-t">
                            <div class="t-name">{{ item.name }}</div>
                            <!-- <div>
                      <span class="t-change iconfont icon-bianji" @click="goodsDialogShow=true"></span>
                      <span class="t-del iconfont icon-cha" @click="delOrderGoods"></span>
                    </div> -->
                          </div>
                        </template>
                        <div class="card-c">
                          <template>
                            <!-- <div v-if="i" class="c-time">30分钟</div> -->
                            <div class="c-time">{{ item.hour }}</div>
                          </template>
                          <div class="c-price">
                            ¥{{ item.original_price }}
                            <span>¥{{ item.vip_price }}</span>
                          </div>
                        </div>
                        <template>
                          <!-- <div v-if="i" class="card-b" @click="orderChangeTeacherShow=true">
                    <div class="b-name">
                      <img src="@/assets/images/avatar.png" alt="">
                      <div class="b-n-name">{{orderTeacherName}}</div>
                    </div>
                    <div class="b-change">选择 <i class="iconfont icon-arrow-right-bold"></i></div>
                  </div> -->
                          <div class="card-b">
                            <div class="b-name">
                              <div class="stepper">
                                <el-input-number
                                  v-model="item.addnum"
                                  :precision="0"
                                  :min="1"
                                  @change="numchang"
                                >
                                </el-input-number>
                              </div>
                            </div>
                            <!-- <div class="b-change">
                      <span>寄存：3</span>
                      <span>赠送：3</span>
                    </div> -->
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </el-main>
              </div>
            </el-main>
          </el-container>
        </el-main>
        <el-footer>
          <div class="order-bottom">
            <div class="btm-t">
              <div class="t-left">
                共<span>{{ activeItemList.length }}</span
                >项 合计：<b>¥{{ allmoney.toFixed(2) }}</b>
              </div>
              <!-- <div>
                已优惠：99.00
              </div> -->
            </div>
            <div class="btm-c">
              <el-input placeholder="填写备注" v-model="remark">
                <i slot="prefix" class="el-input__icon iconfont icon-bi"></i>
              </el-input>
            </div>
            <div class="btm-b">
              <el-button type="primary" round @click="yuyue">预约</el-button>
              <!-- <el-button type="danger" round @click="ordpay">收款</el-button> -->
            </div>
          </div>
        </el-footer>

        <el-footer>
          <div class="order-bottom">
            <!-- <div class="btm-t">
              <div class="t-left">
                共<span>3</span>项 合计：<b>¥399.00</b>
              </div>
              <div>
                已优惠：99.00
              </div>
            </div> -->
            <!-- <div class="btm-c">
              <el-input placeholder="填写备注" v-model="remark">
                <i slot="prefix" class="el-input__icon iconfont icon-bi"></i>
              </el-input>
            </div> -->
            <!-- <div class="btm-b">
              <el-button size="small" type="primary" round @click="yuyue">预约</el-button>
            </div> -->
          </div>
        </el-footer>
      </el-container>
    </el-container>
    <!-- 选择美容师 -->
    <el-dialog
      class="dialog"
      title="选择用户"
      :visible.sync="tehDiaLogShow"
      width="30%"
      :before-close="handleClose"
    >
      <template v-if="true">
        <!-- <div class="alert el-icon-warning">
          合理的排班会让工作更便捷高效
        </div> -->
        <div class="header">
          <div class="search">
            <el-input placeholder="请输入会员昵称、姓名或手机" prefix-icon="el-icon-search" v-model="searchVip"
              @keyup.enter.native="vipSearchChange">
            </el-input>
            <el-button type="primary" @click="vipSearchChange">搜索</el-button>
          </div>
        </div>
        <el-container style="height: 370px">
          <el-main class="cell-list" v-infinite-scroll="load">
            <el-radio-group v-model="cellRadio">
              <el-radio
                :label="i"
                v-for="(item, i) in userList"
                :key="i"
                @change="changeTeacher(item)"
              >
                <div class="title">
                  <img :src="$getimgsrc(item.avatar)" alt="" />
                  {{ item.realname }}
                </div>
              </el-radio>
            </el-radio-group>
          </el-main>
        </el-container>
      </template>
      <template v-if="false">
        <div class="empty-box">
          <div>
            <img src="@/assets/images/empty.png" alt="" />
          </div>
          <div class="text">您还没有排班哦~</div>
          <div>
            <el-button type="primary">立即排班</el-button>
          </div>
        </div>
      </template>
    </el-dialog>
  </el-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      tabsChange: "2",
      item: [],
      activeItem: 0,
      activeItemList: [],
      innerWidth: document.documentElement.clientWidth,
      rowCol: 6,
      //选中的时间
      thistime: "",
      //选中员工
      thisyuangong: {},
      // 预约信息
      telInput: "",
      nameInput: "",
      // 备注
      remark: "",
      //用户列表
      userList: [],
      // 选择美容师
      tehDiaLogShow: false,
      cellRadio: null,
      timePicker:
        new Date().getFullYear() +
        "-" +
        (new Date().getMonth() + 1) +
        "-" +
        new Date().getDate(),
      teacherName: {},
      page: 0,
      //服务列表
      goodList: [],
      allmoney: 0,
      searchVip: ''
    };
  },
  computed: {
    ...mapState(["aboutTime"]),
  },
  watch: {
    innerWidth(val) {
      if (val >= 1440) {
        this.rowCol = 6;
      } else if (val >= 1180) {
        this.rowCol = 8;
      } else if (val >= 900) {
        this.rowCol = 12;
      } else if (val <= 900) {
        this.rowCol = 24;
      }
    },
    timePicker() {
      this.getLookInfo();
    },
  },

  mounted() {
    var that = this;
    if (that.innerWidth >= 1440) {
      that.rowCol = 6;
    } else if (that.innerWidth >= 1180) {
      that.rowCol = 8;
    } else if (that.innerWidth >= 900) {
      that.rowCol = 12;
    } else if (that.innerWidth <= 900) {
      that.rowCol = 24;
    }
    window.onresize = () => {
      return (() => {
        window.fullWidth = document.documentElement.clientWidth;
        that.innerWidth = window.fullWidth; // 宽
      })();
    };
    this.getLookInfo();
    this.getuserList();

    this.getGoodList();
  },
  methods: {
    vipSearchChange () {
        this.getuserList()
    },
    //计算总价格
    computmoney() {
      let money = 0;
      for (let i in this.activeItemList) {
        console.log(this.activeItemList, "sdasda");
        money +=
          Number(this.activeItemList[i].vip_price) *
          Number(this.activeItemList[i].addnum);
      }
      this.allmoney = money;
    },
    //设置数量
    numchang() {
      this.computmoney();
      this.$forceUpdate();
    },
    //获取服务列表
    getGoodList() {
      this.$http.post("api/store/server/serverlist").then((res) => {
        console.log(res);
        this.goodList = res.data.data;
        for (let i in this.goodList) {
          for (let k in this.goodList[i].server) {
            this.goodList[i].server[k]["addnum"] = 1;
          }
        }
        // this.activeItem = this.goodList[0].id
      });
    },
    //点击预约
    yuyue() {
      if (this.activeItemList.length != 0) {
        if (this.teacherName.id) {
          if (this.aboutTime.time) {
            this.$http
              .post("api/store/foolish/addintment", {
                user_id: this.teacherName.id,
                server: this.activeItemList,
                money: this.allmoney,
                remark: this.remark,
                workperson: this.aboutTime.id,
                yuetime: this.aboutTime.time,
                day: this.aboutTime.timePicker,
              })
              .then((res) => {
                this.$message.success(res.data.msg);
                setTimeout(() => {
                  this.returnOn();
                }, 1000);
              });
          } else {
            this.$message.warning("请选择预约时间");
          }
        } else {
          this.$message.warning("请选择用户");
        }
      }else {
          this.$message.warning("请选择服务");
        }
    },
    //滚动加载
    load() {
      if (this.userList.length < (this.page + 1) * 10) return;
      this.page++;
      this.getuserList();
    },
    //用户列表
    getuserList() {
      this.$http
        .post("api/store/storeuser/quickuserlist", {
          page: this.page,
          keyword: this.searchVip
        })
        .then((res) => {
          // this.userList = [...this.userList, ...res.data.data];
          this.userList = res.data.data
        });
    },
    //选中员工
    ThisYuanGong(item, index) {
      this.thisyuangong = item;
      // this.thistime = null
      this.activeItem = index;
    },
    //获取看板信息
    getLookInfo() {
      this.$http
        .post("api/store/booked/paiworklistdetail", {
          time: this.timePicker,
        })
        .then((res) => {
          this.item = res.data.data;
          this.thisyuangong = this.item[0];
          // this.thistime = null
        });
    },
    // 返回上级
    returnOn() {
      this.$router.go(-1);
    },
    activeList(item) {
      console.log(item);
      if (this.activeItemList.indexOf(item) === -1) {
        this.activeItemList.push(item);
      } else {
        this.activeItemList.splice(this.activeItemList.indexOf(item), 1);
      }
      this.numchang();
    },
    // 切换美容师
    changeTeacher(item) {
      this.teacherName = item;
      this.tehDiaLogShow = false;
    },
    handleClose() {
      this.tehDiaLogShow = false;
    },
    // 开单
    openOrder() {
      this.$message({
        message: "开单成功",
        type: "success",
        duration: 1500,
      });
      let that = this;
      setTimeout(() => {
        that.returnOn();
      }, 1500);
    },
  },
};
</script>
<style lang="less" scoped>
.el-icon-arrow-left,
.el-icon-arrow-right {
  font-weight: bold;
  color: #666;
}

/deep/ .el-date-editor.el-input {
  width: 120px;

  .el-input__inner {
    border: 0;
    background-color: transparent;
    text-align: center;
    color: #409eff;
    padding: 0;
  }
}
// 头部
.el-header {
  position: relative;
  z-index: 1;
  background-color: #fff;
  font-weight: bold;
  font-size: 16px;
  color: #333;
  height: 50px !important;
  line-height: 50px;
  text-align: center;
  border-bottom: 1px solid #e9e9e9;

  .left {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: #46a6ff;
    font-size: 24px;
  }
}

// 左侧导航
.aside {
  min-height: calc(93.5vh);
  background-color: #f9f9f9;

  &:first-child {
    padding-top: 30px;
  }

  .nav {
    text-align: center;

    span {
      border: 1px solid transparent;
      padding: 5px 15px;
      border-radius: 50px;
    }
  }

  .nav1 {
    text-align: center;

    span {
      color: #409eff;
      border: 1px solid #409eff;
      padding: 5px 15px;
      border-radius: 50px;
    }
  }
}
.order-card {
  box-shadow: 0px 0px 4px #ccc;
  margin: 8px 0;
  padding: 15px;
  border-radius: 10px;
  background-color: #f4f4f4;
  overflow: hidden;
  border: 1px solid #f4f4f4;

  .card-name {
    font-size: 14px;
    color: #666;
    margin-bottom: 30px;
  }

  .btm {
    color: #999;
    font-size: 12px;

    .btm-time {
      float: left;
    }

    .btm-price {
      float: right;

      strong {
        margin-left: 10px;
        font-size: 14px;
        color: #f56c6c;
      }
    }
  }
}

.order-card1 {
  border: 1px solid #409eff;
  background-color: #e6eff9;
  box-shadow: none;
}
// 左侧商品卡片
.main {
  background-color: #f9f9f9;
}

// 右侧订单
.right-main {
  background-color: #fff;
  height: calc(65vh);

  .content {
    padding: 10px 10px;

    .about-info {
      .info-title {
        line-height: 40px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .user-info {
        padding: 0 10px;
        background-color: #f4f4f4;
        border-radius: 8px;
        margin-bottom: 10px;
        // height: 82px;
        overflow: hidden;

        .tel,
        .name,
        .teacher,
        .about-time {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .teacher,
        .about-time {
          .left,
          .right {
            display: flex;
            align-items: center;
            height: 41px;
          }

          .right {
            width: 70%;
            min-width: 120px;
          }
        }

        // .tel,
        // .teacher {
        //   // border-bottom: 1px solid #e9e9e9;
        // }

        .tel,
        .name {
          box-sizing: border-box;

          /deep/ .el-input {
            .el-input__inner {
              border: none;
              text-align: right;
              font-size: 12px;
              background-color: #f4f4f4;
              padding-right: 0;
            }
          }
        }

        .left {
          color: #666;
          font-size: 12px;
        }

        .right {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          line-height: 40px;
          color: #666;
          font-size: 12px;

          .img {
            width: 30px;
            height: 30px;
            margin-right: 10px;
            border-radius: 50%;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
        }
      }
    }

    .check-service {
      color: #666;

      .service-title {
        line-height: 40px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .service-list {
        .card {
          line-height: 24px;
          box-shadow: 0 0 4px #ccc;
          border-radius: 10px;
          padding: 10px;
          margin-bottom: 10px;

          .title,
          .time {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .title {
            .el-icon-circle-close {
              color: #999;
              font-size: 16px;
            }
          }

          .time {
            .left {
              font-size: 12px;
              color: #999;
            }

            .right {
              .del {
                font-size: 12px;
                color: #999;
                text-decoration-line: line-through;
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}

/deep/ .el-dialog {
  min-width: 400px;
}

.dialog {
  /deep/.el-dialog__body {
    height: 400px;
  }

  .alert {
    color: #ff9900;
    margin-bottom: 20px;
  }

  .cell-list {
    width: 100%;

    /deep/ .el-radio-group {
      width: 100%;
      position: relative;

      .el-radio {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #f2f2f2;
        padding: 10px;
        margin-bottom: 10px;
        margin-right: 0;

        .title {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 12px;

          img {
            width: 40px;
            height: 40px;
            margin-right: 10px;
            border-radius: 50%;
          }
        }

        .el-radio__input {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  .header {
      position: relative;
      background-color: #fff;
      color: #333;
      line-height: 50px;
      height: 50px;
      // border-bottom: 1px solid #e9e9e9;

      .search {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .el-button--primary {
          height: 35px;
          margin-left: -6px;
          z-index: 1;
          font-size: 12px;
          border-radius: 0 5px 5px 0;

        }

        .el-button--primary:focus,
        .el-button--primary:hover,
        .el-button:active,
        .el-button:focus,
        .el-button:hover {
          color: #fff;
          border-color: #409eff;
          background-color: #409eff;
        }

        /deep/ .el-input__inner {
          height: 35px;
          border: 1px solid #dcdfe6;
          border-right: 0;
          border-radius: 5px 0 0 5px;

          &:focus,
          &:hover {
            border-color: #dcdfe6;
          }
        }
      }
    }
}

/deep/ .el-footer {
  padding: 0 !important;
  height: auto !important;

  .order-bottom {
    padding: 10px 10px 20px;
    box-sizing: border-box;
    background-color: #fff;
    color: #666;
    border: 1px solid #e6e6e6;
    border-left: none;

    .btm-t {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      border-bottom: 1px solid #f2f2f2;
      padding: 10px 0;

      .t-left {
        b {
          color: #f56c6c;
        }
      }
    }

    .btm-c {
      border-bottom: 1px solid #f2f2f2;
      margin-bottom: 10px;

      .el-input .el-input__inner {
        border: none !important;
      }
    }

    .btm-b {
      display: flex;
      justify-content: center;

      .el-button {
        width: 100%;
      }
    }
  }
}

/deep/ .menu.el-menu {
  border-right: none !important;
  background-color: #f9f9f9;
}

.el-menu-item:focus,
.el-menu-item:hover {
  background-color: #f9f9f9;
}

.main.el-main {
  padding: 0 10px !important;
}

// 左侧商品卡片
/deep/ .el-main.left-main {
  padding-bottom: 20px !important;
}

// 右侧订单
/deep/ .order-main.el-main {
  padding: 0 0 10px !important;
  width: 100%;
  position: relative;
  background-color: #fff;
}

/deep/ .order-header.el-header {
  height: auto !important;
  width: 100% !important;
  padding: 0 !important;
  border-bottom: none;
}

.order-box {
  padding: 0 10px 10px;
  box-sizing: border-box;
  background-color: #fff;

  .order-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-btn {
      width: 70%;
      line-height: 60px;

      span {
        width: 20%;
        font-size: 12px;
        padding: 5px 5px;
        color: #409eff;
        border: 1px solid #409eff;
        border-radius: 5px;
        margin-left: 10px;
      }
    }

    .right {
      position: relative;
      right: 30px;
      color: #409eff;

      span {
        position: absolute;
        top: 5px;
        right: -15px;
        width: 22px;
        height: 22px;
        font-size: 12px;
        text-align: center;
        line-height: 22px;
        color: #fff;
        background-color: #f56c6c;
        border-radius: 100%;
      }
    }
  }

  .order-vip {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f5f5f5;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    overflow: hidden;
    padding-right: 10px;

    img {
      float: left;
      width: 40px;
      height: 40px;
      margin: 10px 15px;
    }

    .name {
      float: left;
      color: #999;
    }

    b {
      float: right;
      color: #ccc;
      font-size: 20px;
      text-align: center;
      font-weight: normal;
    }
  }
}
.order {
  background-color: #fff;
  padding: 0 10px;

  .order-body {
    .goods-card {
      background-color: #fff;
      box-shadow: 0 0 4px #ccc;
      border-radius: 10px;
      margin-top: 10px;
      padding: 10px;
      color: #666;

      .card-t {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        .t-name {
          width: 70%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        span {
          display: inline-block;
          margin-left: 10px;
          color: #ccc;
        }
      }

      .card-c {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .c-price {
          font-size: 12px;
          color: #999;

          span {
            color: #666;
            font-size: 14px;
          }
        }
      }

      .card-b {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .b-name {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          img {
            width: 30px;
            margin-right: 10px;
          }
        }

        .b-change {
          color: #999;
          font-size: 12px;

          .icon-arrow-right-bold {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.stepper {
  /deep/ .el-input-number {
    width: 90px;
    height: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .el-input-number__decrease,
    .el-input-number__increase {
      width: 24px;
      height: 24px;
      box-sizing: border-box;
      z-index: 1;
      line-height: 22px;
      border-radius: 100%;
      border: 1px solid #666;
      color: #666;
      font-weight: bold;
    }

    .el-input {
      width: 100px;
      height: 34px;
      background-color: transparent;

      .el-input__inner {
        height: 24px;
        line-height: 24px;
        padding: 0 15px;
        border-color: #73a0fa;
        background-color: transparent;
        color: #666;
        font-weight: bold;
        border: none;
      }
    }
  }
}
</style>
