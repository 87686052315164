<template>

  <el-container>
    <el-main class="left-main">
      <el-header>
        <div class="el-icon-arrow-left" @click="returnOn">返回</div>
        <div class="title-body">
          <div class="el-icon-document"></div>
          <div class="text">预约账单</div>
        </div>
        <div class="user-box">
          <div class="user-card">
            <div class="user">
              <div class="avatar">
                <img src="@/assets/images/avatar.png" alt="">
              </div>
              <div class="info">
                <div class="top">
                  <div class="name-tel" v-if="Info.user">
                    <div class="name">
                      {{ Info.user.realname }}
                      <div class="btn">
                        {{ Info.user.dengji }}
                      </div>
                    </div>
                    <div class="tel">{{ Info.user.mobile }}</div>
                  </div>

                </div>
              </div>
            </div>
            <!-- <div class="rem-bind" @click="jumpVipInfo">查看会员信息<span class="el-icon-arrow-right"></span></div> -->
          </div>
        </div>
      </el-header>
      <div class="body">
        <div class="body-title el-icon-document">基本信息</div>
        <div class="cell">
          <div class="cell-list">
            <div class="cl-left">账单编号</div>
            <div class="cl-right">{{ Info.ordernum }}</div>
          </div>
          <div class="cell-list">
            <div class="cl-left">结算时间</div>
            <div class="cl-right">{{ Info.paytime | timeform(true) }}</div>
          </div>
          <div class="cell-list">
            <div class="cl-left">收银人员</div>
            <div class="cl-right">{{ Info.manystoreperson_id }}</div>
          </div>
          <!-- <div class="cell-list">
            <div class="cl-left">收银机号</div>
            <div class="cl-right">A123</div>
          </div> -->
          <div class="cell-list">
            <div class="cl-left">消费项目</div>
            <div class="cl-right">共{{ Info.sum }}项</div>
          </div>
          <!-- <div class="cell-list">
            <div class="cl-left">会员顾问</div>
            <div class="cl-right">顾小敏</div>
          </div> -->
        </div>
      </div>
    </el-main>
    <el-main class="right-main">
      <el-header>
        <div class="btn">
          <div :class="tabsChange==='1'?'btn1 left':'left'" @click="tabsChange='1'">消费项目</div>
          <div :class="tabsChange==='2'?'btn1 cent':'cent'" @click="tabsChange='2'">结账详情</div>
          <!-- <div :class="tabsChange==='3'?'btn1 right':'right'" @click="tabsChange='3'">评价信息</div> -->
        </div>
      </el-header>
      <el-container class="right-main-container">
        <el-main>
          <template v-if="tabsChange==='1'">
            <div class="list" v-for="(item,i) in shopList" :key="i">
              <div class="left">
                <div class="name">{{ item.servername }}({{item.hour}})</div>
                <!-- <div class="time">{{ item.scnum }}{{ item.danwei }}</div> -->
              </div>
              <div class="center" @click="showDio(item)">
                查看服务人员
              </div>
              <!-- <div class="center">
                <div class="avatar">
                  <img src="@/assets/images/avatar.png" alt="">
                </div>
                <div class="name">
                  <span>顾小敏</span>
                  <span>美容师</span>
                </div>
              </div> -->
              <div class="right">
                <b>¥ {{ item.unit_price }}</b>
              </div>
            </div>
          </template>
          <template v-else-if="tabsChange==='2'">
            <div class="end-pay-detail">
              <div class="cell">
                <div class="left">费用合计</div>
                <div class="right"><b>¥ {{ ordInfo.total_price }}</b></div>
              </div>
              <div class="cell">
                <div class="left">等级折扣</div>
                <div class="right">-<b>¥ {{ ordInfo.usergrade }}</b></div>
              </div>
              <!-- <div class="cell">
                <div class="left">会员满200打9折活动</div>
                <div class="right">-<b>¥ 30.00</b></div>
              </div> -->
              <div class="cell" v-if="ordInfo.couponname != ''">
                <div class="left">{{ ordInfo.couponname }}</div>
                <div class="right">-<b>¥ {{ ordInfo.couponmon }}</b></div>
              </div>
              <div class="cell">
                <div class="left">卡券抵扣</div>
                <div class="right">-<b>¥ {{ ordInfo.cardmoney }}</b></div>
              </div>
              <div class="cell">
                <div class="left">积分抵现</div>
                <div class="right">-<b>¥ {{ ordInfo.score_price }}</b></div>
              </div>
              <div class="cell">
                <div class="left">余额抵扣</div>
                <div class="right">-<b>¥ {{ ordInfo.money_price }}</b></div>
              </div>
              <div class="cell">
                <div class="left">整单抹零</div>
                <div class="right">-<b>¥ {{ ordInfo.rub_price }}</b></div>
              </div>
              <div class="cell">
                <div class="left">支付方式</div>
                <div class="right">{{ ordInfo.pay_type }}</div>
              </div>
            </div>
          </template>
          <template v-else-if="tabsChange==='3'">
            <div class="end-pay-detail">
              <p>面诊老师评价</p>
              <el-table
                :data="teachDate"
                border
                style="width: 100%;">
                <el-table-column
                  fixed
                  width="150"
                  prop="manystoreperson_id"
                  label="面诊老师"
                 >
                </el-table-column>
                <el-table-column
                  label="评价人"
                  width="150"
                  >
                  <template slot-scope="scope" >
                      <div style="display: flex;align-items: center;">
                        <el-image
                        style="width: 50px; height: 50px; margin-bottom:-4px;border-radius: 50%;"
                        :src="$getimgsrc(scope.row.user.avatar)"
                        >
                        </el-image>
                        <span style="white-space: nowrap;">{{scope.row.user.nickname}}</span>
                      </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="part"
                  width="150"
                  label="星级"
                  >
                  <template slot-scope="scope" >
                    <el-rate
                      v-model="scope.row.part"
                      disabled
                      show-score
                      text-color="#ff9900"
                      score-template="{value}">
                    </el-rate>
                  </template>
                </el-table-column>
                <el-table-column
                  label="图片"
                  width="350"
                  >
                  <template slot-scope="scope">
                      <el-image
                      @click="bigImg(item)"
                      v-for="(item,index) in scope.row.image"
                      :key="index"
                      style="width: 50px; height: 50px; margin-right:0px;border-radius: 5px;"
                      :src="$getimgsrc(item)"
                      :preview-src-list="srcList"
                      >
                      </el-image>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="content"
                  label="内容"
                  width="250"
                  >
                </el-table-column>
                <el-table-column
                  width="150"
                  label="时间"
                  >
                  <template slot-scope="scope">
                    <p>{{ scope.row.createtime | timeform(false)}}</p>
                  </template>
                </el-table-column>
              </el-table>
              <p>商品/服务评价</p>
              <el-table
                :data="tableData"
                border
                style="width: 100%">
                <el-table-column
                  fixed
                  width="150"
                  prop="shopname"
                  label="商品"
                 >
                </el-table-column>
                <el-table-column
                  label="评价人"
                  width="150"
                  >
                      <template slot-scope="scope" >
                        <div style="display: flex;align-items: center;">
                          <el-image
                          style="width: 50px; height: 50px; margin-bottom:-4px;border-radius: 50%;"
                          :src="$getimgsrc(scope.row.ping.user.avatar)"
                          >
                          </el-image>
                          <span style="white-space: nowrap;">{{scope.row.ping.user.nickname}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                  width="150"
                  label="评分">
                  <template slot-scope="scope" >
                    <el-rate
                      v-model="scope.row.ping.part"
                      disabled
                      show-score
                      text-color="#ff9900"
                      score-template="{value}">
                    </el-rate>
                  </template>
                </el-table-column>
                <el-table-column
                width="350"
                  label="图片">
                  <template slot-scope="scope">
                      <el-image
                      @click="bigImg(item)"
                      v-for="(item,index) in scope.row.ping.image"
                      :key="index"
                      style="width: 50px; height: 50px; margin-bottom:-4px;border-radius: 5px;"
                      :src="$getimgsrc(item)"
                      :preview-src-list="srcList"
                      >
                      </el-image>
                  </template>
                </el-table-column>
                <el-table-column
                  width="250"
                  label="内容">
                  <template slot-scope="scope">
                    <p>{{ scope.row.ping.content }}</p>
                  </template>
                </el-table-column>
                <el-table-column
                  width="150"
                  label="时间">
                  <template slot-scope="scope">
                    <p>{{ scope.row.ping.createtime | timeform(false)}}</p>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
        </el-main>
        <el-footer>
          <div class="foot-box">
            <div class="left">
              实付金额：<b>¥{{ ordInfo.real_price }}</b>
            </div>
            <div class="right" @click="handlePrint()">补打小票</div>
          </div>
        </el-footer>
      </el-container>
    </el-main>
    <el-dialog
      title="服务人员"
      :visible.sync="dialogVisible"
      width="30%"
      >
      <div class="center" v-for="(item,index) in showList" :key="index">
        <div class="avatar">
          <img :src="$getimgsrc(item.avatar)" alt="">
        </div>
        <div class="name">
          <span>{{ item.realname }}</span>
          <span>{{ item.manypersonrole_id }}</span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
  export default {
    data() {
      return {
        srcList:[''],
        dialogVisible:false,
        currentPage: '',
        routerPage: '',
        tabsChange: '1',
        Info:{},
        ordInfo:{},
        shopList:[],//消费项目
        showList:[],
        tableData: [],//商品评价
        teachDate:[],//老师评价
      }
    },
    created () {
      
    },
    mounted() {
      this.Info = JSON.parse(this.$route.query.item)
      this.getOrdInfo()
      this.getPinglun()
      this.currentPage = this.$route.path
      this.routerPage = window.sessionStorage.getItem('reservationPath')
    },
    // destroyed() {
    //   window.sessionStorage.setItem('reservationPath', '/reservation')
    // },
    methods: {
       //大图
       bigImg(url) {
          this.srcList[0] = this.$getimgsrc(url);
        },
      //获取评论信息
      getPinglun(){
        this.$http.post('api/store/census/faceevaluate',{
          id:this.Info.id,
        }).then(res =>{
          this.tableData = res.data.data.shopping
          this.teachDate = res.data.data.mianping
        })
      },
      //打开弹框
      showDio(item){
        console.log(item)
        this.showList = []
        if(item.manystoreperson_id){
          this.showList.push(item.manystoreperson_id)
        }else{
          this.showList = []
        }
        this.dialogVisible = true
      },
      //获取面诊商品详情
      getOrdInfo(){
        this.$http.post('api/store/census/orderdetail',{
          id:this.Info.id,
          type:this.Info.sout
        }).then(res =>{
          this.ordInfo = res.data.data
          this.shopList = res.data.data.shops
        })
      },

      handlePrint() {
        this.$http.post('api/printer/yueorderdayin',{
            id: this.Info.id
          }).then(res =>{
            console.log(res);
          })
      },
      // 返回上级
      returnOn() {
        if (this.routerPage === '/member-info') {
          this.$router.go(-1)
        } else {
          console.log(this.routerPage);
          this.$router.push({
            path: this.routerPage
          })
        }

      },
      jumpVipInfo() {
        this.$router.push({
          name: 'VipInfo',
        })
        window.sessionStorage.setItem('returnPage', this.currentPage)

      },

    }
  };

</script>
<style lang="less" scoped>
  /deep/ .el-container {
    height: calc(100vh);
  }

  /deep/ .left-main.el-main {
    width: 40%;
    min-width: 350px;
    height: calc(100vh);
    padding: 0 !important;

    .el-header {
      position: relative;
      height: auto !important;
      background-color: #1891ff;
      color: #fff;
      padding: 0 !important;

      .el-icon-arrow-left {
        line-height: 40px;
        padding: 0 15px;
      }

      .title-body {
        text-align: center;
        padding: 60px 0 130px;

        .el-icon-document {
          font-size: 120px;
          transform: rotateX(180deg);
        }

        .text {
          font-weight: bold;
          font-size: 18px;
          margin-top: 15px;
        }
      }

      .user-box {
        position: absolute;
        bottom: -25px;
        width: 100%;
        box-sizing: border-box;

        .user-card {
          margin: 0 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
          background-color: #fff;
          border-radius: 5px;
          color: #666;
          box-shadow: 0 0 4px #ccc;

          .user {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .avatar {
              width: 35px;
              height: 35px;
              margin-right: 10px;
              margin-top: 2px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 100%;
              }
            }

            .info {
              .top {
                display: flex;
                justify-content: flex-start;

                .name-tel {
                  margin-right: 20px;
                  line-height: 20px;

                  .name {
                    font-weight: bold;

                    .btn {
                      display: inline-block;
                      font-size: 12px;
                      background-color: #ff9900;
                      line-height: 24px;
                      width: 20%;
                      min-width: 60px;
                      text-align: center;
                      border-radius: 5px;
                      color: #fff;
                      margin-left: 20px;
                    }
                  }

                  .tel {
                    font-size: 12px;
                  }
                }


              }
            }
          }

          .rem-bind {
            font-size: 12px;
            color: #999;
          }
        }
      }

    }

    .body {
      margin-top: 35px;

      .body-title {
        padding: 25px 20px;
        font-weight: bold;
        color: #666;

        &::before {
          color: #ff9900;
          margin-right: 5px;
        }
      }

      .cell {
        .cell-list {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 25px;

          .cl-left {
            color: #999;
          }

        }
      }
    }
  }

  /deep/ .right-main.el-main {
    width: 60%;
    height: calc(100vh);
    padding: 0 !important;

    .el-header {
      display: flex;
      justify-content: center;
      align-items: center;

      .btn {

        .left,
        .right {
          display: inline-block;
          width: 100px;
          height: 30px;
          border: 1px solid #409eff;
          text-align: center;
          line-height: 30px;
          color: #409eff;
          cursor: pointer;
        }
        .cent{
          display: inline-block;
          width: 100px;
          height: 30px;
          border: 1px solid #409eff;
          // border-top: 1px solid #409eff;
          // border-bottom: 1px solid #409eff;
          text-align: center;
          line-height: 30px;
          color: #409eff;
          cursor: pointer;
          border-radius: 0 5px 5px 0;
        }

        .left {
          border-right: 0;
          border-radius: 5px 0 0 5px;
          border-right: 1px solid #409eff;
        }

        .right {
          border-radius: 0 5px 5px 0;
        }

        .btn1 {
          border: 1px solid #409eff;
          text-align: center;
          line-height: 30px;
          color: #fff;
          background-color: #409eff;
        }

      }
    }

    .right-main-container {
      height: calc(100vh - 60px);
      background-color: #f5f5f5;

      .list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        border-radius: 5px;
        padding: 10px;
        margin-top: 15px;

        &:last-child {
          margin-bottom: 15px;
        }


        .left {
          .name {
            margin-bottom: 5px;
          }

          .time {
            font-size: 12px;
            color: #999;
          }
        }

        .center {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .avatar {
            width: 35px;
            height: 35px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 100%;
            }
          }

          .name {

            span {
              margin-left: 10px;

              &:last-child {
                color: #999;

              }
            }
          }
        }

        .right {
          color: #f56c6c;
        }
      }

      .end-pay-detail {
        background-color: #fff;
        margin-top: 15px;
        padding: 15px;
        border: 1px solid #f2f2f2;

        .cell {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 0;
          color: #666;
        }
      }

    }

    .el-footer {
      padding: 15px;
      background-color: #fff;
      border: 1px solid #f2f2f2;
      height: auto;

      .foot-box {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
          b {
            color: #f56c6c;
          }
        }

        .right {
          border: 1px solid #409eff;
          color: #409eff;
          width: 120px;
          text-align: center;
          line-height: 40px;
          border-radius: 30px;
        }
      }
    }

  }
  .center {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .avatar {
            width: 35px;
            height: 35px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 100%;
            }
          }

          .name {

            span {
              margin-left: 10px;

              &:last-child {
                color: #999;

              }
            }
          }
        }
</style>
