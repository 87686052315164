<template>
    <el-container>
        <el-container>
            <el-header style="line-height: 60px;">
                <el-button type="primary" @click="to_mianzhengadd">添加面诊</el-button>
                <div class="date">
                    <el-date-picker
                        v-model="dateVal"
                        format="yyyy-MM-dd hh:mm:ss"
                        value-format="yyyy-MM-dd hh:mm:ss"
                        type="datetime"
                        placeholder="选择日期时间"
                        @change="handleTimeChange">
                    </el-date-picker>
                </div>
                <div class="search">
                    <el-input placeholder="请输入姓名、电话" prefix-icon="el-icon-search" v-model="searchVal"
                        @keyup.enter.native="searchChange">
                    </el-input>
                    <el-button type="primary" @click="searchChange">搜索</el-button>
                </div>
            </el-header>
            <el-main v-loading="loadin">
                <template>
                    <el-table
                        :data="tableData.data"
                        style="width: 100%"
                        >
                        <el-table-column
                        
                        label="用户头像"
                        >
                        <template slot-scope="scope">
                            <el-image
                            style="width: 50px; height: 50px; margin-bottom:-4px;border-radius: 50%;"
                            :src="$getimgsrc(scope.row.user.avatar)"
                            >
                            </el-image>
                        </template>
                        </el-table-column>
                        <el-table-column
                        
                        prop="user.realname"
                        label="用户信息"
                        >
                        </el-table-column>
                        <el-table-column
                        
                        prop="user.mobile"
                        label="用户电话"
                        width="150">
                        </el-table-column>
                        <el-table-column
                        
                        prop="manystoreperson_id"
                        label="面诊人员"
                        >
                        </el-table-column>
                        <el-table-column
                        
                        label="舌苔照（前）"
                        >
                        <template slot-scope="scope">
                            <el-image
                            @click="bigImg(scope.row.fur_image)"
                            style="width: 50px; height: 50px; margin-bottom:-4px;border-radius: 5px;"
                            :src="$getimgsrc(scope.row.fur_image)"
                            :preview-src-list="srcList"
                            >
                            </el-image>
                        </template>
                        </el-table-column>
                        <el-table-column
                        
                        label="舌苔照（后）"
                        >
                        <template slot-scope="scope">
                            <el-image
                            @click="bigImg(scope.row.furs_image)"
                            style="width: 50px; height: 50px; margin-bottom:-4px;border-radius: 5px;"
                            :src="$getimgsrc(scope.row.furs_image)"
                            :preview-src-list="srcList"
                            v-if="scope.row.furs_image"
                            >
                            </el-image>
                            <span v-else>无</span>
                        </template>
                        </el-table-column>
                        <el-table-column
                        
                        label="创建时间"
                        >
                        <template slot-scope="scope">
                           {{ scope.row.createtime | timeform(false)}}
                        </template>
                        </el-table-column>
                        <el-table-column
                        
                        label="收银人员"
                        prop="charge"
                        >
                        </el-table-column>
                        <el-table-column
                        fixed="right"
                        label="操作"
                        width="130">
                        <template slot-scope="scope">
                            <el-button
                            @click.native.prevent="deleteRow(scope.row.id)"
                            type="text"
                            size="small">
                            编辑
                            </el-button>
                        </template>
                        </el-table-column>
                    </el-table>
                </template>
            </el-main>
            <el-footer style="text-align: center;margin-top: 20px;">
                <el-pagination
                background
                layout="prev, pager, next, jumper"
                :page-size="10"
                :total="tableData.total"
                @current-change="CurrentChange">
                </el-pagination>
            </el-footer>
        </el-container>
    </el-container>
</template>

<script>
    export default {
        data() {
            return {
                dateVal: '',
                searchVal: '',
                loadin:false,
                tableData: [],
                page:1,
                srcList:["https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg"]
            }
        },
        created () {
            this.getList()
        },
        methods: {
            //添加面诊
            to_mianzhengadd(){
                this.$router.push({
                    name:'MianZhengAdd'
                })
            },
             //大图
            bigImg(url) {
            this.srcList[0] = this.$getimgsrc(url);
            },
            //分页
            CurrentChange(e){
                this.page = e
                this.getList()
            },

            // 搜索
            searchChange () {
                this.page = 0,
                this.tableData = []
                this.getList()
            },

            //服务列表 (keyword暂未实现)
            getList(){
                console.log(this.dateVal)
                this.loadin = true
                this.$http.post('api/store/face/facelist',{
                    page:this.page,
                    keyword: this.searchVal,
                    time: this.dateVal
                }).then(res =>{
                    this.tableData = res.data.data
                    this.loadin = false
                })
            },

            handleTimeChange () {
                this.getList()
            },
            //移除
            deleteRow(id) {
                this.$router.push({
                    path:'/mianzhengedit',
                    query:{
                        id:id
                    }
                })
            }
        }   
    }
</script>

<style lang="less" scoped>
    .el-header {
        display: flex;
        align-items: center;
    }

    .date {
        width: 30%;
        margin-left: 20px;
    }

    .search {
        width: 50%;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .el-button--primary {
            height: 35px;
            margin-left: -6px;
            z-index: 1;
            font-size: 12px;
            border-radius: 0 5px 5px 0;

        }

        .el-button--primary:focus,
        .el-button--primary:hover,
        .el-button:active,
        .el-button:focus,
        .el-button:hover {
            color: #fff;
            border-color: #409eff;
            background-color: #409eff;
        }

        /deep/ .el-input__inner {
            height: 35px;
            border: 1px solid #dcdfe6;
            border-right: 0;
            border-radius: 5px 0 0 5px;

            &:focus,
            &:hover {
            border-color: #dcdfe6;
            }
        }
    }
</style>