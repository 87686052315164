<template>
  <el-container class="container">
    <el-header class="header">
      <span class="left el-icon-arrow-left" @click="returnOn"></span>
      收款结算
    </el-header>
    <el-container>
      <el-container class="container1 left-container">
        <el-main class="main left-main">
          <div class="title iconfont icon-huiyuan1-01">会员信息</div>
          <template>
            <div v-if="true" class="user-card">
              <div class="user">
                <div class="avatar">
                  <img :src="$getimgsrc(userInfo.avatar)" alt="" v-if="userInfo.avatar" />

                  <img src="@/assets/images/avatar.png" alt="" v-else />
                </div>
                <div class="info">
                  <div class="top">
                    <div class="name-tel">
                      <div class="name" v-if="userInfo.realname">
                        {{ userInfo.realname }}
                      </div>
                      <div class="tel" v-if="userInfo.mobile">
                        {{ userInfo.mobile }}
                      </div>
                    </div>
                    <div class="btn" v-if="userInfo.dengji">
                      {{ userInfo.dengji.name }}
                    </div>
                  </div>
                  <div class="btm">
                    <span v-if="userInfo.yue"
                      >余额：¥ {{ userInfo.yue.money }}</span
                    >
                    <span v-if="userInfo.yue"
                      >积分： {{ userInfo.yue.score }}</span
                    >
                  </div>
                </div>
              </div>
              <!-- iconfont icon-jiebang -->
              <div class="rem-bind" @click="removeBind">取消订单</div>
            </div>
            <div v-else>
              <div class="order-vip cursor" @click="changeVipShow = true">
                <div class="left">
                  <img src="@/assets/images/avatar.png" alt="" />
                  <span class="name">点击选择会员</span>
                </div>
                <b class="iconfont icon-jia"></b>
              </div>
            </div>
          </template>
          <!-- <template>
            <div v-if="!vipConsultant" class="change-vip">
              <div class="left">选择会员顾问</div>
              <div class="right" @click="changeVipConsultantShow=true">选择<span class="el-icon-arrow-right"></span></div>
            </div>
            <div v-else class="change-vip">
              <div class="left">{{vipConsultant}}</div>
              <div class="right">
                <span class="span" @click="vipConsultant='',cellRadio=''">取消选择</span>
                <span class="span" @click="changeVipConsultantShow=true">重新选择</span>
              </div>
            </div>
          </template> -->
          <div class="preferential">
            <div class="pre-title">优惠</div>
            <div class="pre-box">
              <div class="pre-card" @click="couponShow = true">
                <img src="@/assets/images/pay-settle/pre.png" alt="" />
                优惠券
                <div class="num">{{ coupon.length }}</div>
              </div>
              <!-- <div class="pre-card" @click="activeShow=true">
                <img src="@/assets/images/pay-settle/gift.png" alt="">
                优惠活动</div> -->
              <div class="pre-card" @click="cardShow = true">
                <img src="@/assets/images/pay-settle/card.png" alt="" />
                套餐次卡
              </div>
              <div class="pre-card" @click="integralShow = true">
                <img src="@/assets/images/pay-settle/integral.png" alt="" />
                积分抵现
              </div>
              <router-link
                to="/integral-for"
                v-if="settleType !== 'sellCardList'"
                class="pre-card"
              >
                <img src="@/assets/images/pay-settle/label.png" alt="" />
                积分换购</router-link
              >
            </div>
          </div>
        </el-main>
      </el-container>
      <el-container class="container1 right-container">
        <el-main class="main right-main">
          <div class="cost-detail">
            <div class="title iconfont icon-meiyuan">费用信息</div>
            <div class="list-box">
              <div class="list">
                <div class="list-left">费用合计</div>
                <div class="list-right">¥{{ orfInfo.total_price }}</div>
              </div>
              <!-- <div class="list">
                <div class="list-left">议价折扣</div>
                <div class="list-right">¥0.00</div>
              </div> -->
              <div class="list">
                <div class="list-left">会员折扣</div>
                <div class="list-right" v-if="orfInfo.usergrade">
                  ¥{{ orfInfo.usergrade }}
                </div>
              </div>
            </div>
          </div>
          <div class="preferential-price">
            <div class="title">优惠金额</div>
            <div class="list-box">
              <!-- <div class="list">

                <div class="list-left" @click="delCoupon('会员满200打9折活动')">
                  <el-tooltip class="item" effect="dark" content="点击撤销优惠" placement="top-start">
                    <div class="el-icon-circle-close"></div>
                  </el-tooltip>
                  会员满200打9折活动
                </div>


                <div class="list-right">-¥30.00</div>
              </div> -->
              <div class="list" v-if="orfInfo.couponname">
                <div class="list-left" @click="delCoupon('满200减20优惠券')">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="点击撤销优惠"
                    placement="top-start"
                  >
                    <div class="el-icon-circle-close"></div>
                  </el-tooltip>
                  {{ orfInfo.couponname }}
                </div>
                <div class="list-right">-¥{{ orfInfo.couponmon }}</div>
              </div>
              <div class="list" v-if="orfInfo.score_price">
                <div class="list-left">
                  <!-- <el-tooltip class="item" effect="dark" content="点击撤销优惠" placement="top-start">
                    <div class="el-icon-circle-close"></div>
                  </el-tooltip> -->
                  {{ integralInput }} 积分抵现
                </div>
                <div class="list-right">-¥{{ orfInfo.score_price }}</div>
              </div>
              <div class="list" v-if="orfInfo.cardmoney">
                <div class="list-left">卡券抵现</div>
                <div class="list-right">-¥{{ orfInfo.cardmoney }}</div>
              </div>
              <!-- <div class="list">
                <div class="list-left">
                  <el-tooltip class="item" effect="dark" content="点击撤销优惠" placement="top-start">
                    <div class="el-icon-circle-close"></div>
                  </el-tooltip>
                  余额支付
                </div>
                <div class="list-right">-¥{{ orfInfo.money_price }}</div>
              </div> -->
              <div class="list" v-if="orfInfo.rub_price">
                <div class="list-left">
                  <!-- <el-tooltip class="item" effect="dark" content="点击撤销优惠" placement="top-start">
                    <div class="el-icon-circle-close"></div>
                  </el-tooltip> -->
                  整单抹零
                </div>
                <div class="list-right">-¥{{ orfInfo.rub_price }}</div>
              </div>
              <div class="list">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="点击设置抹零金额"
                  placement="top-start"
                >
                  <div
                    class="list-left iconfont icon-xiangpica1"
                    @click="maLingShow = true"
                  >
                    抹零
                  </div>
                </el-tooltip>
                <div class="list-right">
                  <span
                    >待收金额：<b>¥{{ allMoney.toFixed(2) }}</b></span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="pay-methods">
            <div class="title">支付方式</div>
            <div class="grid">
              <div class="grid-item" @click="payMethod(1)">
                <div class="iconfont icon-xianjin"></div>
                <div>现金</div>
              </div>
              <div class="grid-item" @click="payMethod(2)">
                <div class="iconfont icon-yue"></div>
                <div>余额</div>
              </div>
              <div class="grid-item" @click="payMethod(3)">
                <div class="iconfont icon-weixin"></div>
                <div>微信</div>
              </div>
              <div class="grid-item" @click="payMethod(4)">
                <div class="iconfont icon-zhifubao"></div>
                <div>支付宝</div>
              </div>

              <!-- <div class="grid-item" @click="payMethod(5)">
                <div class="iconfont icon-yinxingqia"></div>
                <div>银行卡</div>
              </div> -->
            </div>
            <div class="btn">
              <el-button type="danger" @click="payMethod(1)">结账</el-button>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>
    <!-- 选择会员 -->
    <el-dialog
      class="change-vip-dialog"
      :visible.sync="changeVipShow"
      width="30%"
      center
      :before-close="handleClose"
    >
      <template slot="title">
        <div class="dia-title">
          <span>选择会员</span>
        </div>
      </template>
      <el-input
        v-model="vipTel"
        clearable
        prefix-icon="el-icon-mobile-phone"
        placeholder="请输入会员手机号"
      ></el-input>
      <el-alert
        title="若手机号相匹配，点击确认则选中该会员"
        type="warning1"
        show-icon
        :closable="false"
      >
      </el-alert>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changeVipShow = false">取 消</el-button>
        <el-button type="primary" @click="changeVipShow = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 选择会员顾问 -->
    <el-dialog
      class="dialog"
      title="选择会员顾问"
      center
      :visible.sync="changeVipConsultantShow"
      width="30%"
      :before-close="handleClose"
    >
      <el-container style="height: 400px">
        <el-main class="cell-list">
          <el-radio-group v-model="cellRadio">
            <el-radio
              :label="i"
              v-for="(item, i) in 20"
              :key="i"
              @change="changeVip(item)"
            >
              <div class="title">
                <img src="@/assets/images/avatar.png" alt="" />
                顾小敏(美容师)
              </div>
            </el-radio>
          </el-radio-group>
        </el-main>
      </el-container>
    </el-dialog>
    <!-- 可用优惠券 -->
    <el-dialog
      class="dialog-check"
      title="可用优惠券"
      center
      :visible.sync="couponShow"
      width="30%"
      :before-close="handleClose"
    >
      <el-container style="height: 390px">
        <el-main class="cell-list">
          <div class="alert el-icon-warning-outline">
            仅显示本账单可用优惠券
          </div>
          <el-radio-group v-model="couponCheck">
            <!-- :disabled="!(Number(orfInfo.total_price)>=Number(item.full_money))" -->
            <el-radio :label="item" v-for="(item, i) in coupon" :key="i">
              <div class="card">
                <div class="l-img">
                  <div class="num">¥{{ item.money }}</div>
                  <div class="text">满{{ item.full_money }}元可用</div>
                  <div class="round"></div>
                </div>
                <div class="r-info">
                  <div class="title">
                    {{ item.name }}
                  </div>
                  <div class="introduce">
                    {{ item.server }}
                  </div>
                  <div class="time">
                    有效期至：{{ item.endtime | timeform(false) }}
                  </div>
                </div>
              </div>
            </el-radio>
          </el-radio-group>
        </el-main>
      </el-container>
      <div class="dialog-footer">
        <div class="df-l">
          <!-- <span>共选择 {{  couponCheck.length}} 张</span> -->
          <span
            >立减：<b>¥ {{ couponCheck.money || "0.00" }}</b></span
          >
        </div>
        <div class="df-r">
          <el-button type="primary" @click="checkCoupon">确定</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 优惠活动 -->
    <el-dialog
      class="dialog-check-left"
      title="优惠活动"
      center
      :visible.sync="activeShow"
      width="30%"
      :before-close="handleClose"
    >
      <el-container style="height: 390px">
        <el-main class="cell-list">
          <div class="alert el-icon-warning-outline">
            仅显示本账单可用优惠活动
          </div>
          <el-checkbox-group v-model="activeCheck">
            <el-checkbox :label="i" v-for="(item, i) in 10" :key="i">
              <div class="card">
                <div class="l-info">
                  <div class="title">
                    会员十月8折大优惠活动 <span>会员</span>
                  </div>
                  <div class="introduce">
                    保湿补水、动力SPA补水、美白祛黄护理
                  </div>
                </div>
                <div class="r-price">
                  <div class="span">-¥30.00</div>
                </div>
              </div>
            </el-checkbox>
          </el-checkbox-group>
        </el-main>
      </el-container>
      <div class="dialog-footer">
        <div class="df-l">
          <span>共选择 1 个</span>
          <span>立减：<b>¥ 99.00</b></span>
        </div>
        <div class="df-r">
          <el-button type="primary" @click="checkActive">确定</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 套餐次卡 -->
    <el-dialog
      class="dialog-card"
      title="可用次卡"
      center
      :visible.sync="cardShow"
      width="30%"
      :before-close="handleClose"
    >
      <el-container style="height: 390px">
        <el-main class="cell-list">
          <div class="alert el-icon-warning-outline">仅显示本账单可用次卡</div>
          <el-checkbox-group v-model="CarcheckList">
            <div class="card cursor" v-for="(item, i) in CarList" :key="i">
              <!-- <div v-if="i===0" class="label">
                    限时
                    </div> -->
              <div class="card-top">
                <div class="card-title">
                  <div class="name">
                    <el-checkbox :label="item"><br /></el-checkbox
                    >{{ item.cardname.name }}
                  </div>
                  <div class="price">
                    <b>{{ item.num }}/{{ item.cardname.cardnum }}</b
                    >次
                  </div>
                </div>
                <div class="card-info">项目：{{ item.server_ids }}</div>
              </div>
              <div class="card-btm">
                <div class="card-type" style="font-size: 13px">
                  有效期至：{{ item.endtime | timeform(false) }}
                </div>
                <div class="stepper">
                  <el-input-number
                    v-model="item.cheknum"
                    :precision="0"
                    @change="stepperChange"
                    :min="0"
                    :max="item.allnum"
                  >
                  </el-input-number>
                </div>
              </div>
            </div>
          </el-checkbox-group>
        </el-main>
      </el-container>
      <div class="dialog-footer">
        <div class="df-l">
          <span>共选择 {{ CarcheckList.length }} 个</span>
          <!-- <span>立减：<b>¥ 99.00</b></span> -->
        </div>
        <div class="df-r">
          <el-button type="primary" @click="comCar">确定</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 积分抵现 -->
    <el-dialog
      class="dialog-integral"
      title="积分抵现"
      center
      :visible.sync="integralShow"
      width="30%"
      :before-close="handleClose"
    >
      <el-input
        v-model="integralInput"
        clearable
        placeholder="请输入使用的积分数量"
        maxlength="10"
      ></el-input>
      <div class="btm">
        <p class="b-l">
          可用<b v-if="userInfo.yue">{{ userInfo.yue.score }}</b
          >积分，1积分={{ 1 * orfInfo.jifenbili }}元，积分最多可抵扣<b
            >¥{{ orfInfo.jifenjine.toFixed(2) }}</b
          >
        </p>
        <p class="b-r" v-if="integralInput">
          抵扣金额：<b
            >-¥{{ (integralInput * orfInfo.jifenbili).toFixed(2) }}</b
          >
        </p>
      </div>
      <div class="dialog-footer">
        <div class="df-l"></div>
        <div class="df-r">
          <el-button type="primary" @click="checkIntegral">确定</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 抹零 -->
    <el-dialog
      class="dialog-integral"
      title="抹零"
      center
      :visible.sync="maLingShow"
      width="30%"
      :before-close="handleClose"
    >
      <el-input
        v-model="maLingInput"
        clearable
        placeholder="请输入抹零金额"
        maxlength="10"
      ></el-input>
      <div class="btm">
        <span class="b-r"
          >抹零限额：<b>¥{{ orfInfo.moling }}</b></span
        >
      </div>
      <div class="dialog-footer">
        <div class="df-l"></div>
        <div class="df-r">
          <el-button type="primary" @click="checkMaLing">确定</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 支付方式 -->
    <el-drawer
      class="drawer"
      :visible.sync="payMethodShow"
      direction="rtl"
      :show-close="false"
      :before-close="handleClose"
    >
      <template slot="title">
        <div class="nav">
          <div
            :class="payActiveItem === 1 ? 'nav-item nav-item1' : 'nav-item'"
            @click="handlePayActiveItem(1)"
          >
            现金收款
          </div>
          <div
            :class="payActiveItem === 2 ? 'nav-item nav-item1' : 'nav-item'"
            @click="handlePayActiveItem(2)"
          >
            余额支付
          </div>
          <div
            :class="payActiveItem === 3 ? 'nav-item nav-item1' : 'nav-item'"
            @click="handlePayActiveItem(3)"
          >
            微信
          </div>
          <div
            :class="payActiveItem === 4 ? 'nav-item nav-item1' : 'nav-item'"
            @click="handlePayActiveItem(4)"
          >
            支付宝
          </div>
          <!-- <div
            :class="payActiveItem === 5 ? 'nav-item nav-item1' : 'nav-item'"
            @click="payActiveItem = 5"
          >
            银行卡
          </div> -->
        </div>
      </template>
      <div class="body">
        <div class="title">
          应收金额：<b>¥{{ this.allMoney }}</b>
        </div>
        <div v-if="payActiveItem === 1" class="cash">
          <!-- <el-input v-model="cashInput" clearable placeholder="输入收款金额"></el-input> -->

          <!-- <div class="info">
            找零：<span v-if="cashInput">¥{{(cashInput*1-519).toFixed(2)}}</span>
            <span v-else>¥0.00</span>
          </div> -->
        </div>
        <div v-if="payActiveItem === 2" class="balance">
          <div class="balance-box">
            <div class="left">账户余额</div>
            <div class="right">¥{{ userInfo.yue.money }}</div>
          </div>
          <div class="balance-box">
            <div class="left">支付后剩余余额：</div>
            <div class="right">
              ¥{{
                (Number(userInfo.yue.money) - Number(this.allMoney)).toFixed(2)
              }}
            </div>
          </div>
          <!-- <el-input v-model="balanceInput" clearable placeholder="输入支付金额"></el-input>
          <div class="info">
            支付后剩余余额：<span >¥{{(Number(userInfo.yue.money)-Number(this.allMoney)).toFixed(2)}}</span>
            <span v-else>¥0.00</span>
          </div> -->
        </div>
        <div v-if="payActiveItem === 3" class="weChat">
          <img :src="payCode" alt="" />
          <div v-if="codeShow === 0">使用扫码枪扫描客户付款码</div>
          <el-input ref="input" v-if="codeShow===0" placeholder="流水号" v-model="numNo">
          </el-input>
          <div v-if="codeShow === 2">支付超时</div>
          <div v-if="codeShow === 3">未收到支付结果</div>
        </div>
        <div v-if="payActiveItem === 4" class="weChat">
          <img :src="payCode" alt="" />
          <div v-if="codeShow === 0">使用扫码枪扫描客户付款码</div>
          <el-input ref="input" v-if="codeShow===0" placeholder="流水号" v-model="numNo">
          </el-input>
          <div v-if="codeShow === 2">支付超时</div>
          <div v-if="codeShow === 3">未收到支付结果</div>
        </div>
        <div v-if="payActiveItem === 5" class="card">
          <div class="cell">
            <img src="@/assets/images/pay-settle/card-title.png" alt="" />
            <span>银行卡支付</span>
          </div>
          <el-steps :active="stepsActive" align-center>
            <el-step title="发送账单至POS"></el-step>
            <el-step title="使用POS刷卡"></el-step>
            <el-step title="结账成功"></el-step>
          </el-steps>
          <div class="info">
            <div class="i-title">
              <span v-if="stepsActive === 1">第一步,发送账单至POS</span>
              <span v-if="stepsActive === 2"
                >第二步,使用POS刷卡支付({{ payTime }})s</span
              >
            </div>
            <div class="text">
              <span v-if="stepsActive === 1">请保证POS网络连接顺畅</span>
              <span v-if="stepsActive === 2">未收到账单?请保证POS网络顺畅</span>
            </div>
            <div class="img">
              <img src="@/assets/images/pay-settle/prompt.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="drawer-footer">
        <div
          :class="codeShow === 3 ? 'cancel-confirm' : 'cancel'"
          @click="payMethodShow = false"
        >
          <span v-if="codeShow === 3">已扣款成功</span>
          <span v-else>取消收款</span>
        </div>
        <template>
          <div
            v-if="payActiveItem === 1 || payActiveItem === 2"
            class="confirm"
            @click="pay"
          >
            确认收款
          </div>
          <div
            v-if="payActiveItem === 3 || payActiveItem === 4"
            class="confirm"
            @click="changeCode"
          >
            <!-- <span class="iconfont icon-erweima" v-if="codeShow === 0"
              >客户扫我的收款码</span
            > -->
            <span class="iconfont" v-if="codeShow === 0" @click="handlePay"
              >确认收款</span
            >
            <span v-if="codeShow === 2 || codeShow === 3"
              >重新发起支付</span
            >
          </div>
          <div v-if="payActiveItem === 5" class="confirm">
            <div v-if="stepsActive === 1" @click="stepsActive = 2">
              发送账单
            </div>
            <div v-if="stepsActive === 2" @click="stepsActive = 1">
              重新发送账单
            </div>
          </div>
        </template>
      </div>
    </el-drawer>
  </el-container>
</template>

<script>
import payCode from "../../assets/images/pay-settle/scanCode.png";
import code from "../../assets/images/pay-settle/code.jpg";
export default {
  data() {
    return {
      CarList: [], //套餐次卡
      CarcheckList: [], //选择套餐次卡
      settleType: "",
      // 选择会员
      changeVipShow: false,
      vipTel: "",
      // 会员顾问
      vipConsultant: "",
      changeVipConsultantShow: false,
      cellRadio: null,
      // 可用优惠券
      couponShow: false,
      couponCheck: {
        money: 0,
      },
      showCouponCheck: {},
      // 优惠活动
      activeShow: false,
      activeCheck: [],
      // 套餐次卡
      cardShow: false,
      cardCheck: [],
      stepperNum: 0,
      // 积分抵现
      integralShow: false,
      integralInput: "",
      dikouMoney: 0,
      // 抹零
      maLingShow: false,
      maLingInput: "",
      showmalin: 0,
      // 支付方式
      payMethodShow: false,
      payActiveItem: 1,
      cashInput: "",
      balanceInput: "",
      cardStep: 0,
      payCode: payCode,
      codeShow: 0,
      stepsActive: 1,
      payTime: 60,
      timer: null,
      id: null, //订单id
      orfInfo: {}, //订单详情
      userInfo: {}, //购买人信息
      coupon: [], //优惠卷列表
      allMoney: 0, //结算金额
      overCarId: null, //防止优惠卷金额重复结算
      numNO: ''
    };
  },
  computed: {},
  watch: {
    stepsActive() {
      if (this.stepsActive === 2) {
        this.timer = setInterval(() => {
          this.payTime -= 1;
          if (this.payTime < 0) {
            this.payTime = 60;
            this.stepsActive = 1;
          }
        }, 1000);
      } else {
        clearInterval(this.timer);
        this.payTime = 60;
      }
    },

    payMethodShow (val) {
        if (val && (this.payActiveItem == 3 || this.payActiveItem == 4)) {
          this.$nextTick(() => {
            this.$refs.input.focus()
          })
        }
      }
  },

  mounted() {
    console.log(this.$route.query);
    this.settleType = this.$route.query.type;
    this.id = this.$route.query.id;
    this.getordInfo();
    this.getCarList();
  },
  methods: {
    handlePayActiveItem (num) {
        this.payActiveItem = Number(num)
        this.numNo = ''
        if (num == 3 || num == 4) {
          this.$nextTick(() => {
            this.$refs.input.focus()
          })
        }
      },
    //计算套餐卡次
    comCar() {
      this.$http
        .post("api/store/foolish/carddikou", {
          id: this.id,
          card: this.CarcheckList,
        })
        .then((res) => {
          this.getordInfo();
          this.cardShow = false;
        });
    },
    //获取套餐次卡
    getCarList() {
      this.$http
        .post("api/store/foolish/kaquanlist", {
          id: this.id,
        })
        .then((res) => {
          this.CarList = res.data.data;
          for (let i in this.CarList) {
            this.CarList[i]["cheknum"] = 1;
            this.CarList[i]["cid"] = this.CarList[i].cardname.id;
          }
        });
    },

    //结算订单
    pay() {
      this.$http
        .post("api/store/foolish/orderserverpay", {
          id: this.id,
          real_price: this.allMoney,
          pay_type: this.payActiveItem,
        })
        .then((res) => {
          this.$message.success(res.data.msg);
          setTimeout(() => {
            this.confirmCollection(JSON.stringify(res.data.data));
          }, 1000);
        });
    },

    handlePay() {
      this.$http
        .post("api/store/foolish/orderserverpay", {
          id: this.id,
          real_price: this.allMoney,
          pay_type: this.payActiveItem,
          authcode: this.numNo
        })
        .then((res) => {
          this.$message.success(res.data.msg);
          setTimeout(() => {
            this.confirmCollection(JSON.stringify(res.data.data));
          }, 1000);
        });
    },
    //获取订单信息
    getordInfo() {
      this.$http
        .post("api/store/foolish/serverorderdetail", {
          id: this.id,
        })
        .then((res) => {
          console.log(res);
          this.orfInfo = res.data.data;
          this.userInfo = res.data.data.user;
          this.coupon = res.data.data.coupon;
          this.allMoney =
            Number(res.data.data.total_price) -
            Number(res.data.data.couponmon) -
            Number(res.data.data.score_price) -
            Number(res.data.data.rub_price) -
            Number(res.data.data.usergrade) -
            Number(res.data.data.cardmoney);
        });
    },
    // 返回上级
    returnOn() {
      this.$router.go(-1);
    },
    // 关闭订单
    removeBind() {
      this.$confirm(
        "该订单信息将会关闭，您确认要关闭订单吗？",
        "关闭订单确认",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          center: true,
        }
      )
        .then(() => {
          this.$http
            .post("api/store/foolish/removeorder", {
              id: this.id,
            })
            .then((res) => {
              this.$message({
                type: "success",
                message: res.data.msg,
              });
              setTimeout(() => {
                // this.$router.go(-1);

                this.$router.push({
                  path: "/reservation",
                });
              }, 1000);
            });
          // this.$message({
          //   type: 'success',
          //   message: '解绑成功!'
          // });
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消解绑'
          // });
        });
    },
    // 选择会员顾问
    changeVip(item) {
      this.vipConsultant = item;
      this.changeVipConsultantShow = false;
    },
    // 选择优惠券
    checkCoupon() {
      if (this.couponCheck.id != this.overCarId) {
        this.showCouponCheck = this.couponCheck;
        this.overCarId = this.couponCheck.id;
        this.$http
          .post("api/store/foolish/couponjisuan", {
            id: this.id,
            coupon: this.showCouponCheck.coupon_id,
          })
          .then((res) => {
            this.getordInfo();
          });
        this.couponShow = false;
      } else {
        this.couponShow = false;
      }
    },
    // 选择优惠活动
    checkActive() {
      this.activeShow = false;
    },
    // 选择套餐次卡
    stepperChange(value) {
      this.$forceUpdate();
      // this.stepperNum = value
    },
    // 选择套餐次卡
    checkCard() {
      this.cardShow = false;
    },
    // 选择积分数量
    checkIntegral() {
      if (Number(this.integralInput) <= Number(this.userInfo.yue.score)) {
        if (
          Number(this.integralInput) * Number(this.orfInfo.jifenbili) <=
          Number(this.orfInfo.jifenjine)
        ) {
          this.dikouMoney =
            Number(this.integralInput) * Number(this.orfInfo.score_bili);
          this.$http
            .post("api/store/foolish/scorejisuan", {
              id: this.id,
              score: this.integralInput,
            })
            .then((res) => {
              this.getordInfo();
              this.integralShow = false;
            });
        } else {
          this.$message.warning("抵扣金额不能高于" + this.orfInfo.jifenjine);
        }
      } else {
        this.$message.warning("积分不足");
      }
    },
    // 选择抹零数量
    checkMaLing() {
      if (Number(this.maLingInput) <= Number(this.orfInfo.moling)) {
        this.showmalin = this.maLingInput;
        this.$http
          .post("api/store/foolish/molingjisuan", {
            id: this.id,
            money: this.showmalin,
          })
          .then((res) => {
            this.getordInfo();
          });
        this.maLingShow = false;
      } else {
        this.$message.warning("抹零金额不可高于" + this.orfInfo.moling);
      }
    },
    // 删除优惠
    delCoupon(item) {
      this.$confirm(
        "确认要撤销【" + this.couponCheck.name + "】吗？",
        "撤销优惠",
        {
          confirmButtonText: "确定撤销",
          cancelButtonText: "取消",
          center: true,
        }
      )
        .then(() => {
          this.$http
            .post("api/store/foolish/couponjisuan", {
              id: this.id,
              coupon: 0,
            })
            .then((res) => {
              this.getordInfo();
            });
          this.showCouponCheck = {};
          this.overCarId = null;
          this.couponCheck = {};
          // this.$message({
          //   type: 'success',
          //   message: '撤销成功!'
          // });
        })
        .catch(() => {});
    },
    // 选择支付方式
    payMethod(activeItem) {
      this.payActiveItem = activeItem;
      this.payMethodShow = true;
    },
    changeCode() {
      if (this.codeShow === 1) {
        this.payCode = payCode;
        this.codeShow = 0;
      } else {
        this.payCode = code;
        this.codeShow = 1;
      }
    },
    // 确认收款
    confirmCollection(info) {
      this.$router.push({
        path: "/pay-end",
        query: {
          info: info,
          name: "fuwu",
        },
      });
    },
    handleClose() {
      this.changeVipShow = false;
      this.changeVipConsultantShow = false;
      this.couponShow = false;
      this.activeShow = false;
      this.cardShow = false;
      this.integralShow = false;
      this.maLingShow = false;
      this.payMethodShow = false;
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  height: calc(100vh);
  background-color: #f9f9f9;

  // 头部
  .el-header {
    position: relative;
    z-index: 1;
    background-color: #fff;
    font-weight: bold;
    font-size: 16px;
    color: #333;
    height: 50px !important;
    line-height: 50px;
    text-align: center;
    border-bottom: 1px solid #e9e9e9;

    .left {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      color: #46a6ff;
      font-size: 24px;
    }
  }

  .container1 {
    background-color: #fff;
    box-shadow: 0 0 2px #ccc;
    padding: 0 10px;
    height: calc(90vh);

    .title {
      font-weight: bold;
      color: #666;
      margin-top: 10px;
      margin-bottom: 15px;

      &::before {
        margin-right: 5px;
        color: #fec03d;
        font-weight: normal;
      }
    }
  }

  .left-container {
    width: 40%;
    min-width: 300px;
    margin: 20px 5px 20px 20px;

    .user-card {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      background-color: #333333;
      border-radius: 5px;
      color: #fff;
      margin-bottom: 15px;

      .user {
        display: flex;
        justify-content: flex-start;

        .avatar {
          width: 35px;
          height: 35px;
          margin-right: 10px;
          margin-top: 2px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
          }
        }

        .info {
          .top {
            display: flex;
            justify-content: flex-start;

            .name-tel {
              margin-right: 20px;
              line-height: 20px;

              .name {
                font-weight: bold;
              }

              .tel {
                font-size: 12px;
              }
            }

            .btn {
              font-size: 12px;
              background-color: #ff9900;
              line-height: 24px;
              width: 80px;
              text-align: center;
              height: 24px;
              border-radius: 5px;
            }
          }

          .btm {
            font-size: 12px;
            margin-top: 10px;

            span {
              margin-right: 5px;
            }
          }
        }
      }

      .rem-bind {
        margin-top: 2px;
      }
    }

    .order-vip {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #f5f5f5;
      border: 1px solid #e9e9e9;
      border-radius: 5px;
      overflow: hidden;
      padding-right: 10px;
      margin-bottom: 15px;

      .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        img {
          width: 40px;
          height: 40px;
          margin: 10px 15px;
        }

        .name {
          color: #999;
        }
      }

      b {
        color: #ccc;
        font-size: 20px;
        text-align: center;
        font-weight: normal;
      }
    }

    .change-vip {
      display: flex;
      justify-content: space-between;
      background-color: #f5f5f5;
      border: 1px solid #e9e9e9;
      border-radius: 5px;
      line-height: 50px;
      padding: 0 15px;

      .left {
        color: #666666;
      }

      .right {
        color: #cccccc;

        .span {
          margin: 0 5px;
        }
      }
    }

    .preferential {
      .pre-title {
        line-height: 80px;
        font-weight: bold;
        font-size: 16px;
        color: #666;
      }

      .pre-box {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        .pre-card {
          position: relative;
          width: 48%;
          margin: 10px 0;

          border: 1px solid #e9e9e9;
          border-radius: 5px;
          padding: 20px;
          box-sizing: border-box;
          font-weight: bold;
          color: #666;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          &:nth-child(2n-1) {
            margin-right: 4%;
          }

          img {
            width: 18px;
            height: 18px;
            margin-right: 8px;
            vertical-align: middle;
          }

          .num {
            position: absolute;
            top: -8px;
            right: -8px;
            background-color: #f56c6c;
            color: #fff;
            width: 20px;
            height: 20px;
            text-align: center;
            line-height: 20px;
            font-size: 12px;
            font-weight: normal;
            border-radius: 100%;
          }
        }
      }
    }
  }

  .right-container {
    width: 60%;
    min-width: 450px;
    margin: 20px 20px 20px 5px;
    padding: 0;

    .cost-detail {
      border-bottom: 1px solid #f2f2f2;
      padding-bottom: 10px;
      padding: 0 15px 10px;

      .list-box {
        padding: 0 10px;

        .list {
          display: flex;
          justify-content: space-between;
          align-items: center;
          line-height: 40px;
          color: #666;

          .list-right {
            font-weight: bold;
          }
        }
      }
    }

    .preferential-price {
      padding: 0 15px;

      .title {
        font-size: 16px;
      }

      .list-box {
        .list {
          display: flex;
          justify-content: space-between;
          align-items: center;
          line-height: 40px;
          color: #666;

          &:last-child {
            .list-left {
              font-size: 12px;
              border: 1px solid #e4e4e4;
              line-height: 30px;
              width: 70px;
              text-align: center;
              border-radius: 5px;
              color: #999;

              &::before {
                color: #999;
              }
            }
          }

          .list-left {
            &::before {
              margin-right: 2px;
            }
          }

          .list-right {
            font-weight: bold;

            span {
              font-weight: normal;
              font-size: 12px;

              b {
                font-size: 16px;
                font-weight: bold;
                color: #f56c6c;
              }
            }
          }
        }
      }
    }

    .pay-methods {
      padding: 0 15px;

      .title {
        font-size: 16px;
      }

      .grid {
        display: flex;
        justify-content: space-around;
        align-items: center;

        .grid-item {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          width: 60px;
          height: 60px;
          background-color: #f2f2f2;
          border-radius: 100%;
          font-size: 12px;
          border: 1px solid #e4e4e4;
        }
      }

      .btn {
        margin-top: 30px;
        text-align: center;

        .el-button {
          width: 100%;
          max-width: 600px;
        }
      }
    }
  }
}

/deep/ .el-dialog {
  min-width: 400px;

  .el-dialog__header {
    border-bottom: 1px solid #f2f2f2;
  }

  .el-dialog__body {
    height: 400px;
    padding: 0 10px;
  }
}

.change-vip-dialog {
  /deep/.el-dialog__body {
    height: auto;

    .el-input {
      .el-input__inner {
        text-align: left !important;
        border: 1px solid #dcdfe6 !important;
      }
    }

    .el-alert {
      padding: 0 10px;
      margin-top: 10px;
    }

    .el-alert--warning1 {
      background-color: #ebf5ff;
      color: #409eff;
      border: 1px solid #e5f2ff;
    }
  }
}

.dialog {
  .cell-list {
    width: 100%;

    /deep/ .el-radio-group {
      width: 100%;
      position: relative;
      padding-top: 10px;

      .el-radio {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #f2f2f2;
        padding: 10px;
        margin-bottom: 10px;
        margin-right: 0;

        .title {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 12px;

          img {
            width: 40px;
            height: 40px;
            margin-right: 10px;
          }
        }

        .el-radio__input {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}

.dialog-check {
  .alert {
    color: #ff9900;
    margin: 20px 0 10px;
  }

  /deep/ .el-dialog__body {
    height: 450px;
    padding: 0 10px;
  }

  .cell-list {
    width: 100%;

    /deep/ .el-radio-group {
      width: 100%;
      position: relative;
      padding-top: 10px;

      .el-radio__input.is-checked + .el-radio__label {
        color: #666;
      }

      .el-radio__label {
        padding-left: 0;
      }

      .el-radio {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #f2f2f2;
        margin-bottom: 10px;
        margin-right: 0;

        .el-radio__input {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);

          .el-radio__inner {
            border-radius: 100%;
          }
        }

        .card {
          display: flex;
          justify-content: flex-start;
          width: 100%;

          .l-img {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-width: 100px;
            width: 120px;
            height: 100px;
            background-color: #ffa94c;
            color: #fff;
            text-align: center;

            .num {
              font-size: 24px;
              font-weight: bold;
              margin-bottom: 5px;
            }

            .round {
              position: absolute;
              top: 50%;
              left: -9px;
              transform: translateY(-50%);
              width: 15px;
              height: 15px;
              background-color: #fff;
              border-radius: 100%;
            }
          }

          .r-info {
            padding: 10px 30px 10px 10px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            .title,
            .introduce {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .title {
              font-size: 16px;
              font-weight: bold;
            }

            .introduce {
              font-size: 12px;
              color: #999;
              margin: 8px 0 12px;
            }
          }
        }
      }
    }
  }

  .dialog-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    .df-l {
      span {
        margin-right: 10px;

        b {
          color: #f56c6c;
        }
      }
    }

    .df-r {
      .el-button {
        width: 120px;
      }
    }
  }
}

.dialog-check-left {
  .alert {
    color: #ff9900;
    margin: 20px 0 10px;
  }

  /deep/ .el-dialog__body {
    height: 450px;
    padding: 0 10px;
  }

  .cell-list {
    width: 100%;

    /deep/ .el-checkbox-group {
      width: 100%;
      position: relative;
      padding-top: 10px;

      .el-checkbox__input.is-checked + .el-checkbox__label {
        color: #666;
      }

      .el-checkbox__label {
        padding-left: 0;
        width: 100%;
      }

      .el-checkbox {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: 1px solid #f2f2f2;
        margin-bottom: 10px;
        margin-right: 0;
        width: 100%;
        background-color: #f8f8f8;
        border-radius: 10px;

        .el-checkbox__input {
          margin: 0 20px;

          .el-checkbox__inner {
            border-radius: 100%;
          }
        }

        .card {
          position: relative;
          left: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 10px 0;
          box-sizing: border-box;

          .l-info {
            width: 70%;

            .title {
              font-size: 16px;
              font-weight: bold;
              margin-bottom: 5px;

              span {
                font-size: 12px;
                font-weight: normal;
                display: inline-block;
                border: 1px solid #ff9900;
                color: #ff9900;
                border-radius: 5px;
                padding: 1px 10px;
                margin-left: 5px;
              }
            }

            .introduce {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          .r-price {
            width: 30%;
            text-align: right;

            .span {
              padding-right: 10px;
            }
          }
        }
      }
    }
  }

  .dialog-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    .df-l {
      span {
        margin-right: 10px;

        b {
          color: #f56c6c;
        }
      }
    }

    .df-r {
      .el-button {
        width: 120px;
      }
    }
  }
}

.dialog-card {
  .alert {
    color: #ff9900;
    margin: 20px 0 10px;
  }

  /deep/ .el-dialog__body {
    height: 450px;
    padding: 0 10px;
  }

  .cell-list {
    width: 100%;

    .card {
      position: relative;
      border: 3px solid #73a0fa;
      margin: 10px 0 20px;
      border-bottom: none;
      overflow: hidden;

      .label {
        position: absolute;
        top: 0;
        left: -33px;
        transform: rotate(-45deg);
        background-color: #f95e5a;
        color: #fff;
        font-size: 12px;
        width: 90px;
        line-height: 24px;
        text-align: center;
      }

      .card-top {
        padding: 10px;

        .card-title,
        .card-time {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .card-title {
          .name {
            font-size: 14px;
            font-weight: bold;
          }

          .price {
            font-size: 12px;
            font-weight: bold;

            b {
              font-size: 14px;
              color: #ff9900;
            }
          }
        }

        .card-info {
          font-size: 12px;
          color: #999;
          margin: 10px 0;
        }
      }

      .card-btm {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #73a0fa;
        font-weight: bold;
        color: #fff;
        padding: 10px;
        border-bottom: 3px dashed #fff;

        .stepper {
          /deep/ .el-input-number {
            width: 100px;
            height: 24px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .el-input-number__decrease,
            .el-input-number__increase {
              width: 24px;
              height: 24px;
              box-sizing: border-box;
              z-index: 1;
              line-height: 20px;
              border-radius: 100%;
              border: 2px solid #fff;
              background-color: #73a0fa;
              color: #fff;
              font-weight: bold;
            }

            .el-input {
              width: 100px;
              height: 34px;

              .el-input__inner {
                height: 24px;
                line-height: 24px;
                padding: 0 15px;
                border-color: #73a0fa;
                background-color: #73a0fa;
                color: #fff;
                font-weight: bold;
                border: none;
              }
            }
          }
        }
      }
    }
  }

  .dialog-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    .df-l {
      span {
        margin-right: 10px;

        b {
          color: #f56c6c;
        }
      }
    }

    .df-r {
      .el-button {
        width: 120px;
      }
    }
  }
}

.dialog-integral {
  /deep/ .el-dialog__body {
    height: auto;
    padding: 10px;
  }

  .btm {
    // display: flex;
    // justify-content: space-between;
    margin: 10px 0;

    .b-l {
      b {
        color: #ff9900;
        margin: 0 5px;
      }
    }

    .b-r {
      b {
        color: #f56c6c;
      }
    }
  }

  .dialog-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    .df-l {
      span {
        margin-right: 10px;

        b {
          color: #f56c6c;
        }
      }
    }

    .df-r {
      .el-button {
        width: 120px;
      }
    }
  }
}

/deep/ .drawer.el-drawer__wrapper {
  .el-drawer {
    min-width: 400px;
  }

  .el-drawer__header {
    padding: 0;
  }

  .nav {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #f2f2f2;
    padding: 15px 0;

    .nav-item {
      border: 1px solid #6cb5ff;
      color: #6cb5ff;
      width: 20%;
      line-height: 30px;
      text-align: center;
      border-left: none;
      font-size: 12px;

      &:first-child {
        border-left: 1px solid #6cb5ff;
        border-radius: 5px 0 0 5px;
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
      }
    }

    .nav-item1 {
      background-color: #6cb5ff;
      color: #fff;
    }
  }

  .body {
    padding: 30px 50px;

    .title {
      margin-bottom: 50px;
      text-align: center;
      font-size: 18px;
      font-weight: bold;

      b {
        color: #f56c6c;
      }
    }

    .cash {
      .info {
        text-align: right;
        margin: 15px 0;

        span {
          font-weight: bold;
          color: #f56c6c;
        }
      }
    }

    .balance {
      .balance-box {
        display: flex;
        justify-content: space-between;
        margin: 15px 0;
        color: #666;

        .left {
          font-size: 12px;
        }

        .right {
          font-weight: bold;
        }
      }

      .info {
        text-align: right;
        margin: 15px 0;

        span {
          font-weight: bold;
          color: #f56c6c;
        }
      }
    }

    .weChat {
      text-align: center;

      img {
        width: 70%;
        height: auto;
      }
    }

    .card {
      .cell {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        img {
          width: 80px;
        }

        span {
          margin-left: 30px;
        }
      }

      .el-steps {
        margin: 15px 0;

        .el-step__main {
          .el-step__title {
            font-size: 12px;
          }
        }
      }

      .info {
        text-align: center;
        color: #666;

        .i-title {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 5px;
        }

        .img {
          margin-top: 20px;

          img {
            width: 50%;
          }
        }
      }
    }
  }

  .drawer-footer {
    position: fixed;
    bottom: 10px;
    width: 30%;
    min-width: 400px;
    background-color: #fff;
    display: flex;
    justify-content: space-around;

    .cancel,
    .confirm,
    .cancel-confirm {
      width: 40%;
      line-height: 40px;
      border-radius: 60px;
      text-align: center;
      font-size: 14px;
    }

    .cancel {
      border: 1px solid #46a6ff;
      color: #46a6ff;
    }

    .confirm {
      background-color: #46a6ff;
      color: #fff;
    }

    .cancel-confirm {
      background-color: #f56c6c;
      color: #fff;
    }
  }
}

.main.el-main {
  padding: 0 5px !important;
}

.main.el-main.right-main {
  padding: 0 !important;
}
</style>
