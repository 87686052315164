<template>
  <el-container style="height:calc(100vh);">
    <el-container style="height:calc(100vh);">
      <el-aside class="aside" width="25%">

        <el-header class="header">
          <span class="left el-icon-arrow-left" @click="returnOn"></span>
          消息列表
        </el-header>

        <el-container>

          <ul class="menu">
            <li class="menu-item" v-for=" (item,i) in List" :key="i">
              <div :class="activeItem===item?'nav nav1':'nav'" @click="routerJump(item)">
                <div class="title">{{item.title}}</div>
                <div class="time el-icon-time" v-if="item.createtime">{{ item.createtime | timeform(false) }}</div>
              </div>
            </li>
          </ul>

        </el-container>

      </el-aside>
      <el-container>
        <el-header>
          消息详情
        </el-header>
        <el-main class="main">
          <el-container>
            <div class="content">
              <div class="msg-list-detail">
                <div class="title">{{activeItem.title}}</div>
                <div class="time el-icon-time" v-if="activeItem.createtime">{{ activeItem.createtime | timeform(false) }}</div>
                <div class="text" v-html="activeItem.content"></div>
              </div>

            </div>
          </el-container>
        </el-main>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
  export default {
    data() {
      return {
        item: ['化', '妆', '品', '连', '锁'],
        activeItem: {},
        List:[],
      }
    },
    created() {
      this.getList()
    },
    methods: {
      routerJump(item) {
        this.activeItem = item
      },
      //检查库存预警
      getList(){
        this.$http.post('api/store/index/message').then(res =>{
            this.List = res.data.data
            if(this.List != 0){
              this.activeItem = this.List[0]
            }
        })
      },
      // 返回上级
      returnOn() {
        let a = window.sessionStorage.getItem('activePath')
        this.$router.push({
          path: a
        })
      },
    }
  };

</script>
<style lang="less" scoped>
  .el-header {
    position: relative;
    z-index: 1;
    background-color: #fff;
    font-weight: bold;
    font-size: 16px;
    color: #333;
    height: 50px !important;
    line-height: 50px;
    text-align: center;
    border-bottom: 1px solid #e9e9e9;

    .left {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      color: #46a6ff;
      font-size: 24px;
      font-weight: bold;
    }
  }

  .header {
    position: fixed;
    width: 25%;
    min-width: 200px;
    border-right: 1px solid #e9e9e9;
  }

  // 左侧导航
  .aside {
    min-height: calc(100vh);
    background-color: #f9f9f9;
    border-right: 1px solid #e9e9e9;
    min-width: 200px;

    .menu {
      width: 100%;
      padding-top: 65px;


      .menu-item {
        background-color: #fff;
        margin-bottom: 15px;
        border-top: 1px solid #f2f2f2;
        border-bottom: 1px solid #f2f2f2;
        line-height: 30px;

        .nav {
          color: #333;
          padding: 0 20px;
          color: #666;

          .time {
            font-size: 12px;
            color: #999;

            &::before {
              margin-right: 5px;
            }
          }
        }

        .nav1 {
          background-color: #ecf5ff;
        }
      }


    }

  }

  .main {
    background-color: #f9f9f9;

    .content {
      padding: 15px;
      width: 100%;

      .msg-list-detail {
        padding: 10px;
        background-color: #fff;
        color: #666;

        .title {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 10px;
          border-left: 4px solid #409eff;
          padding-left: 10px;
        }

        .time {
          padding-left: 15px;
          color: #999;

          &::before {
            margin-right: 5px;
          }
        }

        .text {
          margin-top: 30px;
        }
      }
    }
  }



  /deep/ .menu.el-menu {
    border-right: none !important;
    background-color: #f9f9f9;

  }

  .el-menu-item:focus,
  .el-menu-item:hover {
    background-color: #ecf5ff;

  }

  .main.el-main {
    padding: 0 !important;
  }

</style>
