<template>
  <div class="index-container">
    <el-container style="height:calc(100vh)">
      <el-aside width="80px">
        <div class="container-left">
          <div class="avatar-box" @click="rightShow=true">
            <img :src="$getimgsrc(userinfor.avatar)" alt="">
            <div>{{ userinfor.realname }}</div>
          </div>
          <!-- 用户信息 -->
          <div>
            <el-drawer class="drawer" :visible.sync="rightShow" direction="rtl" :before-close="handleClose">
              <template slot="title">
                <div class="drawer-title">用户设置</div>
              </template>
              <div class="drawer-info">
                <div class="cell">
                  <div class="left">
                    <div class="img">
                      <img :src="$getimgsrc(userinfor.avatar)" alt="">
                    </div>
                    <div class="info">
                      <div class="name" @click="infoChange(5)">{{ userinfor.realname }}</div>
                      <div class="tel">{{ userinfor.mobile }}</div>
                    </div>
                  </div>
                  <div class="right">
                    <div class="btn">{{ userinfor.manypersonrole_id }}</div>
                  </div>
                </div>
              </div>
              <div class="drawer-main">
                <div class="top">
                  <div class="cell-list">
                    <div class="list-l">
                      用户账号
                    </div>
                    <div class="list-r">
                      {{ userinfor.username }}
                    </div>
                  </div>
                  <div class="cell-list">
                    <div class="list-l">
                      手机号码
                    </div>
                    <div class="list-r" @click="infoChange(1)">
                      {{userinfor.mobile}}<span class="el-icon-arrow-right"></span>
                    </div>
                  </div>
                  <div class="cell-list" @click="infoChange(2)">
                    <div class="list-l">
                      性别
                    </div>
                    <div class="list-r">
                      {{sex}}<span class="el-icon-arrow-right"></span>
                    </div>
                  </div>
                  <div class="cell-list" @click="infoChange(3)">
                    <div class="list-l">
                      简介
                    </div>
                    <div class="list-r">
                      {{userinfor.intro}}<span class="el-icon-arrow-right"></span>
                    </div>
                  </div>
                </div>
                <div class="bottom">
                  <div class="cell-list">
                    <div class="list-l">
                      修改头像
                    </div>
                    <div class="list-r list-r-upd">
                      <el-upload class="avatar-uploader" :action="$imgFile"
                        :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                        点击修改
                      </el-upload>
                      <span class="el-icon-arrow-right"></span>
                    </div>
                  </div>
                  <div class="cell-list">
                    <div class="list-l">
                      修改密码
                    </div>
                    <div class="list-r" @click="infoChange(4)">
                      点击修改<span class="el-icon-arrow-right"></span>
                    </div>
                  </div>
                </div>
                <div class="btn">
                  <el-button type="danger" round @click="jiaoban">交班并退出</el-button>
                </div>
              </div>
            </el-drawer>
            <!-- 修改手机号&个性签名 -->
            <el-dialog :title="telSignature===1?'手机号码':'个性签名'" :visible.sync="dialogShow" width="30%"
              :before-close="diaLogClose">
              <el-input v-model="changeInput" :placeholder="placeholder"></el-input>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dialogShow = false">取 消</el-button>
                <el-button type="primary" @click="confirmChange(telSignature)">确 定</el-button>
              </span>
            </el-dialog>
             <!-- 修改用户名 -->
             <el-dialog title="用户名" :visible.sync="nameshow" width="30%"
              :before-close="diaLogClose">
              <el-input v-model="nameInput" :placeholder="placeholder"></el-input>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dialogShow = false">取 消</el-button>
                <el-button type="primary" @click="confirmChange(telSignature)">确 定</el-button>
              </span>
            </el-dialog>
            <!-- 修改性别 -->
            <el-dialog title="性别" :visible.sync="sexShow" width="30%" :before-close="diaLogClose">
              <el-radio-group v-model="sexChange">
                <el-radio label="2">保密</el-radio>
                <el-radio label="1">男</el-radio>
                <el-radio label="0">女</el-radio>
              </el-radio-group>
              <span slot="footer" class="dialog-footer">
                <el-button @click="sexShow = false">取 消</el-button>
                <el-button type="primary" @click="confirmChange(telSignature)">确 定</el-button>
              </span>
            </el-dialog>
            <!-- 修改密码 -->
            <el-dialog title="修改密码" :visible.sync="pwdShow" width="30%" :before-close="diaLogClose">
              <el-form :rules="rules" ref="form" label-position="right" :model="form" label-width="80px">
                <el-form-item label="原密码" prop="oldPwd">
                  <el-input type="password" v-model="form.oldPwd" placeholder="输入原密码"></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="newPwd">
                  <el-input type="password" v-model="form.newPwd" placeholder="8-20位数字或字母组合"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="newPwdTwo">
                  <el-input type="password" v-model="form.newPwdTwo" placeholder="再次输入新密码"></el-input>
                </el-form-item>
              </el-form>
              <span slot="footer" class="dialog-footer">
                <el-button @click="pwdShow = false">取 消</el-button>
                <el-button type="primary" @click="confirmChange(telSignature)">确 定</el-button>
              </span>
            </el-dialog>
          </div>
          <!-- end用户信息 -->
          <!-- 交班并退出 -->
          <el-drawer class="exit" :visible.sync="changeAndExitShow" direction="rtl" :show-close="false" :modal="false">
            <template slot="title">
              <div class="exit-title">
                <div class="el-icon-arrow-left" @click="changeAndExitShow=false"></div>
                <div class="title">交班</div>
              </div>
            </template>
            <div class="exit-main">
              <div class="main-title">
                收款金额
              </div>
              <div class="main-cell">
                <div class="left">
                  支付宝
                </div>
                <div class="right">
                  ¥{{ MoneyInfo.aplay_pay }}
                </div>
              </div>
              <div class="main-cell">
                <div class="left">
                  微信
                </div>
                <div class="right">
                  ¥{{ MoneyInfo.wepay_pay }}
                </div>
              </div>
              <div class="main-cell">
                <div class="left">
                  银行卡
                </div>
                <div class="right">
                  ¥{{ MoneyInfo.card_pay }}
                </div>
              </div>
              <div class="main-cell">
                <div class="left">
                  余额
                </div>
                <div class="right">
                  ¥{{ MoneyInfo.balan_pay }}
                </div>
              </div>
              <el-input class="main-input" placeholder="请填写现金实收金额" v-model.number="cashMoney">
                <template slot="prepend">现金</template>
              </el-input>
            </div>
            <div class="btn">
              <el-button class="l-btn" round @click="cashMoney=''">重输</el-button>
              <el-button type="danger" round @click="cashier">交班并退出</el-button>
            </div>
          </el-drawer>
          <!-- end交班并退出 -->
          <el-menu router class="nav-el-menu" background-color="#fff" default-active="1" menu-trigger="click"
            @select="select">
            <el-menu-item index="/work" style="background-color: white;">
              <template slot="title">
                <a href="javascript:;" class="nav-list">
                  <img v-if="activePath!=='/work'" src="../assets/images/index/gongzuotai.png" alt="" />
                  <img v-else src="../assets/images/index/gongzuotai_active.png" alt="" />
                  <div :class="activePath!=='/work'?'no-sel':'sel'">
                    工作台
                  </div>
                </a>
              </template>
            </el-menu-item>
            <el-menu-item index="/cash-reg" style="background-color: white;">
              <template slot="title">
                <a href="javascript:;" class="nav-list">
                  <img v-if="activePath!=='/cash-reg'" src="../assets/images/index/shouyin.png" alt="">
                  <img v-else src="../assets/images/index/shouyin_active.png" alt="">
                  <div :class="activePath!=='/cash-reg'?'no-sel':'sel'">
                    收银
                  </div>
                </a>
              </template>
            </el-menu-item>
            <el-menu-item index="/reservation" style="background-color: white;">
              <template slot="title">
                <a href="javascript:;" class="nav-list">
                  <img v-if="activePath!=='/reservation'" src="../assets/images/index/yuyue.png" alt="">
                  <img v-else src="../assets/images/index/yuyue_active.png" alt="">
                  <div :class="activePath!=='/reservation'?'no-sel':'sel'">
                    预约
                  </div>
                </a>
              </template>
            </el-menu-item>
            <el-menu-item index="/mianzheng" style="background-color: white;">
              <template slot="title">
                <a href="javascript:;" class="nav-list">
                  <img v-if="activePath!=='/mianzheng'" src="../assets/images/index/yuyue.png" alt="">
                  <img v-else src="../assets/images/index/yuyue_active.png" alt="">
                  <div :class="activePath!=='/mianzheng'?'no-sel':'sel'">
                    面诊
                  </div>
                </a>
              </template>
            </el-menu-item>
            <el-menu-item index="/vip" style="background-color: white;">
              <template slot="title">
                <a href="javascript:;" class="nav-list">
                  <img v-if="activePath!=='/vip'" src="../assets/images/index/vip.png" alt="">
                  <img v-else src="../assets/images/index/vip_active.png" alt="">
                  <div :class="activePath!=='/vip'?'no-sel':'sel'">
                    会员
                  </div>
                </a>
              </template>
            </el-menu-item>
            <el-menu-item index="/sellCard">
              <template slot="title">
                <a href="javascript:;" class="nav-list">
                  <img v-if="activePath!=='/sellCard'" src="../assets/images/index/shouka.png" alt="">
                  <img v-else src="../assets/images/index/shouka_active.png" alt="">
                  <div :class="activePath!=='/sellCard'?'no-sel':'sel'">
                    售卡
                  </div>
                </a>
              </template>
            </el-menu-item>
            <el-menu-item index="/bill" style="background-color: white;">
              <template slot="title">
                <a href="javascript:;" class="nav-list">
                  <img v-if="activePath!=='/bill'" src="../assets/images/index/zhangdan.png" alt="">
                  <img v-else src="../assets/images/index/zhangdan_active.png" alt="">
                  <div :class="activePath!=='/bill'?'no-sel':'sel'">
                    账单
                  </div>
                </a>
              </template>
            </el-menu-item>
            <!-- 预约排班 -->
             <!-- <el-menu-item index="/Schedule" style="background-color: white;">
              <template slot="title">
                <a href="javascript:;" class="nav-list">
                  <img v-if="activePath!=='/Schedule'" src="../assets/images/index/yuyuepaiban.png" alt="">
                  <img v-else src="../assets/images/index/yuyuepaiban_active.png" alt="">
                  <div :class="activePath!=='/Schedule'?'no-sel':'sel'">
                    业绩考核
                  </div>
                </a>
              </template>
            </el-menu-item> -->
            <el-menu-item index="/echars-data" style="background-color: white;">
              <template slot="title">
                <a href="javascript:;" class="nav-list">
                  <img v-if="activePath!=='/echars-data'" src="../assets/images/index/shuju.png" alt="">
                  <img v-else src="../assets/images/index/shuju_active.png" alt="">
                  <div :class="activePath!=='/echars-data'?'no-sel':'sel'">
                    数据
                  </div>
                </a>
              </template>
            </el-menu-item>
            <!-- <el-menu-item index="/partner" style="background-color: white;">
              <template slot="title">
                <a href="javascript:;" class="nav-list">
                  <img v-if="activePath!=='/partner'" src="../assets/images/index/hehuoren.png" alt="">
                  <img v-else src="../assets/images/index/hehuoren_active.png" alt="">
                  <div :class="activePath!=='/partner'?'no-sel':'sel'">
                    合伙人
                  </div>
                </a>
              </template>
            </el-menu-item> -->
            <el-menu-item index="/more" style="background-color: white;">
              <template slot="title">
                <a href="javascript:;" class="nav-list">
                  <img v-if="activePath!=='/more'" src="../assets/images/index/gengduo.png" alt="">
                  <img v-else src="../assets/images/index/gengduo_active.png" alt="">
                  <div :class="activePath!=='/more'?'no-sel':'sel'">
                    更多
                  </div>
                </a>
              </template>
            </el-menu-item>
            <el-menu-item index="/set" style="background-color: white;">
              <template slot="title">
                <a href="javascript:;" class="nav-list">
                  <img v-if="activePath!=='/set'" src="../assets/images/index/shezhi.png" alt="">
                  <img v-else src="../assets/images/index/shezhi_active.png" alt="">
                  <!-- <div :class="activePath!=='/set'?'no-sel':'sel'">
                    设置
                  </div> -->
                </a>
              </template>
            </el-menu-item>
          </el-menu>
        </div>
      </el-aside>
      <el-container>
        <el-main class="main">
          <div class="container-right">
            <router-view></router-view>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
  import validatorPhone from 'vue'
  import { mapState } from 'vuex'
  export default {
    data() {
      // 密码校验
      let validatePass = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('原密码不能为空'));
        } else {
          callback();
        }
      };
      let validatePass1 = (rule, value, callback) => {
        if (value === '') {
          return callback(new Error('密码不能为空'));
        } else {
          if (value.length < 6) {
            return callback(new Error('密码不能小于6位'));
          } else if (value.length > 20) {
            return callback(new Error('密码不能大于20位'));
          }
          if (this.form.newPwd !== '') {
            this.$refs.form.validateField('newPwd');
          }
          callback();
        }
      };
      let validatePass2 = (rule, value, callback) => {
        if (value === '') {
          return callback(new Error('请再次输入密码'));
        } else if (value !== this.form.newPwd) {
          return callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      return {
        activePath: this.$route.path,
        // 信息侧栏
        rightShow: false,
        //用户名弹框
        nameshow:false,
        nameInput:'',//暂存
        // 手机号&签名修改
        changeInput: '', // 暂存区
        dialogShow: false, // 弹窗是否显示
        telSignature: 0, // 弹窗赋值判断
        placeholder: '',
        tel: '15858858558', // 页面显示
        signature: '暂无签名', // 页面显示
        // 性别修改
        sexShow: false, // 弹窗是否显示
        sex: '保密', // 页面显示
        sexChange: '', // 暂存区
        // 修改头像
        imageUrl: '',
        // 修改密码
        pwdShow: false,
        form: {
          oldPwd: '',
          newPwd: '',
          newPwdTwo: '',
        },
        // 表单验证
        rules: {
          oldPwd: [{
            validator: validatePass,
            trigger: 'blur'
          }],
          newPwd: [{
            validator: validatePass1,
            trigger: 'blur'
          }],
          newPwdTwo: [{
            validator: validatePass2,
            trigger: 'blur'
          }],
        },
        //用户数据
        userinfor:{},
        // 交班并退出
        changeAndExitShow: false,
        cashMoney: '',//交班时输入的金额
        MoneyInfo:{},//交班金额

      };
    },
    computed:{
      ...mapState(['user'])
    },
    watch:{
      user(userinfor){
        this.userinfor = userinfor
        if(this.userinfor.gender == 0){
          this.sex = '女'
        }else if(this.userinfor.gender == 1){
          this.sex = '男'
        }else{
          this.sex = '保密'
        }
      }
    },
    mounted(){
      this.getuser()
    },
    created() {
      window.sessionStorage.setItem("activePath", this.$route.path);
      this.activePath = window.sessionStorage.getItem('activePath')
      // console.log('this.activePath', this.$route.path)
    },
    beforeUpdate() {
      // console.log('this.$route.path', this.activePath)
      this.activePath = this.$route.path === '/work' ? '/work' : this.$route.path
    },
    methods: {
      //获取账号基本信息
      getuser(){
        this.$http.post('api/store/user/userinfor').then(res =>{
          this.$store.commit('setUser',res.data.data)
        })
      },
      //交班退出
      select(activePath) {
        if (activePath === '/set') return
        window.sessionStorage.setItem("activePath", activePath);
        this.activePath = activePath;
      },
      // 关闭用户设置侧栏
      handleClose() {
        this.rightShow = false
        this.changeAndExitShow = false
      },
      infoChange(id) {
        this.telSignature = id
        if (id === 1) {
          this.dialogShow = true
          this.placeholder = '请输入新的手机号'
        } else if (id === 2) {
          this.sexShow = true
          this.sexChange = this.sex
        } else if (id === 3) {
          this.dialogShow = true
          this.placeholder = '请输入新的签名'
        } else if (id === 4) {
          this.pwdShow = true
        }else if(id === 5){
          this.nameshow = true
          this.placeholder = '请输入用户名'
        }
      },
      confirmChange(id) {
        if (id === 1) {
          if (this.validatorPhone(this.changeInput)) {
            this.userinfor.mobile = this.changeInput
            this.eduser()
            // this.tel = this.changeInput
            this.dialogShow = false
            this.changeInput = ''
          }
        } else if (id === 2) {
          this.userinfor.gender = this.sexChange
          this.eduser()
          this.sexShow = false
          this.sexChange = ''
        } else if (id === 3) {
          this.userinfor.intro = this.changeInput
          this.eduser()
          this.dialogShow = false
          this.changeInput = ''
        } else if (id === 4) {
          this.edpaw()
          this.pwdShow = false
        }else if(id === 5){
          this.userinfor.realname = this.nameInput
          this.eduser()
          this.nameshow = false
          this.nameInput = ''
        }
      },
      diaLogClose() {
        this.dialogShow = false
        this.sexShow = false
        this.pwdShow = false
      },
      //修改个人信息
      eduser(){
        this.$http.post('api/store/user/edituserinfor',{
          realname:this.userinfor.realname,
          avatar:this.userinfor.avatar,
          mobile:this.userinfor.mobile,
          gender:this.userinfor.gender,
          intro:this.userinfor.intro
        }).then(res =>{
          this.$message.success(res.data.msg)
          this.getuser()
        })
      },
      //修改密码
      edpaw(){
        this.$http.post('api/store/user/editpassword',{
          oldpwd:this.form.oldPwd,
          newpwd:this.form.newPwd,
          ercipwd:this.form.newPwdTwo
        }).then(res =>{
          this.$message.success(res.data.msg)
        })
      },
      // 修改头像
      handleAvatarSuccess(res, file) {
        // this.imageUrl = URL.createObjectURL(file.raw);
        this.userinfor.avatar = res.data.url
        this.eduser()
      },
      beforeAvatarUpload(file) {
        console.log(file.type);
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isJPG && isLt2M;
      },
      //获取交班金额信息
      jiaoban(){
        this.$http.post('api/store/storeset/outmoney').then(res =>{
          this.MoneyInfo = res.data.data
          this.cashMoney = res.data.data.cash_pay
          this.changeAndExitShow = true
        })
      },
      //交班前
      cashier(){
        this.$http.post('api/store/user/cashier').then(res =>{
          this.changeAndExit()
        })
      },
      // 交班并退出
      changeAndExit() {
        let loading = this.$loading({
          lock: false,
          text: '正在退出...',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.$http.post('api/store/login/logout',{
          money:this.cashMoney
        }).then(res =>{
          sessionStorage.clear()
          localStorage.clear()
          this.$router.push({
            path: '/'
          })
          loading.close()
        }).catch(err =>{
          loading.close()
        })
        
      }
    }
  }

</script>

<style lang="less" scoped>
  .index-container {

    .container-right {
      min-height: calc(100vh);
      border-left: 1px solid #e9e9e9;
      background-color: #f9f9f9;
    }

    .avatar-box {
      text-align: center;
      padding: 15px 0 0;

      img {
        width: 36px;
        height: 36px;
        border-radius: 50%;
      }

      div {
        font-size: 14px;
        color: #999;
      }
    }

    .nav-el-menu {
      border-right: none;

      .nav-list {
        display: block;
        text-align: center;
        margin: 20px 0;
      }

      img {
        width: 24px;
      }
    }
  }

  .no-sel {
    margin-top: 5px;
    color: #999;
  }

  .sel {
    margin-top: 5px;
    color: #45a0ff;
  }

  .el-menu-item {
    height: auto;
    line-height: 24px;
    padding: 0 !important;

    &:hover {
      background-color: #fff !important;
    }
  }

  /deep/ .main.el-main {
    padding: 0 0 !important;
  }

  // 用户设置
  /deep/ .drawer .el-drawer {
    min-width: 350px;
    background-color: #f9f9f9;

    .el-drawer__header {
      padding: 0;
      background-color: #409eff;
      color: #fff;
      margin-bottom: 0;
    }

    .drawer-title {
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      line-height: 50px;


    }

    .drawer-info {
      background-color: #409eff;
      color: #fff;
      padding: 50px 20px;
      margin-bottom: 20px;

      .cell {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;

        .left {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .img {
            width: 60px;
            height: 60px;
            margin-right: 15px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 100%;
            }
          }

          .info {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: 60px;
          }
        }

        .right {
          .btn {
            width: 90px;
            line-height: 30px;
            text-align: center;
            border: 1px solid #fff;
            border-radius: 5px;
          }
        }
      }
    }

    .drawer-main {

      .cell-list {
        display: flex;
        justify-content: space-between;
        padding: 12px;
        border-bottom: 1px solid #f2f2f2;

        &:last-child {
          border-bottom: none;
        }

        .list-r {
          color: #999;
        }

        .list-r-upd {
          display: flex;
          justify-content: flex-end;
          align-items: center;


        }
      }

      .top,
      .bottom {
        margin-bottom: 20px;
        padding: 0 10px;
        background-color: #fff;
        border-top: 1px solid #f2f2f2;
        border-bottom: 1px solid #f2f2f2;
      }


      .btn {
        text-align: center;

        .el-button {
          width: 50% !important;
        }
      }
    }
  }

  // 交班
  /deep/ .exit .el-drawer {
    min-width: 350px;
    background-color: #f9f9f9;

    .el-drawer__header {
      margin-bottom: 0;
      padding: 0;
    }

    .exit-title {
      position: relative;
      font-size: 16px;
      font-weight: bold;
      line-height: 50px;
      padding: 0 20px;
      border-bottom: 2px solid #f6f6f6;

      .el-icon-arrow-left {
        color: #409eff;
        font-size: 20px;
      }

      .title {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        color: #333;
      }
    }

    .exit-main {
      padding: 30px 40px;

      .main-title {
        color: #999;
        margin-bottom: 20px;
      }

      .main-cell {
        display: flex;
        justify-content: space-between;
        line-height: 40px;

        .left {
          color: #666;
          font-size: 12px;
        }

        .right {
          color: #666;
          font-weight: bold;
        }
      }

      .main-input {
        margin-top: 20px;
      }

    }

    .btn {
      display: flex;
      justify-content: space-around;
      text-align: center;

      .el-button {
        width: 40%;
      }
    }
  }

  /deep/ .l-btn.el-button:focus,
  .l-btn.el-button:hover {
    color: #666;
    border-color: #dcdfe6;
    background-color: #ffffff;
  }

  /deep/ .main-input.el-input {
    .el-input-group__prepend {
      border-right: 0 !important;
      background-color: #fff;
      color: #333;
      font-size: 12px;
    }

    .el-input__inner {
      border-left: 0 !important;
      text-align: right;
    }

    .el-input.is-active .el-input__inner,
    .el-input__inner:focus {
      border-color: #dcdfe6;
    }
  }

  /deep/ .avatar-uploader {
    .el-upload--picture-card:hover,
    .el-upload:focus {
      color: #999 !important;
    }
  }

  /deep/ .el-dialog {
    min-width: 430px !important;
  }

</style>
