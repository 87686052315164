<template>
  <el-row>
    <el-container style="">
      <el-header class="header">
        <div class="search">
          <el-input placeholder="请输入会员昵称、姓名或手机" prefix-icon="el-icon-search" v-model="searchVal"
            @keyup.enter.native="searchChange">
          </el-input>
          <el-button type="primary" @click="searchChange">搜索</el-button>
        </div>
        <router-link to="/add-vip" class="addVip">
          <i class="el-icon-plus"></i> 添加会员</router-link>
      </el-header>
      <el-container v-loading="loading">
        <el-container>
          <el-main class="main">
            <!-- <div v-if="true" v-infinite-scroll="load"> -->
            <div v-if="true">
              <div class="ul-box">
                <div class="li">
                  <div class="time">姓名</div>
                </div>
                <div class="li">
                  <div class="time">性别</div>
                </div>
                <div class="li">
                  <div class="time">生日</div>
                </div>
                <div class="li">
                  <div class="time">手机号码</div>
                </div>
                <div class="li">
                  <div class="time">注册日期</div>
                </div>
                <!-- <div class="li">
                  <div class="time">上次消费</div>
                </div> -->
                <!-- <div class="li">
                  <div class="time">消费次数</div>
                </div> -->
                <div class="li">
                  <div class="time">会员等级</div>
                </div>
                <div class="li">
                  <div class="time">负责顾问</div>
                </div>
              </div>
              <div class="list-box" v-if="VipList.data.length > 0">
                <div class="list" v-for="(item,i) in VipList.data " :key="i" @click="jumpVipInfo(item)">
                  <div class="li">
                    <div class="time">
                      <img :src="$getimgsrc(item.avatar)" alt="" style="border-radius:50%;">
                      {{ item.realname }}
                    </div>
                  </div>
                  <div class="li">
                    <div class="time">{{  item.gender}}</div>
                  </div>
                  <div class="li">
                    <div class="time">{{  item.birthday}}</div>
                  </div>
                  <div class="li">
                    <div class="time">{{ item.mobile }}</div>
                  </div>
                  <div class="li">
                    <div class="time">{{  item.createtime | timeform(false)}}</div>
                  </div>
                  <!-- <div class="li">
                    <div class="time">2021/04/05</div>
                  </div> -->
                  <!-- <div class="li">
                    <div class="time">10</div>
                  </div> -->
                  <div class="li">
                    <div class="time btn">{{  item.dengji}}</div>
                  </div>
                  <div class="li">
                    <div class="time">{{  item.brief}}</div>
                  </div>
                </div>
              </div>
              <div v-else class="empty">
                暂无数据
              </div>
            </div>
            <div v-else class="box">
              <img src="@/assets/images/null.png" alt="">
              <div>
                <el-button type="primary" icon="el-icon-plus" @click="jumpAddAbout">添加预约</el-button>
              </div>
            </div>
          </el-main>
          <el-footer style="text-align: center;margin-top: 20px;">
              <el-pagination
              background
              layout="prev, pager, next, jumper"
              :page-size="10"
              :total="VipList.total"
              @current-change="CurrentChange">
              </el-pagination>
          </el-footer>
        </el-container>
      </el-container>
    </el-container>
  </el-row>

</template>

<script>
import { mapMutations } from "vuex";
  export default {
    data() {
      return {
        loading:false,
        searchVal: '',
        tabsChange: '2',
        timePicker: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate(),
        VipList:[],
        page:1
      }
    },
    created() {
      this.getVipLsit()
    },

    mounted () {
      this.getVipLabel()
    },
    methods: {
    ...mapMutations(["setLabels"]),
      //会员列表
      getVipLsit(){
        this.loading = true
        this.$http.post('api/store/storeuser/userlist',{
          page:this.page,
          keyword:this.searchVal
        }).then(res =>{
          // this.VipList = [...this.VipList,...res.data.data]
          this.VipList = res.data.data
          this.loading = false
        })
      },

      //分页
      CurrentChange(e){
          this.page = e
          this.getVipLsit()
      },
      // 获取标签
      getVipLabel() {
        this.$http.post('api/store/storeuser/userlabel').then(res => { 
          this.setLabels(res.data.data)
        })
      },
      //下拉加载更多
      // load(){
      //   if(this.VipList.length < (this.page+1)*10)return
      //   this.page++
      //   this.getVipLsit()
      // },
      //会员详情
      jumpVipInfo(item) {
        this.$store.commit('setVip',item)
        this.$router.push({
          name: 'VipInfo'
        })
        window.sessionStorage.setItem('returnPage', '/vip')

      },
      // 搜索
      searchChange() {
        this.page = 1
        this.VipList = []
        this.getVipLsit()
      },
    }
  };

</script>
<style lang="less" scoped>
  // 头部
  .header {
    position: relative;
    background-color: #fff;
    color: #333;
    line-height: 50px;
    border-bottom: 1px solid #e9e9e9;

    .search {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50%;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .el-button--primary {
        height: 35px;
        margin-left: -6px;
        z-index: 1;
        font-size: 12px;
        border-radius: 0 5px 5px 0;

      }

      .el-button--primary:focus,
      .el-button--primary:hover,
      .el-button:active,
      .el-button:focus,
      .el-button:hover {
        color: #fff;
        border-color: #409eff;
        background-color: #409eff;
      }

      /deep/ .el-input__inner {
        height: 35px;
        border: 1px solid #dcdfe6;
        border-right: 0;
        border-radius: 5px 0 0 5px;

        &:focus,
        &:hover {
          border-color: #dcdfe6;
        }
      }
    }

    .addVip {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      color: #409eff;
      font-size: 12px;

      .el-icon-plus {
        font-weight: bold;
      }
    }
  }

  .main {
    font-size: 12px;

    .ul-box,
    .list {
      color: #999;
      display: flex;
      justify-content: space-around;
      border-bottom: 1px solid #e9e9e9;
      background-color: #f9f9f9;
      padding: 0 20px;
      font-size: 10px;

      .li {
        width: (100/8%);
        height: 40px;
        line-height: 40px;
        text-align: center;

        .time {
          text-align: center;

          img {
            width: 40%;
            max-width: 35px;
            max-height: 35px;
            vertical-align: middle;
            margin-right: 5px;
          }

        }

        .btn {
          display: inline;
          border-radius: 5px;
          padding: 5px;
          color: #fff;
          background-color: #ff9900;
        }
      }
    }

    .list-box {
      padding: 10px;
      box-sizing: border-box;
      background-color: #f9f9f9;
      // height: calc(100vh - 80px);

      .list {
        display: flex;
        // justify-content: flex-start;
        justify-content: space-between;
        align-items: center;
        border-left: 3px solid #409eff;
        color: #666;
        font-size: 12px;
        background-color: #fff;
        margin-bottom: 10px;
        padding: 5px 8px;
        img{
          height: 40px;
          width: 40px;
        }
      }
    }

    .empty {
      text-align: center;
      padding: 15px 0;
      font-size: 14px;
      color: #999;
      background-color: #fff;
      box-sizing: border-box;
    }

    .box {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;

      img {
        width: 240px;
        margin-bottom: 20px;
      }
    }
  }


  /deep/.header.el-header {
    height: 80px !important;
  }

  .menu.el-menu {
    border-right: none !important;
    background-color: #f9f9f9;
  }

  .el-menu-item:focus,
  .el-menu-item:hover {
    background-color: #f9f9f9;

  }

  .main.el-main {
    padding: 0 0px !important;
  }

</style>
