<template>

  <el-container>
    <el-main class="left-main">
      <el-header>
        <div class="el-icon-arrow-left" @click="returnOn">返回</div>
        <div class="title-body">
          <div class="img">
            <img src="@/assets/images/bills/integral.png" alt="">
          </div>
          <div class="text">积分账单</div>
        </div>
        <div class="user-box">
          <div class="user-card">
            <div class="user">
              <div class="avatar">
                <img src="@/assets/images/avatar.png" alt="">
              </div>
              <div class="info">
                <div class="top">
                  <div class="name-tel" v-if="Info.user">
                    <div class="name">
                      {{ Info.user.realname }}
                      <div class="btn">
                        {{ Info.user.dengji }}
                      </div>
                    </div>
                    <div class="tel">{{ Info.user.mobile }}</div>
                  </div>

                </div>
              </div>
            </div>
            <!-- <div class="rem-bind" @click="jumpVipInfo">查看会员信息<span class="el-icon-arrow-right"></span></div> -->
          </div>
        </div>
      </el-header>
      <div class="body">
        <div class="body-title el-icon-document">基本信息</div>
        <div class="cell">
          <div class="cell-list">
            <div class="cl-left">账单编号</div>
            <div class="cl-right">{{ Info.ordernum }}</div>
          </div>
          <div class="cell-list">
            <div class="cl-left">结算时间</div>
            <div class="cl-right">{{ Info.paytime | timeform(true) }}</div>
          </div>
          <div class="cell-list">
            <div class="cl-left">收银人员</div>
            <div class="cl-right">{{ Info.manystoreperson_id }}</div>
          </div>
          <!-- <div class="cell-list">
            <div class="cl-left">收银机号</div>
            <div class="cl-right">A123</div>
          </div> -->
          <div class="cell-list">
            <div class="cl-left">消费项目</div>
            <div class="cl-right">共{{ Info.sum }}项</div>
          </div>
          <div class="cell-list" v-if="Info.status == '待取货'">
            <div class="cl-left"></div>
            <div class="cl-right">
              <el-button type="primary" @click="quhuo">确认取货</el-button>
            </div>
          </div>
        </div>
      </div>
    </el-main>
    <el-main class="right-main">
      <el-header>
        <div class="btn">
          <div :class="tabsChange==='1'?'btn1 left':'left'" @click="tabsChange='1'">消费项目</div>
          <div :class="tabsChange==='2'?'btn1 right':'right'" @click="tabsChange='2'">结账详情</div>
        </div>
      </el-header>
      <el-container class="right-main-container">
        <el-main>
          <template v-if="tabsChange==='1'">
            <div class="list" v-for="(item,i) in shopList" :key="i">
              <div class="left">
                <div class="name">{{ item.scshopname }}</div>
                <div class="time">{{ item.danwei }}</div>
              </div>
              <div class="center">
                购买数量：{{ item.scnum }}
              </div>
              <div class="right">
                <b>{{ item.unit_price }}</b>积分
              </div>
            </div>
          </template>
          <template v-else>
            <div class="end-pay-detail">
              <div class="cell">
                <div class="left">积分支付</div>
                <div class="right"><b>{{ ordInfo.total_price }}</b>积分</div>
              </div>
            </div>
          </template>
        </el-main>
        <el-footer>
          <div class="foot-box">
            <div class="left">
              消费合计：<b>{{ ordInfo.total_price }}</b>积分
            </div>
            <div class="right" @click="handlePrint()">补打小票</div>
          </div>
        </el-footer>
      </el-container>
    </el-main>
  </el-container>
</template>

<script>
  export default {
    data() {
      return {
        currentPage: '',
        routerPage: '',
        tabsChange: '1',
        Info:{},
        ordInfo:{},
        shopList:[],//消费项目
      }
    },
    mounted() {
      this.Info = JSON.parse(this.$route.query.item)
      this.getOrdInfo()
      this.currentPage = this.$route.path
      this.routerPage = window.sessionStorage.getItem('reservationPath')
    },
    // destroyed() {
    //   window.sessionStorage.setItem('reservationPath', '/reservation')
    // },
    methods: {
       //取货
       quhuo(){
        // this.$bus.$emit('eventlist','牛逼')
        this.$http.post('api/store/census/querenquhuo',{
          id:this.Info.id,
          type:this.Info.sout
        }).then(res =>{
          this.Info.status = ''
          this.$bus.$emit('eventlist')
          this.$message.success(res.data.msg)
          setTimeout(() => {
            this.$router.go(-1)
          }, 1000);
        })
      },
      //获取卡卷商品详情
      getOrdInfo(){
        this.$http.post('api/store/census/orderdetail',{
          id:this.Info.id,
          type:this.Info.sout
        }).then(res =>{
          this.ordInfo = res.data.data
          this.shopList = res.data.data.shops
        })
      },
      handlePrint() {
        this.$http.post('api/printer/scoreorderdayin',{
            id: this.Info.id
          }).then(res =>{
            console.log(res);
          })
      },
      // 返回上级
      returnOn() {
        this.$router.push({
          path: this.routerPage
        })
        // this.$router.go(-1)
      },
      jumpVipInfo() {
        window.sessionStorage.setItem('returnPage', this.currentPage)

        this.$router.push({
          name: 'VipInfo',
        })
      },

    }
  };

</script>
<style lang="less" scoped>
  /deep/ .el-container {
    height: calc(100vh);
  }

  /deep/ .left-main.el-main {
    width: 40%;
    min-width: 350px;
    height: calc(100vh);
    padding: 0 !important;

    .el-header {
      position: relative;
      height: auto !important;
      background-color: #1891ff;
      color: #fff;
      padding: 0 !important;

      .el-icon-arrow-left {
        line-height: 40px;
        padding: 0 15px;
      }

      .title-body {
        text-align: center;
        padding: 60px 0 130px;

        .img {
          img {
            width: 120px;
          }
        }

        .text {
          font-weight: bold;
          font-size: 18px;
          margin-top: 15px;
        }
      }

      .user-box {
        position: absolute;
        bottom: -25px;
        width: 100%;
        box-sizing: border-box;

        .user-card {
          margin: 0 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
          background-color: #fff;
          border-radius: 5px;
          color: #666;
          box-shadow: 0 0 4px #ccc;

          .user {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .avatar {
              width: 35px;
              height: 35px;
              margin-right: 10px;
              margin-top: 2px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 100%;
              }
            }

            .info {
              .top {
                display: flex;
                justify-content: flex-start;

                .name-tel {
                  margin-right: 20px;
                  line-height: 20px;

                  .name {
                    font-weight: bold;

                    .btn {
                      display: inline-block;
                      font-size: 12px;
                      background-color: #ff9900;
                      line-height: 24px;
                      width: 20%;
                      min-width: 60px;
                      text-align: center;
                      border-radius: 5px;
                      color: #fff;
                      margin-left: 20px;
                    }
                  }

                  .tel {
                    font-size: 12px;
                  }
                }


              }
            }
          }

          .rem-bind {
            font-size: 12px;
            color: #999;
          }
        }
      }

    }

    .body {
      margin-top: 35px;

      .body-title {
        padding: 25px 20px;
        font-weight: bold;
        color: #666;

        &::before {
          color: #ff9900;
          margin-right: 5px;
        }
      }

      .cell {
        .cell-list {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 25px;

          .cl-left {
            color: #999;
          }

        }
      }
    }
  }

  /deep/ .right-main.el-main {
    width: 60%;
    height: calc(100vh);
    padding: 0 !important;

    .el-header {
      display: flex;
      justify-content: center;
      align-items: center;

      .btn {

        .left,
        .right {
          display: inline-block;
          width: 100px;
          height: 30px;
          border: 1px solid #409eff;
          text-align: center;
          line-height: 30px;
          color: #409eff;
          cursor: pointer;
        }

        .left {
          border-right: 0;
          border-radius: 5px 0 0 5px;
        }

        .right {
          border-radius: 0 5px 5px 0;
        }

        .btn1 {
          border: 1px solid #409eff;
          text-align: center;
          line-height: 30px;
          color: #fff;
          background-color: #409eff;
        }

      }
    }

    .right-main-container {
      height: calc(100vh - 60px);
      background-color: #f5f5f5;

      .list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        border-radius: 5px;
        padding: 10px;
        margin-top: 15px;

        &:last-child {
          margin-bottom: 15px;
        }


        .left {
          width: 40%;
          min-width: 200px;


          .name {
            margin-bottom: 5px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .time {
            font-size: 12px;
            color: #999;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

          }
        }

        .center {
          color: #666;
        }

        .right {
          color: #f56c6c;
        }
      }

      .end-pay-detail {
        background-color: #fff;
        margin-top: 15px;
        padding: 15px;
        border: 1px solid #f2f2f2;

        .cell {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #666;
        }
      }

    }

    .el-footer {
      padding: 15px;
      background-color: #fff;
      border: 1px solid #f2f2f2;
      height: auto;

      .foot-box {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
          b {
            color: #f56c6c;
          }
        }

        .right {
          border: 1px solid #409eff;
          color: #409eff;
          width: 120px;
          text-align: center;
          line-height: 40px;
          border-radius: 30px;
        }
      }
    }

  }

</style>
