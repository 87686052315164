<template>
  <el-row>
    <el-container style="height:calc(93.5vh);">
      <el-header class="header">
        <div class="header-top">
          <el-input class="searchInput" placeholder="请输入会员姓名、备注名、编号或手机" prefix-icon="el-icon-search" v-model="searchVal"
            @keyup.enter.native="searchChange">
          </el-input>
         
        </div>
        <div class="header-btm">
          <div class="tabs cursor">
            <div :class="tabsActive===0?'tab tab1':'tab'" @click="tabsActiveClick(0)">全部账单</div>
            <div :class="tabsActive===2?'tab tab1':'tab'" @click="tabsActiveClick(2)">面诊账单</div>
            <div :class="tabsActive===1?'tab tab1':'tab'" @click="tabsActiveClick(1)">卡券/服务账单</div>
            <div :class="tabsActive===3?'tab tab1':'tab'" @click="tabsActiveClick(3)">充值账单</div>
            <div :class="tabsActive===4?'tab tab1':'tab'" @click="tabsActiveClick(4)">积分账单</div>
            <div :class="tabsActive===5?'tab tab1':'tab'" @click="tabsActiveClick(5)">预约账单</div>
            <div :class="tabsActive===6?'tab tab1':'tab'" @click="tabsActiveClick(6)">权益卡账单</div>
          </div>
          <div class="data">
            <span class="el-icon-arrow-left"></span>
            <el-date-picker class="cursor" 
              v-model="timePicker" 
              :editable="false" 
              type="date" 
              placeholder="选择日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              align="center" prefix-icon="none" clear-icon="none">
            </el-date-picker>
            <span class="el-icon-arrow-right"></span>

          </div>
        </div>
      </el-header>
      <el-container style="height:calc(93.5vh);">
        <el-container>
          <el-main class="main">
            <div class="content">
              <el-row :gutter="10">
                <el-col class="content-box" :span="rowCol" v-for="(item,i) in list" :key="i">
                  <div class="content-list">
                    <a>
                      <el-row class="title">
                        <el-col :span="13" class="img">
                          <img :src="$getimgsrc(item.user.avatar)" alt="" style="border-radius: 50%;">
                          <div>
                            {{ item.user.realname }}
                            <div>{{ item.user.mobile }}</div>
                          </div>
                        </el-col>
                        <el-col :span="11" class="time">
                          <span class="left" @click="handlePrint(item.sout, item.id)">补打小票</span>
                          <span class="right" @click="jumpBillDetail(item.sout,item)">账单详情</span>
                        </el-col>
                      </el-row>
                      <el-row class="list">
                        <el-col :span="8" lass="list-l">
                          账单编号
                        </el-col>
                        <el-col :span="16" class="list-r">
                          <!-- <span v-if="i===1" class="list-r-left">退货账单</span> -->
                          {{ item.ordernum }}
                        </el-col>
                      </el-row>
                      <el-row class="list">
                        <el-col :span="8" lass="list-l">
                          结账时间
                        </el-col>
                        <el-col :span="16" class="list-r">
                          {{ item.paytime | timeform(true) }}
                        </el-col>
                      </el-row>
                      <el-row class="list">
                        <el-col :span="8" lass="list-l">
                          收银人员
                        </el-col>
                        <el-col :span="16" class="list-r">
                         {{ item.manystoreperson_id }}
                        </el-col>
                      </el-row>
                      <el-row class="list">
                        <el-col :span="8" lass="list-l">
                          订单状态
                        </el-col>
                        <el-col :span="16" class="list-r">
                         {{ item.status }}
                        </el-col>
                      </el-row>
                      <el-row class="list1">
                        <el-col :span="8" class="list-l">
                          消费项目
                        </el-col>
                        <el-col :span="16" class="list-r">
                          共{{item.sum}}项
                        </el-col>
                      </el-row>
                    </a>
                    <el-row class="list2">
                      <el-col :span="12" class="list-l">
                        实付金额
                      </el-col>
                      <el-col :span="12" class="list-r">
                        ¥ {{ item.real_price }}
                      </el-col>
                    </el-row>
                  </div>
                </el-col>
              </el-row>
              <empty title="暂无账单" v-if="list.length == 0"></empty>
            </div>
          </el-main>
        </el-container>
      </el-container>
      <el-footer class="footer">

      </el-footer>
    </el-container>
  </el-row>
</template>

<script>
import empty from '../../module/empty.vue'
  export default {
  components: { empty },
    data() {
      return {
        innerWidth: document.documentElement.clientWidth,
        rowCol: 6,
        searchVal: '',
        tabsActive: 0,
        timePicker: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate(),
        list:[]
      }
    },
    watch: {
      innerWidth(val) {
        if (val >= 1440) {
          this.rowCol = 6
        } else if (val >= 1150) {
          this.rowCol = 8
        } else if (val >= 830) {
          this.rowCol = 12
        } else if (val <= 830) {
          this.rowCol = 12
        }
      },
      timePicker(){
        this.getList()
      }
    },
    created () {
      this.getList()
      
    },
    mounted() {
      var that = this;
      if (that.innerWidth >= 1440) {
        this.rowCol = 6
      } else if (that.innerWidth >= 1150) {
        this.rowCol = 8
      } else if (that.innerWidth >= 830) {
        this.rowCol = 12
      } else if (that.innerWidth <= 830) {
        this.rowCol = 12
      }
      window.onresize = () => {
        return (() => {
          window.fullWidth = document.documentElement.clientWidth;
          that.innerWidth = window.fullWidth; // 宽
        })()
      };
    },
    methods: {
      tabsActiveClick(i){
        this.tabsActive = i
        this.getList()
      },
      //获取账单  预约账单
      getList(){
        this.$http.post('api/store/census/checklist',{
          type:this.tabsActive,
          time:this.timePicker,
          keyword:this.searchVal
        }).then(res =>{
          // console.log('订单列表');
          this.list = res.data.data
        })
      },
      jumpBillDetail(i,item) {
        // if (i === 0) {
        //   this.$router.push({
        //     path: '/goods-bills',//退款
        //     query:{
        //       item:JSON.stringify(item)
        //     }
        //   })
        // } else
        this.$bus.$on('eventlist',(e)=>{
          this.getList()
          this.$bus.$off('eventlist');
        })
        window.sessionStorage.setItem('reservationPath', '/bill')
         if (i === 1) {
          this.$router.push({
            path: '/card-bills',//售卡
            query:{
              item:JSON.stringify(item)
            }
          })
        } else if (i === 4) {
          this.$router.push({
            path: '/integral-bills',//积分
            query:{
              item:JSON.stringify(item)
            }
          })
        } else if (i === 3) {
          this.$router.push({
            path: '/top-up-bills',//充值
            query:{
              item:JSON.stringify(item)
            }
          })
        } else if (i === 5) {
          this.$router.push({
            path: '/reservation-bills',//预约
            query:{
              item:JSON.stringify(item)
            }
          })
        }  else if (i === 6) {
          this.$router.push({
            path: '/equity-bills',//权益卡
            query:{
              item:JSON.stringify(item)
            }
          })
        } else {
          this.$router.push({
            path: '/service-bills',//面诊
            query:{
              item:JSON.stringify(item)
            }
          })
        }
        
      },

      // 打印
      handlePrint (i, id) {
        if (i === 1) {
          // 卡券/服务
          this.$http.post('api/printer/shoporderdayin',{
            id: id
          }).then(res =>{
            console.log(res);
          })
        } else if (i === 2) {
          // 面诊
          this.$http.post('api/printer/faceorderdayin',{
            id: id
          }).then(res =>{
            console.log(res);
          })
        } else if (i === 4) {
          // 积分
          this.$http.post('api/printer/scoreorderdayin',{
            id: id
          }).then(res =>{
            console.log(res);
          })
        } else if (i === 5) {
          // 预约
          this.$http.post('api/printer/yueorderdayin',{
            id: id
          }).then(res =>{
            console.log(res);
          })
        }
      },

      // 搜索
      searchChange() {
        this.getList()
        // if (this.searchVal.trim()) {
        //   console.log(this.searchVal.trim());
        // } else {
        //   this.$message.error('搜索内容不能为空')
        // }
      },
    }
  }

</script>

<style lang="less" scoped>
  .header {
    text-align: center;
    background-color: #fff;
    border-bottom: 1px solid #e9e9e9;

    .header-top {
      .searchInput {
        width: 40%;
        height: 30px;
        margin: 10px 0;

        /deep/ .el-input__inner {
          height: 35px;
          border-radius: 35px;

          &:focus {
            border-color: #dcdfe6;
          }
        }

        /deep/ .el-input__prefix {
          .el-input__icon {
            line-height: 36px;
          }
        }
      }
    }

    .header-btm {
      display: flex;
      justify-content: space-between;

      .tabs {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        min-width: 480px;

        .tab {
          box-sizing: border-box;
          padding: 0 20px 12px;
          border-bottom: 2px solid transparent;

        }

        .tab1 {
          box-sizing: border-box;
          color: #409eff;
          border-bottom: 2px solid #409eff;
        }
      }

      .data {
        margin-top: 5px;

        .el-icon-arrow-left,
        .el-icon-arrow-right {
          font-weight: bold;
          color: #666;
        }

        /deep/ .el-date-editor.el-input {
          width: 120px;

          .el-input__inner {
            border: 0;
            background-color: transparent;
            text-align: center;
            color: #409eff;
            padding: 0;
          }
        }
      }
    }
  }

  .main {

    .content {
      padding: 10px;

      .content-box {
        margin: 5px 0;

        .content-list {
          background-color: #fff;
          box-shadow: 0 0 4px #ccc;
          border-radius: 5px;

          img {
            width: 100%;
            max-width: 40px;
            min-width: 30px;
            vertical-align: middle;
            margin-right: 10px;

          }

          .img {
            display: flex;
            justify-content: flex-start;
          }

          .title {
            padding: 10px;

            // .name {}

            .time {

              text-align: right;
              line-height: 40px;

              span {
                border: 1px solid #409eff;
                padding: 5px 5px;
                font-size: 12px;
                border-radius: 50px;
              }

              .left {
                color: #409eff;
                margin-right: 5px;
              }

              .right {
                color: #fff;
                background-color: #409eff;
              }
            }
          }

          .list {
            padding: 10px;
            border-bottom: 1px solid #f2f2f2;
          }

          .list1 {
            padding: 10px;
          }

          .list2 {
            padding: 10px;
            background-color: #f2f2f2;
          }

          .list .list-r,
          .list1 .list-r,
          .list2 .list-r {
            text-align: right;
            color: #999;
          }

          .list2 .list-r {
            color: #f56c6c;
            font-weight: bold;
          }
        }

        .list-r-left {
          border: 1px solid #f2a44e;
          color: #f2a44e;
          padding: 3px 5px;
          font-size: 12px;
          margin-right: 5px;
        }
      }
    }
  }

  /deep/ .el-header {
    height: 90px !important;
  }

</style>
