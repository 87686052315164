<template>

  <el-container style="height:calc(100vh); background-color:#f9f9f9;">
    <el-header class="header">
      <span class="header-left el-icon-arrow-left" @click="returnOn"></span>
      售卡列表
    </el-header>
    <el-container>
      <el-main style="height:calc(100vh);width:75%;border-right:1px solid #e9e9e9;">
        <el-row class="content" :gutter="10">

          <el-checkbox-group v-model="checkList" @change="checkChang">
          <el-col :span="rowCol" v-for="(item,i) in callList" :key="i">
            <div class="card cursor">
              <div class="card-top">
                <div class="card-title">
                  <div class="name">{{ item.name}}</div>
                  <div class="price"><b>¥{{  item.original_price}}</b></div>
                </div>
                <div class="card-info">
                  项目：{{ item.server_ids }}
                </div>
                <div class="card-time">
                  <div class="time">
                    有效期限：{{ item.valid }}个月
                  </div>
                  <div class="num">
                    {{item.num}}次
                  </div>
                </div>
              </div>
              <div class="card-btm">
                <div class="card-type">
                  <!-- 限时卡 -->
                  <el-checkbox :label="item" :key="item.id"> <br/></el-checkbox>
                </div>
                <div class="stepper">
                  <el-input-number v-model="item.addnum" :precision="0" :min="1" :max="99" @change="handleChange">
                  </el-input-number>
                </div>
              </div>
            </div>
          </el-col>
        </el-checkbox-group>
          
        </el-row>
      </el-main>
      <el-main style="height:calc(100vh);width:25%;min-width:350px; background-color:#fff;padding:0 !important;">
        <el-container style="height:calc(94vh);">
          <el-header class="header-right">
            <div class="about-info">
              <div class="info-title">
                预约信息
              </div>
              <div class="user-info">
                <div class="tel">
                  <div class="left">
                    手机
                  </div>
                  <div class="right">
                    <el-input v-model="vipuser.mobile"  placeholder="请输入手机号码"></el-input>
                  </div>
                </div>
                <div class="name">
                  <div class="left">
                    姓名
                  </div>
                  <div class="right">
                    <el-input v-model="vipuser.realname"  placeholder="请输入姓名"></el-input>
                  </div>
                </div>
              </div>
              <div class="info-title">
                已选项目
              </div>
            </div>
          </el-header>
          <el-main>
            <div class="order">
              <div class="order-body">
                <div class="goods-card cursor" v-for="(item ,i) in checkList" :key="i">
                  <div class="card-t">
                    <div class="t-name">{{  item.name}}</div>
                    <div class="stepper">
                            <el-input-number v-model="item.num" :precision="0" :min="1">
                            </el-input-number>
                          </div>
                    <!-- <div>
                      <span class="t-del iconfont icon-cha" @click="delOrderGoods"></span>
                    </div> -->
                  </div>
                  <div class="card-c">
                      <template>
                        <div  class="c-time">有效期限：{{item.valid}}个月</div>
                      </template>
                      <div class="c-price">¥{{ item.original_price }}</div>
                    </div>
                    <div class="card-c">
                      <template>
                        <div  class="c-time">到期时间：</div>
                      </template>
                      <div class="c-date">
                        <el-date-picker
                          v-model="item.date"
                          type="date"
                          placeholder="到期时间"
                          format="yyyy-MM-dd"
                          value-format="yyyy-MM-dd">
                        </el-date-picker>
                      </div>
                    </div>
                    <template>
                      <div  class="card-b">
                        <div class="b-name">
                          <div class="stepper">
                            <el-input-number v-model="item.addnum" :precision="0" :min="1"  @change="numchang">
                            </el-input-number>
                          </div>
                        </div>
                        <el-input placeholder="填写姓名" v-model="item.childname">
                          <i slot="prefix" class="el-input__icon iconfont icon-bi"></i>
                        </el-input>
                      </div>
                    </template>
                </div>
              </div>
            </div>
          </el-main>
          <el-footer>
            <div class="order-bottom">
              <div class="btm-t">
                <div class="t-left">
                  共<span>{{ checkList.length }}</span>项 合计：<b>¥{{  allMoney}}</b>
                </div>
              </div>
              <div class="btm-c">
                <el-input placeholder="填写备注" v-model="remark">
                  <i slot="prefix" class="el-input__icon iconfont icon-bi"></i>
                </el-input>
              </div>
              <div class="btm-b">
                <el-button type="danger" round @click="ordpay">收款</el-button>
              </div>
            </div>
          </el-footer>
        </el-container>
      </el-main>
    </el-container>

  </el-container>
</template>

<script>
import { mapState } from 'vuex'
  export default {
    data() {
      return {
        innerWidth: document.documentElement.clientWidth,
        rowCol: 6,
        stepperNum: 1,
        telInput: '',
        nameInput: '',
        remark: '',
        callList:[],
        checkList:[],//选中列表
        allMoney:0,//价格
      }
    },
    computed: {
      ...mapState(['vipuser'])
    },
    watch: {
      innerWidth(val) {
        if (val >= 1440) {
          this.rowCol = 6
        } else if (val >= 1180) {
          this.rowCol = 8
        } else if (val >= 900) {
          this.rowCol = 12
        } else if (val <= 900) {
          this.rowCol = 24
        }
      }
    },
    mounted() {
      var that = this;
      if (that.innerWidth >= 1440) {
        that.rowCol = 6
      } else if (that.innerWidth >= 1180) {
        that.rowCol = 8
      } else if (that.innerWidth >= 900) {
        that.rowCol = 12
      } else if (that.innerWidth <= 900) {
        that.rowCol = 24
      }
      window.onresize = () => {
        return (() => {
          window.fullWidth = document.documentElement.clientWidth;
          that.innerWidth = window.fullWidth; // 宽
        })()
      };
      this.getCalllist()
    },
    methods: {
      //提交订单
      ordpay(){
        var arr =[]
        this.checkList.forEach(item => {
          arr.push(item.childname)
        }) 
        if (arr.indexOf(undefined) != -1) {
          this.$message.warning('姓名不能为空')
          return
        }
        this.$http.post('api/store/order/createorder',{
          user_id:this.vipuser.id,
          card:this.checkList,
          money:this.allMoney,
          remark:this.remark,
          type:1,
        }).then(res =>{
          this.confirmChangeGoods(res.data.data)
        })
      },
      //复选框勾选计算价格
      checkChang(e){
        this.allMoney = 0
        if(e.length != 0){
          for(let k in e){
            this.allMoney += Number(e[k].addnum)*Number(e[k].original_price)
          }
        }else{
          this.allMoney = 0
        }
        
      },

      //设置数量
      numchang(){
        this.handleChange()
        this.$forceUpdate()
      },
      
      //获取卡卷列表
      getCalllist(){
        this.$http.post('api/store/storecard/cardlist').then(res =>{
          res.data.data.forEach(item => {
            item.addnum = 1
            item.count = item.num
            item.date = item.endtime
            // item.pickerOptions = {
            //   disabledDate(time) {
            //     return time.getTime() <= (new Date() - 3600 * 1000 * 24) || time.getTime() > new Date(item.endtime).getTime();
            //   },
            // }
          })
          this.callList = res.data.data
        })
      },
      //重新渲染计数器并计算价格
      handleChange(){
        this.allMoney = 0
        if(this.checkList.length != 0){
          for(let k in this.checkList){
            this.allMoney += Number(this.checkList[k].addnum)*Number(this.checkList[k].original_price)
          }
        }else{
          this.allMoney = 0
        }
        this.$forceUpdate()
      },
      // 返回上级
      returnOn() {
        this.$router.push({
          name: 'VipInfo'
        })
      },
      // 删除商品
      delOrderGoods() {
        this.$confirm('确认要删除该次卡吗？', '删除确认', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          center: true
        }).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {

        });
      },
      // 收款
      confirmChangeGoods(id) {
        this.$router.push({
          path: '/pay-settle',
          query: {
            type: 'sellCardList',
            id:id
          }
        })
      },
    }
  };

</script>
<style lang="less" scoped>
  // 头部
  .el-header {
    position: relative;
    z-index: 1;
    background-color: #fff;
    font-weight: bold;
    font-size: 16px;
    color: #333;
    height: 50px !important;
    line-height: 50px;
    text-align: center;
    border-bottom: 1px solid #e9e9e9;

    .header-left {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      color: #46a6ff;
      font-size: 24px;
    }
  }

  // 左侧商品卡片
  .header-search {
    height: 60px !important;
    background-color: #f9f9f9;
    border-bottom: none;
  }

  .content {
    padding: 0 10px 60px;

    .card {
      border: 3px solid #73a0fa;
      margin: 10px 0;
      border-bottom: none;

      .card-top {
        padding: 10px;


        .card-title,
        .card-time {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .card-title {
          .name {
            font-size: 14px;
            font-weight: bold;
          }

          .price {
            font-size: 12px;
            color: #f56c6c;
          }
        }

        .card-info {
          font-size: 12px;
          color: #999;
          margin: 10px 0 15px;
        }

        .card-time {
          font-size: 12px;
          color: #999;
          margin-bottom: 15px;

          .num {
            font-weight: bold;
            color: #666;
            font-size: 14px;
          }
        }
      }

      .card-btm {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #73a0fa;
        font-weight: bold;
        color: #fff;
        padding: 10px;
        border-bottom: 3px dashed #fff;


        .stepper {

          /deep/ .el-input-number {
            width: 100px;
            height: 24px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .el-input-number__decrease,
            .el-input-number__increase {
              width: 24px;
              height: 24px;
              box-sizing: border-box;
              z-index: 1;
              line-height: 20px;
              border-radius: 100%;
              border: 2px solid #fff;
              background-color: #73a0fa;
              color: #fff;
              font-weight: bold;
            }

            .el-input {
              width: 100px;
              height: 34px;

              .el-input__inner {
                height: 24px;
                line-height: 24px;
                padding: 0 15px;
                border-color: #73a0fa;
                background-color: #73a0fa;
                color: #fff;
                font-weight: bold;
                border: none;
              }
            }
          }
        }
      }
    }
  }

  .header-right {
    height: auto !important;
    color: #666;
    padding: 0 10px 10px;
    background-color: #fff;
    border-bottom: none;

    .about-info {
      .info-title {
        line-height: 40px;
        font-weight: bold;
        text-align: left;
        font-size: 14px;
      }

      .user-info {
        padding: 0 10px;
        background-color: #f4f4f4;
        border-radius: 8px;
        margin-bottom: 10px;

        .tel,
        .name {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .tel {
          border-bottom: 1px solid #e9e9e9;
        }

        .tel,
        .name {
          box-sizing: border-box;

          /deep/ .el-input {

            .el-input__inner {
              border: none;
              text-align: right;
              font-size: 12px;
              background-color: #f4f4f4;
              padding-right: 0;

            }
          }
        }

        .left {
          color: #666;
          font-size: 12px;
        }

        .right {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          line-height: 40px;
          color: #666;
          font-size: 12px;
        }
      }
    }
  }

  .order {
    padding-bottom: 20px;

    .order-body {
      .goods-card {
        background-color: #fff;
        box-shadow: 0 0 4px #ccc;
        border-radius: 10px;
        margin-top: 10px;
        padding: 10px;
        color: #666;

        .card-t {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;

          .t-name {
            width: 70%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          span {
            display: inline-block;
            margin-left: 10px;
            color: #ccc;
          }
        }

        .card-c {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;

          .c-price {
            font-size: 12px;
            color: #999;

            span {
              color: #666;
              font-size: 14px;
            }

            .c-date {
              width: 60%;

              /deep/ .el-date-editor.el-input {
                width: 100%;
              }
            }
          }
        }

        .card-b {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .c-time {
            font-size: 12px;
            color: #999;
          }

          .b-change {
            color: #666;
            font-size: 14px;
          }
        }

        .card-c {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;

          .c-price {
            color: #666;
            font-size: 14px;
          }
        }

        .card-b {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .b-name {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-right: 20px;

            img {
              width: 30px;
              margin-right: 10px;
            }


          }
        }
      }
    }
  }
  .stepper {

    /deep/ .el-input-number {
      width: 90px;
      height: 24px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .el-input-number__decrease,
      .el-input-number__increase {
        width: 24px;
        height: 24px;
        box-sizing: border-box;
        z-index: 1;
        line-height: 22px;
        border-radius: 100%;
        border: 1px solid #666;
        color: #666;
        font-weight: bold;
      }

      .el-input {
        width: 100px;
        height: 34px;
        background-color: transparent;

        .el-input__inner {
          height: 24px;
          line-height: 24px;
          padding: 0 15px;
          border-color: #73a0fa;
          background-color: transparent;
          color: #666;
          font-weight: bold;
          border: none;
        }
      }
    }
  }

  /deep/ .el-footer {
    padding: 0 !important;
    height: auto !important;

    .order-bottom {
      padding: 10px 10px 20px;
      box-sizing: border-box;
      background-color: #fff;
      color: #666;
      border-left: none;

      .btm-t {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #f2f2f2;
        padding: 10px 0;

        .t-left {
          b {
            color: #f56c6c;
          }
        }
      }

      .btm-c {
        border-bottom: 1px solid #f2f2f2;
        margin-bottom: 10px;

        .el-input__inner {
          border: none;
        }
      }

      .btm-b {
        .el-button {
          width: 100%;
        }
      }
    }
  }

  /deep/ .el-main {
    padding: 0 10px !important;

    .el-row {
      padding: 0 10px !important;


    }

  }

</style>
