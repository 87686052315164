<template>
  <div class="table">
    <div class="thead">
      <div class="th">时间</div>
      <div class="th">交易类型</div>
      <div class="th">金额</div>
      <div class="th">余额</div>
      <!-- <div class="th">单号</div> -->
      <!-- <div class="th">操作</div> -->
    </div>
    <div class="tbody" v-for="(item,i) in List" :key="i">
      <div class="td">{{  item.createtime | timeform(false)}}</div>
      <div class="td">{{ item.memo }}</div>
      <div class="td">
        <b v-if="item.aa == 1" class="green">+￥{{ item.money }}</b>
        <b v-else class="red">-￥{{ item.money }}</b>
      </div>
      <div class="td">
        <b>¥{{ item.after }}</b>
      </div>
      <!-- <div class="td">202101306600</div> -->
      <!-- <div class="td" @click="jumpServiceBills"><span class="span">查看账单</span></div> -->
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    data() {
      return {
        List:[]
      }
    },
    computed:{
      ...mapState(['vipuser'])
    },
    created() {
      this.getChongZhiList()
    },
    methods: {
      //获取消费记录列表
      getChongZhiList(){
        this.$http.post('api/store/storeuser/usermoney',{
          user_id:this.vipuser.id
        }).then(res =>{
          this.List = res.data.data
        })
      },
      jumpServiceBills() {
        this.$router.push({
          name: 'ServiceBills',
        })
        window.sessionStorage.setItem('reservationPath', '/about-history')
      }
    }
  };

</script>
<style lang="less" scoped>
  .table {
    color: #666;

    .thead,
    .tbody {
      display: flex;
      justify-content: space-around;
      text-align: center;
      line-height: 40px;

      .th,
      .td {
        width: 16%;
        font-size: 12px;

        &:nth-child(1) {
          width: 22%;
        }

        &:nth-child(2),
        &:nth-child(6) {
          width: 14%;
        }

        &:nth-child(3),
        &:nth-child(4) {
          width: 15%;
        }

        &:nth-child(5) {
          width: 20%;
        }
      }
    }

    .thead {
      background-color: #f5f5f5;
      border: 1px solid #e9e9e9;
      margin-bottom: 15px;
    }

    .tbody {
      background-color: #fff;
      border: 1px solid #e9e9e9;
      margin-bottom: 15px;

      .td {
        line-height: 50px;

        .green {
          color: #2ec478;
        }

        .red {
          color: #f56c6c;
        }

        .span {
          display: inline-block;
          border: 1px solid #409eff;
          color: #409eff;
          width: 80%;
          line-height: 30px;
          border-radius: 30px;

        }
      }

    }
  }

</style>
